import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../firebaseConfig'
import store from '../store';
const fb = require('../firebaseConfig.js')

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/',
      name: 'home',
      component: () => import('../views/Home.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Login.vue')
    },
     {
      path: '/shifts',
      name: 'shiftsTable',
      component: () => import('../views/Shifts.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/addsite',
      name: 'addsite',
      component: () => import('../views/Jobsites/AddJobsite.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/jobsites',
      name: 'jobsites',
      component: () => import('../views/Jobsites/Jobsites.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/jobsites/:id',
      name: 'jobsite',
      component: () => import('../views/Jobsites/Jobsite.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/addvenue', redirect: '/addsit',
      // name: 'addvenue',
      // component: () => import('../views/Venues/AddVenue.vue'),
      // meta: {
      //   requiresAuth: true
      // },
    },
    {
      path: '/venues', redirect: '/jobsites',
      // name: 'venues',
      // component: () => import('../views/Venues/Venues.vue'),
      // meta: {
      //   requiresAuth: true
      // },
    },
    {
      path: '/venues/:id', redirect: '/jobsites/:id',
      // name: 'venueQR',
      // component: () => import('../views/Venues/Venue.vue'),
      // meta: {
      //   requiresAuth: true
      // },
    },
    {
      path: '/jobsites/:id/qr',
      name: 'venueQR',
      component: () => import('../views/Jobsites/qrCode.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/settings', redirect: '/settings/skills',
      name: 'settingsHome',
      component: () => import('../views/Settings/SettingsHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/settings/skills',
          name: 'manageSkills',
          component: () => import('../views/Settings/ManageSkills.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/settings/skills/:id',
          name: 'skill',
          component: () => import('../views/Settings/Skill.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/settings/subclients',
          name: 'manageSubClients',
          component: () => import('../views/Settings/SubClients.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/settings/checks',
          name: 'manageChecks',
          component: () => import('../views/Settings/Checks.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/settings/categories',
          name: 'manageCategories',
          component: () => import('../views/Settings/ManageCategories.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/settings/certs',
          name: 'manageCerts',
          component: () => import('../views/Settings/ManageCerts.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/settings/certs/:id',
          name: 'cert',
          component: () => import('../views/Settings/Cert.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/settings/panels',
          name: 'managePanels',
          component: () => import('../views/Settings/ManagePanels.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/payroll', 
      name: 'payrollHome',
      component: () => import('../views/Payroll/PayrollHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/payroll/payrollqueue',
          name: 'payrollQueue',
          component: () => import('../views/Payroll/PayrollQueue.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'payroll/payables',
          name: 'payables',
          component: () => import('../views/Payroll/Payables.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'payroll/workers',
          name: 'payrollWorkers',
          component: () => import('../views/Payroll/Workers.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },

    {
      path: '/reports',
      name: 'reportsHome',
      component: () => import('../views/Reports/ReportsHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        
         {
          path: '/reports/employees',
          name: 'onboardingEmployees',
          component: () => import('../views/Reports/OnboardingEmployees.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/reports/paidAssignments',
          name: 'paidAssignments',
          component: () => import('../views/Reports/PaidAssignments.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/reports/backgroundchecks',
          name: 'backgroundChecks',
          component: () => import('../views/Reports/BackgroundChecks.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/reports/evereeUsersWithoutExternalId',
          name: 'evereeUsersWithoutExternalId',
          component: () => import('../views/Reports/EvereeUsersWithoutExternalId.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/reports/noncontractors',
          name: 'nonContractors',
          component: () => import('../views/Reports/NonContractors.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/reports/newyork',
          name: 'newYork',
          component: () => import('../views/Reports/NewYork.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/reports/timesheets',
          name: 'reportTimesheets',
          component: () => import('../views/Reports/Timesheets.vue'),
          meta: {
            requiresAuth: true
          },
        },


        
        {
          path: '/reports/parse',
          name: 'userParse',
          component: () => import('../views/Reports/UserParse.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    // {
    //   path: '/markets',
    //   name: 'markets',
    //   component: () => import('../views/Leads/Markets.vue'),
    //   meta: {
    //     requiresAuth: true
    //   },
    // },
    {
      path: '/leads', redirect: '/leads/all',
      name: 'leadsHome',
      component: () => import('../views/Leads/LeadsHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/leads/all',
          name: 'allLeads',
          component: () => import('../views/Leads/AllLeads.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/leads/hidden',
          name: 'archivedLeads',
          component: () => import('../views/Leads/HiddenLeads.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/leads/my',
          name: 'myLeads',
          component: () => import('../views/Leads/MyLeads.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/leads/markets',
          name: 'markets',
          component: () => import('../views/Leads/Markets.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/leads/add',
          name: 'enterNewLead',
          component: () => import('../views/Leads/AddLead.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/watchlist', redirect: '/watchlist/users',
      name: 'watchlistHome',
      component: () => import('../views/Watchlist/WatchlistHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/watchlist/users',
          name: 'userWatchlist',
          component: () => import('../views/Watchlist/UserWatchlist.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/watchlist/orders',
          name: 'orderWatchlist',
          component: () => import('../views/Watchlist/OrderWatchlist.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/watchlist/groups',
          name: 'groupWatchlist',
          component: () => import('../views/Watchlist/GroupWatchlist.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/shiftleads',
      name: 'shiftLeads',
      component: () => import('../views/ShiftLeads/ShiftLeads.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/groups',
      name: 'groups',
      component: () => import('../views/Groups/Groups.vue'),
      meta: {
        requiresAuth: true,
        refreshGroup: true
      },
    },
    {
      path: '/addgroup',
      name: 'addgroup',
      component: () => import('../views/Groups/AddGroup.vue'),
      meta: {
        requiresAuth: true,
        refreshGroup: true
      },
    },
    {
      path: '/groups/grouphome', redirect: '/groups/:id/edit',
      name: 'groupHome',
      component: () => import('../views/Groups/GroupHome.vue'),
      meta: {
        requiresAuth: true,
        refreshGroup: true
      },
      // afterRouteLeave(to, from, next) {
      //   return $store.dispatch('clearGroupState').then(next)
      //   console.log('changing')
        
      // },
      children: [
        {
          path: '/groups/:id',
          name: 'group',
          component: () => import('../views/Groups/Group.vue'),
          meta: {
            requiresAuth: true,
            refreshGroup: true
          },
        },
        {
          path: '/groups/:id/applicants',
          name: 'groupApplicants',
          component: () => import('../views/Groups/Applicants.vue'),
          meta: {
            requiresAuth: true,
            refreshGroup: true
          },
        },
        {
          path: '/groups/:id/waitlist',
          name: 'groupWaitlist',
          component: () => import('../views/Groups/Waitlist.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/groups/:id/edit',
          name: 'groupEdit',
          component: () => import('../views/Groups/EditGroup.vue'),
          meta: {
            requiresAuth: true,
            refreshGroup: true
          },
        },
        {
          path: '/groups/:id/messages',
          name: 'groupMessages',
          component: () => import('../views/Groups/GroupMessages.vue'),
          meta: {
            requiresAuth: true,
            refreshGroup: true
          },
        },
      ]
    },
    {
      path: '/payroll/help',
      name: 'payrollHelp',
      component: () => import('../views/PayrollHelp/PayrollHelp.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/addevent',
      name: 'addevent',
      component: () => import('../views/Events/AddEvent.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/orders',
      name: 'events',
      component: () => import('../views/Events/Events.vue'),
      meta: {
        requiresAuth: true,
        refreshEvent: true
      },
    },
    {
      path: '/orders/:id', redirect: '/orders/:id/setup/details',
      name: 'eventHome',
      component: () => import('../views/Events/EventHome.vue'),
      meta: {
        requiresAuth: true,
        refreshEvent: true
      },
      // afterRouteLeave(to, from, next) {
      //   return $store.dispatch('clearEventState').then(next)
      //   console.log('changing')
        
      // },
      children: [
        {
          path: '/orders/:id/details', redirect: '/orders/:id/setup/details',
          // name: 'eventDetails',
          // component: () => import('../views/Events/Event.vue'),
          // props: true,
          // meta: {
          //   requiresAuth: true,
          //   refreshEvent: true
          // },
        },
        {
          path: '/orders/:id/instructions',
          name: 'eventInstructions',
          component: () => import('../views/Events/EventInstructions.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/orders/:id/jobs',
          name: 'eventJobs',
          component: () => import('../views/Events/EventJobs.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/orders/:id/setup', redirect: '/orders/:id/setup/details',
          name: 'orderHome',
          component: () => import('../views/Events/OrderInfo/OrderHome.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
          children: [
            {
              path: '/orders/:id/setup/details',
              name: 'orderDetails',
              component: () => import('../views/Events/OrderInfo/Details.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
            {
              path: '/orders/:id/setup/qr',
              name: 'orderQR',
              component: () => import('../views/Events/OrderInfo/qrCode.vue'),
              meta: {
                requiresAuth: true,
                refreshEvent: true
              }
            },
            {
              path: '/orders/:id/setup/instructions',
              name: 'orderInstructions',
              component: () => import('../views/Events/OrderInfo/Instructions.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
            {
              path: '/orders/:id/setup/jobs',
              name: 'orderJobs',
              component: () => import('../views/Events/OrderInfo/Jobs.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
            {
              path: '/orders/:id/setup/shifts',
              name: 'orderShifts',
              component: () => import('../views/Events/OrderInfo/Shifts.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
            {
              path: '/orders/:id/setup/shifts/:is',
              name: 'orderShift',
              component: () => import('../views/Events/OrderInfo/Shift.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
            {
              path: '/orders/:id/setup/addshift',
              name: 'addShift',
              component: () => import('../views/Events/OrderInfo/AddShift.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
          ]
        },
        {
          path: '/orders/:id/time', redirect: '/orders/:id/time/timesheets',
          name: 'timeHome',
          component: () => import('../views/Events/Timesheets/TimeHome.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
          children: [
            {
              path: '/orders/:id/time/timesheets',
              name: 'timeTimesheets',
              component: () => import('../views/Events/Timesheets/Timesheets.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
            {
              path: '/orders/:id/time/checkin',
              name: 'timeCheckin',
              component: () => import('../views/Events/Timesheets/Checkin.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
            {
              path: '/orders/:id/time/upload',
              name: 'uploadTimesheet',
              component: () => import('../views/Events/Timesheets/UploadTimesheet.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
          ]
        },
        {
          path: '/orders/:id/staffing', redirect: '/orders/:id/staffing/placements',
          name: 'staffingHome',
          component: () => import('../views/Events/Staffing/StaffingHome.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
          children: [
            {
              path: '/orders/:id/staffing/applicants',
              name: 'staffingApplicants',
              component: () => import('../views/Events/Staffing/Applicants.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
            {
              path: '/orders/:id/staffing/placements',
              name: 'staffingPlacements',
              component: () => import('../views/Events/Staffing/Placements.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
            {
              path: '/orders/:id/staffing/drops',
              name: 'staffingDrops',
              component: () => import('../views/Events/Staffing/Drops.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
            {
              path: '/orders/:id/staffing/groups',
              name: 'staffingGroups',
              component: () => import('../views/Events/Staffing/Groups.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
            {
              path: '/orders/:id/staffing/proximity',
              name: 'staffingProximity',
              component: () => import('../views/Events/Staffing/Proximity.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
            {
              path: '/orders/:id/staffing/pipeline',
              name: 'staffingPipeline',
              component: () => import('../views/Events/Staffing/Pipeline.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
            {
              path: '/orders/:id/staffing/email',
              name: 'staffingEmail',
              component: () => import('../views/Events/Staffing/Email.vue'),
              props: true,
              meta: {
                requiresAuth: true,
                refreshEvent: true
              },
            },
          ]
        },
        {
          path: '/orders/:id/checkin',
          name: 'eventCheckin',
          component: () => import('../views/Events/EventCheckin.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/orders/:id/shifts',
          name: 'eventShifts',
          component: () => import('../views/Events/EventShifts.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/orders/:id/shifts/:is',
          name: 'eventShift',
          component: () => import('../views/Events/EventShift.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/orders/:id/proximity',
          name: 'eventProximity',
          component: () => import('../views/Events/EventProximity.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        // {
        //   path: '/events/:id/shifts/:is/placement',
        //   name: 'shiftPlacement',
        //   component: () => import('../views/Shifts/ShiftPlacement.vue'),
        //   props: true,
        //   meta: {
        //     requiresAuth: true
        //   },
        // },
        {
          path: '/orders/:id/shifts/:is/placement',
          name: 'shiftPlacement',
          component: () => import('../views/Placements/ShiftPlacement.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/orders/:id/placement',
          name: 'eventPlacements',
          component: () => import('../views/Events/EventPlacements.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/orders/:id/pipeline',
          name: 'eventPipeline',
          component: () => import('../views/Events/Pipeline.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/orders/:id/Client',
          name: 'eventClient',
          component: () => import('../views/Events/EventClient.vue'),
          props: true,
          meta: {
            refreshEvent: true
          },
        },
        {
          path: '/orders/:id/drops',
          name: 'eventDrops',
          component: () => import('../views/Events/EventDrops.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/orders/:id/timesheets',
          name: 'eventTimesheets',
          component: () => import('../views/Events/EventTimesheets.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/orders/:id/files',
          name: 'eventFiles',
          props: true,
          component: () => import('../views/Events/EventFiles.vue'),
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/orders/:id/email',
          name: 'eventEmail',
          component: () => import('../views/Events/EventEmail.vue'),
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },


        
      ]
    },
    // {
    //   path: '/jobs',
    //   name: 'weeks',
    //   component: () => import('../views/Weeks/Weeks.vue'),
    //   meta: {
    //     requiresAuth: true
    //   },
    // },
    // {
    //   path: '/addjob',
    //   name: 'addweek',
    //   component: () => import('../views/Weeks/AddWeek.vue'),
    //   meta: {
    //     requiresAuth: true
    //   },
    // },
    // {
    //   path: '/jobs/:id', redirect: '/jobs/:id/details',
    //   name: 'weekHome',
    //   component: () => import('../views/Weeks/WeekHome.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     refreshEvent: true
    //   },
    //   children: [
    //     {
    //       path: '/jobs/:id/details',
    //       name: 'weekDetails',
    //       component: () => import('../views/Weeks/Week.vue'),
    //       props: true,
    //       meta: {
    //         requiresAuth: true
    //       },
    //     },
    //   ]
    // },
    // {
    //   path: '/events/:id',
    //   name: 'event',
    //   component: () => import('../views/Events/Event.vue'),
    //   meta: {
    //     requiresAuth: true
    //   },
    // },
    
    
    {
      path: '/timesheets/:id/:day',
      name: 'daytimesheets',
      component: () => import('../views/Shifts/DayShifts.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/addjob',
      name: 'addjob',
      component: () => import('../views/Jobs/AddJob.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/tags',
      name: 'tags',
      component: () => import('../views/Tags/Tags.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/addtag',
      name: 'addtag',
      component: () => import('../views/Tags/AddTag.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/positions',
      name: 'jobs',
      component: () => import('../views/Jobs/Jobs.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/positions/:id',
      name: 'job',
      component: () => import('../views/Jobs/Job.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/addmgr',
      name: 'addmgr',
      component: () => import('../views/Team/AddMgr.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/team',
      name: 'team',
      component: () => import('../views/Team/Team.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/team/:id',
      name: 'mgr',
      component: () => import('../views/Team/Mgr.vue'),
      meta: {
        requiresAuth: true
      },
    },

    // {
    //   path: '/shifts',
    //   name: 'activeShifts',
    //   component: () => import('../views/Shifts/ActiveShifts.vue'),
    //   meta: {
    //     requiresAuth: true
    //   },
    // },
    {
      path: '/addclient',
      name: 'addclient',
      component: () => import('../views/Clients/AddClient.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('../views/Clients/Clients.vue'),
      meta: {
        requiresAuth: true
      },
    },

    {
      path: '/clients/clienthome', redirect: '/clients/:id',
      name: 'clientHome',
      component: () => import('../views/Clients/ClientHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/clients/:id',
          name: 'client',
          component: () => import('../views/Clients/Client.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/clients/:id/summary',
          name: 'clientSummary',
          component: () => import('../views/Clients/ClientSummary.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/clients/:id/contacts',
          name: 'clientContacts',
          component: () => import('../views/Clients/ClientContacts.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/clients/:id/notes',
          name: 'clientNotes',
          component: () => import('../views/Clients/ClientNotes.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/clients/:id/files',
          name: 'clientFiles',
          component: () => import('../views/Clients/ClientFiles.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/clients/:id/venues',
          name: 'clientVenues',
          component: () => import('../views/Clients/ClientVenues.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/addcontact',
      name: 'addcontact',
      component: () => import('../views/Contacts/AddContact.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: () => import('../views/Contacts/Contacts.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/contacts/:id',
      name: 'contact',
      component: () => import('../views/Contacts/Contact.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/contractors',
      name: 'evereeContractors',
      component: () => import('../views/Everee/Contractors.vue'),
      meta: {
        requiresAuth: true
      },
    },
    // {
    //   path: '/payrolldata2',
    //   name: 'evereeContractorsTable',
    //   component: () => import('../views/Everee/Contractors2.vue'),
    //   meta: {
    //     requiresAuth: true
    //   },
    // },
    {
      path: '/employees',
      name: 'employees',
      component: () => import('../views/Users/Employees.vue'),
      meta: {
        requiresAuth: true
      },
      // afterRouteLeave(to, from, next) {
      //   return $store.dispatch('clearUsersState').then(next)
      //   console.log('changing')
        
      // },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('../views/Users/Users.vue'),
      meta: {
        requiresAuth: true
      },
      // afterRouteLeave(to, from, next) {
      //   return $store.dispatch('clearUsersState').then(next)
      //   console.log('changing')
        
      // },
    },
    {
      path: '/newusers',
      name: 'newUsers',
      component: () => import('../views/Users/NewUsers.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/users/:id', redirect: '/users/:id/details',
      name: 'userHome',
      component: () => import('../views/Users/User.vue'),
      meta: {
        requiresAuth: true,
        refreshUser: true
      },
      // afterRouteLeave(to, from, next) {
      //   return $store.dispatch('clearUserState').then(next)
      //   console.log('changing')
        
      // },
        children: [
          {
            path: '/users/:id/details',
            name: 'userDetails',
            component: () => import('../views/Users/UserDetails.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshUser: true
            },
          },
          {
            path: '/users/:id/certs',
            name: 'userCerts',
            component: () => import('../views/Users/UserCerts.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshUser: true
            },
          },
          {
            path: '/users/:id/activity',
            name: 'userActivity',
            component: () => import('../views/Users/UserActivity.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshUser: true
            },
          },
          {
            path: '/users/:id/pipeline',
            name: 'userPipeline',
            component: () => import('../views/Users/UserPipeline.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshEvent: true
            },
          },
          {
            path: '/users/:id/groups',
            name: 'userGroups',
            component: () => import('../views/Users/UserGroups.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshUser: true
            },
          },
          {
            path: '/users/:id/skills',
            name: 'userSkills',
            component: () => import('../views/Users/UserSkills.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshUser: true
            },
          },
          {
            path: '/users/:id/letter',
            name: 'offerLetter',
            component: () => import('../views/Users/OfferLetter.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshUser: true
            },
          },
          {
            path: '/users/:id/payhistory',
            name: 'userPayHistory',
            component: () => import('../views/Users/UserPayHistory.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshUser: true
            },
          },
          {
            path: '/users/:id/timesheets',
            name: 'userTimesheets',
            component: () => import('../views/Users/UserTimesheets.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshUser: true
            },
          },
          {
            path: '/users/:id/everee',
            name: 'userEveree',
            component: () => import('../views/Users/UserEveree.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshUser: true
            },
          },
          {
            path: '/users/:id/emergency',
            name: 'userEmergency',
            component: () => import('../views/Users/UserEmergency.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshUser: true
            },
          },
          {
            path: '/users/:id/id',
            name: 'userId',
            component: () => import('../views/Users/UserId.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshUser: true
            },
          },
          {
            path: '/users/:id/backgroundcheck',
            name: 'userBackgroundCheck',
            component: () => import('../views/Users/UserBackgroundCheck.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshUser: true
            },
          },
          {
            path: '/users/:id/backgroundcheck/:ic',
            name: 'backgroundCheck',
            component: () => import('../views/Users/Check.vue'),
            props: true,
            meta: {
              requiresAuth: true,
              refreshUser: true
            },
          },

        {
          path: '/users/:id/assignments',
          name: 'userAssignments',
          component: () => import('../views/Users/UserAssignments.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshUser: true
          },
        },
        {
          path: '/users/:id/applications',
          name: 'userApplications',
          component: () => import('../views/Users/UserApplications.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshUser: true
          },
        },
        {
          path: '/users/:id/payroll',
          name: 'userPayroll',
          component: () => import('../views/Users/UserPayroll.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshUser: true
          },
        },
        {
          path: '/users/:id/notes',
          name: 'userNotes',
          component: () => import('../views/Users/UserNotes.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshUser: true
          },
        },
        {
          path: '/users/:id/gallery',
          name: 'userGallery',
          component: () => import('../views/Users/UserGallery.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshUser: true
          },
        },
        {
          path: '/users/:id/reviews',
          name: 'userReviews',
          component: () => import('../views/Users/UserReviews.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshUser: true
          },
        },
        {
          path: '/users/:id/messages',
          name: 'userMessages',
          component: () => import('../views/Users/UserMessages.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshUser: true
          },
        },
        {
          path: '/users/:id/interview',
          name: 'userInterview',
          component: () => import('../views/Users/UserInterview.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshUser: true
          },
        },
        {
          path: '/users/:id/i9',
          name: 'userIdUpload',
          component: () => import('../views/Users/UserIdUpload.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshUser: true
          },
        },
        {
          path: '/users/:id/employeeonboarding',
          name: 'userEmployeeOnboarding',
          component: () => import('../views/Users/UserEmployeeOnboarding.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshUser: true
          },
        },
      ]
    },
    {
      path: '/dashboard',
      name: 'dashboardHome',
      component: () => import('../views/Dashboard/DashboardHome.vue'),
      meta: {
        requiresAuth: true,
        refreshEvent: true
      },
      children: [
        {
          path: '/dashboard/pipeline',
          name: 'myPipeline',
          component: () => import('../views/Dashboard/MyPipeline.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/dashboard/onboarding',
          name: 'myOnboarding',
          component: () => import('../views/Dashboard/MyOnboarding.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/dashboard/active',
          name: 'myActive',
          component: () => import('../views/Dashboard/MyActive.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/dashboard/users',
          name: 'myUserWatchlist',
          component: () => import('../views/Dashboard/MyUserWatchlist.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/dashboard/orders',
          name: 'myOrderWatchlist',
          component: () => import('../views/Dashboard/MyOrderWatchlist.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
        {
          path: '/dashboard/groups',
          name: 'myGroupWatchlist',
          component: () => import('../views/Dashboard/MyGroupWatchlist.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            refreshEvent: true
          },
        },
      ]
    },
    
    // {
    //   path: '/dashboard', redirect: '/watchlist/users',
    //   name: 'dashboard',
    //   component: () => import('../views/Dashboard.vue'),
    //   meta: {
    //     requiresAuth: true
    //   },
    // },
    {
      path: '/support', redirect: '/support/open',
      name: 'supportHome',
      component: () => import('../views/Support/SupportHome.vue'),
      meta: {
        requiresAuth: true,
        refreshSupport: true
      },
      children: [
        {
          path: '/support/open',
          name: 'openTickets',
          component: () => import('../views/Support/OpenTickets.vue'),
          meta: {
            requiresAuth: true,
            refreshSupport: true
          },
        },
        {
          path: '/support/progress',
          name: 'progressTickets',
          component: () => import('../views/Support/ProgressTickets.vue'),
          meta: {
            requiresAuth: true,
            refreshSupport: true
          },
        },
        {
          path: '/support/closed',
          name: 'closedTickets',
          component: () => import('../views/Support/ClosedTickets.vue'),
          meta: {
            requiresAuth: true,
            refreshSupport: true
          },
        },
      ]
    },

    {
      path: '/access', redirect: '/access/clients',
      name: 'clientAccessHome',
      component: () => import('../views/ClientAccess/ClientAccessHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/access/clients',
          name: 'clientAccess',
          component: () => import('../views/ClientAccess/ClientAccess.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/access/new',
          name: 'giveClientAccess',
          component: () => import('../views/ClientAccess/GiveAccess.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/access/clients/:id',
          name: 'manageAccess',
          component: () => import('../views/ClientAccess/ManageAccess.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },

    {
      path: '/timesheets/:id',
      name: 'shift',
      component: () => import('../views/Shifts/Shift.vue'),
      meta: {
        requiresAuth: true
      },
    },

  ]
})

router.beforeEach((to, from, next) => {
  let currentUser = fb.auth.currentUser;
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  let refreshUser = to.matched.some(x => x.meta.refreshUser)
  let refreshEvent = to.matched.some(x => x.meta.refreshEvent)
  let refreshGroup = to.matched.some(x => x.meta.refreshGroup)
  let refreshSupport = to.matched.some(x => x.meta.refreshSupport)

  // if (requiresAuth && !currentUser) next('login');
  // else if (!requiresAuth && currentUser) next('dashboard');
  // else next();

  if (requiresAuth && !auth.currentUser) {
    next('login')
  } else {
    next()
  }

  if (refreshGroup && (to.name == "groupHome" || to.name == "groupEdit" || to.name == "groupMessages" || to.name == "groupWaitlist" || to.name == "groupApplicants" || to.name == "group"))
  {
    console.log('not clearing group')

  } else if (refreshGroup && (to.name == "groups" )) {
    console.log('clearing group state')
    store.dispatch('clearGroupState')
  
  } else if (refreshGroup) {
    console.log('clearing group state')
    store.dispatch('clearGroupState')
  } else {

  }

  if (refreshUser && (to.name == "userSkills" || to.name == "userPipeline" || to.name == "userPayHistory" || to.name == "userId" || to.name == "userGroups" || to.name == "userEmergency" || to.name == "userCerts" || to.name == "userBackgroundCheck" || to.name == "userDetails" || to.name == "userPayroll" || to.name == "userApplications" || to.name == "userAssignments" || to.name == "userNotes" || to.name == "userGallery" || to.name == "userReviews" || to.name == "userMessages" || to.name == "userInterview" || to.name == "userIdUpload" || to.name == "userEveree" || to.name == "backgroundCheck" || to.name == "userEmployeeOnboarding")) 
  {
    console.log('not clearing state')
  
  } else if (refreshUser) {
    console.log(to.name)
    console.log('clearing state')
    store.dispatch('clearUserState')
  } else {

  }

  if (refreshEvent && (to.name == "eventHome" || to.name == "orderQr" || to.name == "eventPipeline" || to.name == "eventInstructions" || to.name == "eventDetails" || to.name == "staffingHome" || to.name == "staffingApplicants" || to.name == "staffingPlacements" || to.name == "staffingDrops" || to.name == "staffingProximity"  || to.name == "staffingApplicants" || to.name == "staffingPlacements" || to.name == "staffingDrops" || to.name == "staffingGroups" || to.name == "staffingPipeline" || to.name == "staffingEmail" || to.name == "eventJobs" || to.name == "eventPlacements" || to.name == "eventFiles" || to.name == "eventProximity" || to.name == "eventShifts" || to.name == "eventShift" || to.name == "eventCheckin" || to.name == "eventEmail" || to.name == "eventTimesheets" || to.name == "shiftPlacement" || to.name == "eventDrops" || to.name == "orderHome" || to.name == "orderDetails" || to.name == "orderJobs" || to.name == "orderInstructions" || to.name == "orderShifts" || to.name == "orderShift" || to.name == "addShift" || to.name == "timeCheckin" || to.name == "orderTimesheets" || to.name == "timeTimesheets" || to.name == "timeHome" || to.name == "uploadTimesheet"))
  {
    console.log('not clearing state')
  } else if ((to.name == "events" ) || (to.name == "user" ) || (to.name == "userHome" )) {
    console.log('clearing event state')
    store.dispatch('clearEventState')
  } else if (refreshEvent) {
    console.log('clearing event state')
    store.dispatch('clearEventState')
  } else {

  }

  if (refreshSupport && (to.name == "supportHome" || to.name == "openTickets" || to.name == "closedTickets" || to.name == "progressTickets"))
  {
    console.log('not clearing state')
  } else if (refreshSupport) {
     console.log('clearing support state')
    store.dispatch('clearSupportRequests')
  } else {

  }

});

export default router;

// // router.beforeEach((to, from, next) => {
// //   const requiresAuth = from.matched.some(x => x.meta.requiresAuth)
// //   // const refreshUser = to.matched.some(x => x.meta.refreshUser)
// //   // const refreshEvent = to.matched.some(x => x.meta.refreshEvent)

//   if (refreshUser && (to.name == "userDetails" || to.name == "userPayroll" || to.name == "userAssignments" || to.name == "userNotes" || to.name == "userGallery" || to.name == "userReviews" || to.name == "userMessages")) 
//   {
//     console.log('not clearing state')
  
//   } else  {
//     console.log(to.name)
//     console.log('clearing state')
//     store.dispatch('clearUserState')
//   }

//   if (refreshEvent && (to.name == "eventDetails" || to.name == "eventplacement" || to.name == "eventshifts" || to.name == "eventCheckin" || to.name == "eventtimesheets"))
//   {
//     console.log('not clearing state')
//   } else  {
//     store.dispatch('clearEventState')
//   }

//   if (requiresAuth) {
//       console.log('not currentUser')
//       store.dispatch('logout')
//       next('/login')

// //   }
// //   // else if (requiresAuth && currentUser) {
//   //   console.log('currentUser')
// //   //     next()
// //   // } 
// //   else {
// //     console.log('else')
// //       next()
// //   }
// // })

// // export default router