import Vue from 'vue'
import Vuex from 'vuex'
// import * as fb from '../firebaseConfig'
const fb = require('../firebaseConfig.js')
import router from '../router/index'
import * as moment from 'moment'
import firebase from 'firebase/app';
import 'firebase/firestore'; // Import Firestore

Vue.use(Vuex)

fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setCurrentUser', user)
    store.dispatch('fetchUserProfile', user)
  }
})

async function fetchAssignmentsBatch(query) {
  try {
    const querySnapshot = await query.get();
    const assignmentsArray = [];

    querySnapshot.forEach((doc) => {
      const assignmentData = doc.data();
      const assignmentId = doc.id;
      assignmentsArray.push({ id: assignmentId, ...assignmentData });
    });

    return assignmentsArray;
  } catch (error) {
    console.error('Error fetching assignments batch:', error);
    return [];
  }
}

async function loadAssignmentsConcurrently(query, onBatchLoaded, batchSize, throttleDelay) {
  const assignmentsArray = [];

  while (true) {
    const batchPromises = [];
    let lastDoc = null;

    for (let i = 0; i < batchSize; i++) {
      if (lastDoc) {
        query = query.startAfter(lastDoc);
      }

      const batchPromise = fetchAssignmentsBatch(query);
      batchPromises.push(batchPromise);

      // Capture the last document for the next batch
      batchPromise.then((batchAssignments) => {
        if (batchAssignments.length > 0) {
          lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        }
      });
    }

    const batchResults = await Promise.all(batchPromises);
    const batchAssignments = batchResults.reduce((acc, curr) => acc.concat(curr), []);

    if (batchAssignments.length === 0) {
      break; // No more assignments to fetch, exit the loop
    }

    assignmentsArray.push(...batchAssignments);

    // Invoke the callback function with the current batch of results
    if (onBatchLoaded && typeof onBatchLoaded === 'function') {
      onBatchLoaded(batchAssignments.slice()); // Pass a copy of the array
    }

    console.log(`Fetched ${batchAssignments.length} assignments. Total: ${assignmentsArray.length}`);

    // Schedule the next batch fetch
    await new Promise((resolve) => setTimeout(resolve, throttleDelay));
  }

  return assignmentsArray;
}

async function throttleRequests(requests, concurrency) {
  const results = [];
  let running = 0;
  let index = 0;

  while (running < concurrency && index < requests.length) {
    const request = requests[index++];
    running++;
    request()
      .then((result) => {
        results.push(result);
        running--;

        if (index < requests.length) {
          throttleNext();
        }
      })
      .catch(() => {
        running--;

        if (index < requests.length) {
          throttleNext();
        }
      });
  }

  function throttleNext() {
    const request = requests[index++];
    if (request) {
      running++;
      request()
        .then((result) => {
          results.push(result);
          running--;

          if (index < requests.length) {
            throttleNext();
          }
        })
        .catch(() => {
          running--;

          if (index < requests.length) {
            throttleNext();
          }
        });
    }
  }

  await new Promise((resolve) => {
    const checkDone = () => {
      if (running === 0) {
        resolve();
      }
    };

    setInterval(checkDone, 200); // Adjust the interval as needed
    checkDone();
  });

  return results;
}

async function loadAllEmployeeAssignmentsByDate(payload) {
  console.log(payload);
  if (!payload) {
    console.error('Invalid payload for loadAllEmployeeAssignmentsByDate');
    return null;
  }

  const query = fb.assignmentsCollection
  .where("day", ">=", payload.startDate)
  .where("day", "<=", payload.endDate)
  .where("eventInfo.workerType", "==", payload.type);
  const assignmentsArray = []; // Define assignmentsArray here

  try {
    const querySnapshot = await query.get();

    querySnapshot.forEach((doc) => {
      const assignmentData = doc.data();
      const assignmentId = doc.id;
      assignmentsArray.push({ id: assignmentId, ...assignmentData });
    });

    store.commit('setAllEmployeeAssignments', assignmentsArray);

    return query;
  } catch (error) {
    console.error('Error during initial load:', error);
    return null;
  }
}

async function loadAllContractorAssignmentsByDate(payload) {
  console.log(payload);
  if (!payload) {
    console.error('Invalid payload for loadAllContractorAssignmentsByDate');
    return null;
  }

  const query = fb.assignmentsCollection
  .where("day", ">=", payload.startDate)
  .where("day", "<=", payload.endDate)
  .where("shift.workerType", "==", payload.type);
  const assignmentsArray = []; // Define assignmentsArray here

  try {
    const querySnapshot = await query.get();

    // querySnapshot.forEach((doc) => {
    //   const assignmentData = doc.data();
    //   const assignmentId = doc.id;
    //   if ((!assignmentData || !assignmentData.eventInfo || !assignmentData.eventInfo.workerType || !assignmentData.eventInfo.workerType) || (assignmentData && assignmentData.eventInfo && assignmentData.eventInfo.workerType && assignmentData.eventInfo.workerType != 'Employees')) {
    //     console.log(doc.id)
    //     assignmentsArray.push({ id: assignmentId, ...assignmentData });
    //   }
    // });

    querySnapshot.forEach((doc) => {
      const assignmentData = doc.data();
      const assignmentId = doc.id;
      console.log(doc.id)
      assignmentsArray.push({ id: assignmentId, ...assignmentData });
    });

    store.commit('setAllContractorAssignments', assignmentsArray);

    return query;
  } catch (error) {
    console.error('Error during initial load:', error);
    return null;
  }
}

// async function loadAllEventAssignmentsByDay(payload) {
//   console.log(payload);
//   if (!payload) {
//     console.error('Invalid payload for loadAllEventAssignments');
//     return null;
//   }

//   const query = fb.assignmentsCollection.where("eventId", "==", payload.eventId).where('day', '==', payload.day);
//   const assignmentsArray = []; // Define assignmentsArray here

//   try {
//     const querySnapshot = await query.get();

//     querySnapshot.forEach((doc) => {
//       const assignmentData = doc.data();
//       const assignmentId = doc.id;
//       assignmentsArray.push({ id: assignmentId, ...assignmentData });
//     });

//     store.commit('setEventAssignmentsByDay', assignmentsArray);

//     return query;
//   } catch (error) {
//     console.error('Error during initial load:', error);
//     return null;
//   }
// }

async function loadAllEventAssignmentsByDay(payload) {
  console.log(payload);
  if (!payload) {
    console.error('Invalid payload for loadAllEventAssignments');
    return null;
  }

  const query = fb.assignmentsCollection.where("eventId", "==", payload.eventId).where('day', '==', payload.day);
  const assignmentsArray = []; // Define assignmentsArray here

  try {
    const querySnapshot = await query.get();

    querySnapshot.forEach((doc) => {
      const assignmentData = doc.data();
      const assignmentId = doc.id;
      const assignment = { id: assignmentId };
      for (const key in assignmentData) {
        if (assignmentData.hasOwnProperty(key)) {
          assignment[key] = assignmentData[key];
        }
      }
      assignmentsArray.push(assignment);
    });

    store.commit('setEventAssignmentsByDay', assignmentsArray);

    return query;
  } catch (error) {
    console.error('Error during initial load:', error);
    return null;
  }
}


async function attachRealtimeListenerByDay({ query, throttleIntervalInSeconds }) {
  if (query === null) {
    console.error('Query is null. Cannot attach a real-time listener.');
    return;
  }

  let isThrottled = false;

  const unsubscribe = query.onSnapshot((querySnapshot) => {
    if (isThrottled) return;

    const assignmentsArray = [...store.state.eventAssignmentsByDay]; // Create a deep copy of the existing assignments

    querySnapshot.docChanges().forEach((change) => {
      const assignmentData = change.doc.data();
      const assignmentId = change.doc.id;

      if (change.type === 'added' || change.type === 'modified') {
        // Handle added or modified eventAssignments optimistically
        const updatedAssignment = { id: assignmentId, ...assignmentData };
        const index = assignmentsArray.findIndex((a) => a.id === assignmentId);

        if (index === -1) {
          // Assignment doesn't exist in the current array, push it
          assignmentsArray.push(updatedAssignment);
        } else {
          // Assignment exists, update it
          assignmentsArray[index] = updatedAssignment;
        }
      } else if (change.type === 'removed') {
        // Handle removed eventAssignments
        const indexToRemove = assignmentsArray.findIndex((a) => a.id === assignmentId);

        if (indexToRemove !== -1) {
          assignmentsArray.splice(indexToRemove, 1);
        }
      }
    });

    // Commit the merged data to the store
    store.commit('setEventAssignmentsByDay', assignmentsArray);
  });

  // Store the unsubscribe function for later use if needed
  // this.unsubscribeEventAssignments = unsubscribe;

  isThrottled = true;
  setTimeout(() => {
    isThrottled = false;
  }, throttleIntervalInSeconds * 2000);
}


async function loadAllEventAssignments(payload) {
  console.log(payload);
  if (!payload) {
    console.error('Invalid payload for loadAllEventAssignments');
    return null;
  }

  try {

    const query = fb.assignmentsCollection
    .where("eventId", "==", payload)
    .orderBy("day", "desc")
    .limit("350")
    
    const assignmentsArray = []; // Define assignmentsArray here

    const querySnapshot = await query.get();

    querySnapshot.forEach((doc) => {
      const assignmentData = doc.data();
      const assignmentId = doc.id;

      if (assignmentData && assignmentData.paystatus && assignmentData.paystatus == 'paid')
      {

      } else {
        // assignmentsArray.push({ id: assignmentId, ...assignmentData });
        assignmentsArray.push(doc.data());
      }
    });

    store.commit('setEventAssignments', assignmentsArray);


    return query;
  } catch (error) {
    console.error('Error during initial load:', error);
    return null;
  }
}

// getEventUsers({ commit }, payload) {
    //   console.log('getEventUsers')
    //   fb.userDaysCollection.where("preferredEvent", "==", payload).orderBy('created', 'asc')
    //   .onSnapshot(querySnapshot => {
    //     let eventUsersArray = []
    //     querySnapshot.forEach(doc => {
    //       let user = doc.data()
    //       eventUsersArray.push(user)
    //     })
    //     commit('setEventUsers', eventUsersArray)
    //   })
    // },

async function loadAllEventUsers(payload) {
  console.log(payload);
  if (!payload) {
    console.error('Invalid payload for loadAllEventUsers');
    return null;
  }

  const query = fb.userDaysCollection.where("preferredEvent", "==", payload)
  .orderBy("created", "desc")
    .limit("500")


  const usersArray = []; // Define usersArray here


  try {
    const querySnapshot = await query.get();

    querySnapshot.forEach((doc) => {
      usersArray.push(doc.data())
    });

    store.commit('setEventUsers', usersArray);


    return query;
  } catch (error) {
    console.error('Error during initial load:', error);
    return null;
  }
}

// async function loadAllEventAssignments(payload) {
//   console.log(payload);
  
//   if (!payload) {
//     console.error('Invalid payload for loadAllEventAssignments');
//     return null;
//   }

//   try {
//     const unsubscribe = fb.assignmentsCollection
//       .where("eventId", "==", payload)
//       .onSnapshot(snapshot => {
//         const assignmentsArray = [];

//         snapshot.forEach((doc) => {
//           const assignmentData = doc.data();
//           const assignmentId = doc.id;

//           if (!(assignmentData && assignmentData.paystatus && assignmentData.paystatus === 'paid')) {
//             // Include only necessary fields and omit paystatus === 'paid' assignments
//             const assignment = { id: assignmentId, ...assignmentData };
//             assignmentsArray.push(assignment);
//           }
//         });

//         store.commit('setEventAssignments', assignmentsArray);
//       });

//     return unsubscribe; // Return unsubscribe function to stop listening to changes
//   } catch (error) {
//     console.error('Error during initial load:', error);
//     return null;
//   }
// }




async function loadAllPaidEventAssignments(payload) {
  console.log(payload);
  if (!payload) {
    console.error('Invalid payload for loadAllPaidEventAssignments');
    return null;
  }

  const query = fb.assignmentsCollection.where("eventId", "==", payload);

  const paidArray = []; // Define assignmentsArray here

  try {
    const querySnapshot = await query.get();

    querySnapshot.forEach((doc) => {
      const assignmentData = doc.data();
      const assignmentId = doc.id;

      if (assignmentData && assignmentData.paystatus && assignmentData.paystatus == 'paid')
      {
        paidArray.push({ id: assignmentId, ...assignmentData });
      } else {
        
      }
    });

    store.commit('setPaidEventAssignments', paidArray);

    return query;
  } catch (error) {
    console.error('Error during initial load:', error);
    return null;
  }
}

async function attachRealtimeListener({ query, throttleIntervalInSeconds }) {
  if (query === null) {
    console.error('Query is null. Cannot attach a real-time listener.');
    return;
  }

  let isThrottled = false;

  const unsubscribe = query.onSnapshot((querySnapshot) => {
    if (isThrottled) return;

    const assignmentsArray = store.state.eventAssignments.slice(); // Create a copy of the existing assignments

    querySnapshot.docChanges().forEach((change) => {
      const assignmentData = change.doc.data();
      const assignmentId = change.doc.id;

      if (change.type === 'added' || change.type === 'modified') {
        // Handle added or modified eventAssignments optimistically
        const updatedAssignment = { id: assignmentId, ...assignmentData };
        const index = assignmentsArray.findIndex((a) => a.id === assignmentId);

        if (index === -1) {
          // Assignment doesn't exist in the current array, push it
          assignmentsArray.push(updatedAssignment);
        } else {
          // Assignment exists, update it
          assignmentsArray[index] = updatedAssignment;
        }
      } else if (change.type === 'removed') {
        // Handle removed eventAssignments
        const indexToRemove = assignmentsArray.findIndex((a) => a.id === assignmentId);

        if (indexToRemove !== -1) {
          assignmentsArray.splice(indexToRemove, 1);
        }
      }
    });

    // Commit the merged data to the store
    store.commit('setEventAssignments', assignmentsArray);
  });

  // Store the unsubscribe function for later use if needed
  // this.unsubscribeEventAssignments = unsubscribe;

  isThrottled = true;
  setTimeout(() => {
    isThrottled = false;
  }, throttleIntervalInSeconds * 2000);
}


// async function loadAllEmployeeAssignments(payload, onBatchLoaded, pageSize = 100, throttleDelay = 2000) {
//   console.log('loadAllEmployeeAssignments');
//   console.log(payload);

//   let query = fb.assignmentsCollection
//     .where("eventInfo.workerType", "==", payload)
//     .orderBy("day", "desc")
//     .limit(pageSize);

//   const assignmentsArray = [];
//   let lastDoc = null;

//   try {
//     const fetchNextBatch = async () => {
//       if (lastDoc) {
//         query = query.startAfter(lastDoc);
//       }

//       const querySnapshot = await query.get();

//       if (querySnapshot.empty) {
//         // No more documents to fetch
//         return;
//       }

//       querySnapshot.forEach((doc) => {
//         const assignmentData = doc.data();
//         const assignmentId = doc.id;
//         assignmentsArray.push({ id: assignmentId, ...assignmentData });
//       });

//       // Set the last document as the marker for the next page
//       lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

//       // Invoke the callback function with the current batch of results
//       if (onBatchLoaded && typeof onBatchLoaded === 'function') {
//         onBatchLoaded(assignmentsArray.slice()); // Pass a copy of the array
//       }

//       // Schedule the next batch fetch
//       setTimeout(fetchNextBatch, throttleDelay);
//     };

//     // Start fetching the first batch
//     fetchNextBatch();

//     // Move the commit outside of the loop, after fetching all data
//     store.commit('setAllEmployeeAssignments', assignmentsArray);

//     return query;
//   } catch (error) {
//     console.error('Error during data loading:', error);
//     return null;
//   }
// }


async function loadAllEmployeeAssignments(payload, onBatchLoaded, pageSize = 100, batchSize = 10, throttleDelay = 4000) {
  console.log('loadAllEmployeeAssignments');
  console.log(payload);

  const query = fb.assignmentsCollection
    .where("eventInfo.workerType", "==", payload)
    .orderBy("day", "desc")
    .limit(pageSize);

  try {
    const assignmentsArray = await loadAssignmentsConcurrently(query, onBatchLoaded, batchSize, throttleDelay);

    // Move the commit outside of the concurrent loading function
    store.commit('setAllEmployeeAssignments', assignmentsArray);

    console.log(`Total assignments fetched: ${assignmentsArray.length}`);

    return query;
  } catch (error) {
    console.error('Error during data loading:', error);
    return null;
  }
}


async function attachRealtimeEmployeeAssignmentListener({ query, throttleIntervalInSeconds }) {
  if (query === null) {
    console.error('Query is null. Cannot attach a real-time listener.');
    return;
  }

  let isThrottled = false;

  const unsubscribe = query.onSnapshot((querySnapshot) => {
    if (isThrottled) return;

    const assignmentsArray = store.state.allEmployeeAssignments.slice(); // Create a copy of the existing assignments

    querySnapshot.docChanges().forEach((change) => {
      const assignmentData = change.doc.data();
      const assignmentId = change.doc.id;

      if (change.type === 'added' || change.type === 'modified') {
        // Handle added or modified eventAssignments optimistically
        const updatedAssignment = { id: assignmentId, ...assignmentData };
        const index = assignmentsArray.findIndex((a) => a.id === assignmentId);

        if (index === -1) {
          // Assignment doesn't exist in the current array, push it
          assignmentsArray.push(updatedAssignment);
        } else {
          // Assignment exists, update it
          assignmentsArray[index] = updatedAssignment;
        }
      } else if (change.type === 'removed') {
        // Handle removed eventAssignments
        const indexToRemove = assignmentsArray.findIndex((a) => a.id === assignmentId);

        if (indexToRemove !== -1) {
          assignmentsArray.splice(indexToRemove, 1);
        }
      }
    });
    // console.log(assignmentsArray)

    // Commit the merged data to the store
    store.commit('setAllEmployeeAssignments', assignmentsArray);
  });

  // Store the unsubscribe function for later use if needed
  // this.unsubscribeEventAssignments = unsubscribe;

  isThrottled = true;
  setTimeout(() => {
    isThrottled = false;
  }, throttleIntervalInSeconds * 2000);
}

async function attachRealtimeContractorAssignmentListener({ query, throttleIntervalInSeconds }) {
  if (query === null) {
    console.error('Query is null. Cannot attach a real-time listener.');
    return;
  }

  let isThrottled = false;

  const unsubscribe = query.onSnapshot((querySnapshot) => {
    if (isThrottled) return;

    const assignmentsArray = store.state.allContractorAssignments.slice(); // Create a copy of the existing assignments

    querySnapshot.docChanges().forEach((change) => {
      const assignmentData = change.doc.data();
      const assignmentId = change.doc.id;

      if (change.type === 'added' || change.type === 'modified') {
        // Handle added or modified eventAssignments optimistically
        const updatedAssignment = { id: assignmentId, ...assignmentData };
        const index = assignmentsArray.findIndex((a) => a.id === assignmentId);

        if (index === -1) {
          // Assignment doesn't exist in the current array, push it
          assignmentsArray.push(updatedAssignment);
        } else {
          // Assignment exists, update it
          assignmentsArray[index] = updatedAssignment;
        }
      } else if (change.type === 'removed') {
        // Handle removed eventAssignments
        const indexToRemove = assignmentsArray.findIndex((a) => a.id === assignmentId);

        if (indexToRemove !== -1) {
          assignmentsArray.splice(indexToRemove, 1);
        }
      }
    });
    // console.log(assignmentsArray)

    // Commit the merged data to the store
    store.commit('setAllContractorAssignments', assignmentsArray);
  });

  // Store the unsubscribe function for later use if needed
  // this.unsubscribeEventAssignments = unsubscribe;

  isThrottled = true;
  setTimeout(() => {
    isThrottled = false;
  }, throttleIntervalInSeconds * 2000);
}



// async function attachRealtimeListenerByDay({ query, throttleIntervalInSeconds }) {
//   if (query === null) {
//     console.error('Query is null. Cannot attach a real-time listener.');
//     return;
//   }

//   let isThrottled = false;

//   const unsubscribe = query.onSnapshot((querySnapshot) => {
//     if (isThrottled) return;

//     const assignmentsArray = store.state.eventAssignments.slice(); // Create a copy of the existing assignments

//     querySnapshot.docChanges().forEach((change) => {
//       const assignmentData = change.doc.data();
//       const assignmentId = change.doc.id;

//       if (change.type === 'added' || change.type === 'modified') {
//         // Handle added or modified eventAssignments optimistically
//         const updatedAssignment = { id: assignmentId, ...assignmentData };
//         const index = assignmentsArray.findIndex((a) => a.id === assignmentId);

//         if (index === -1) {
//           // Assignment doesn't exist in the current array, push it
//           assignmentsArray.push(updatedAssignment);
//         } else {
//           // Assignment exists, update it
//           assignmentsArray[index] = updatedAssignment;
//         }
//       } else if (change.type === 'removed') {
//         // Handle removed eventAssignments
//         const indexToRemove = assignmentsArray.findIndex((a) => a.id === assignmentId);

//         if (indexToRemove !== -1) {
//           assignmentsArray.splice(indexToRemove, 1);
//         }
//       }
//     });

//     // Commit the merged data to the store
//     store.commit('setEventAssignmentsByDay', assignmentsArray);
//   });

//   // Store the unsubscribe function for later use if needed
//   // this.unsubscribeEventAssignments = unsubscribe;

//   isThrottled = true;
//   setTimeout(() => {
//     isThrottled = false;
//   }, throttleIntervalInSeconds * 1000);
// }


// Define an async function to perform the batched writes with throttling
async function performBatchedWrites(dataArray, batchSize, delayBetweenBatches) {
  const totalItems = dataArray.length;

  for (let startIndex = 0; startIndex < totalItems; startIndex += batchSize) {
    const endIndex = Math.min(startIndex + batchSize, totalItems);
    const batch = dataArray.slice(startIndex, endIndex);

    const batchWrite = fb.firestore().batch();

    batch.forEach((data) => {
      const docRef = fb.assignmentsCollection.doc(data.id); // Replace with the appropriate reference
      batchWrite.update(docRef, data);
    });

    try {
      await batchWrite.commit();
      console.log(`Batch of ${batch.length} items written successfully.`);
    } catch (error) {
      console.error('Error writing batched updates:', error);
    }

    // Introduce a delay before processing the next batch
    if (endIndex < totalItems) {
      await new Promise((resolve) => setTimeout(resolve, delayBetweenBatches));
    }
  }

  console.log('All data written successfully.');
}

// Define an async function to execute the batched writes
async function yourAsyncFunction() {
  const dataArray = []; // Replace with your data array
  const batchSize = 10; // Adjust the batch size as needed
  const delayBetweenBatches = 2000; // Adjust the delay as needed

  try {
    await pyourAsyncFunctions(dataArray, batchSize, delayBetweenBatches);
    console.log('Data written successfully.');
  } catch (error) {
    console.error('Error writing data:', error);
  }
}





async function throttleUpdates(updates, delayBetweenBatches) {
  const batchSize = 25; // Adjust the batch size as needed
  let currentIndex = 0;

  async function writeBatch() {
    const batch = fb.firestore().batch();
    const endIndex = Math.min(currentIndex + batchSize, updates.length);

    for (let i = currentIndex; i < endIndex; i++) {
      const update = updates[i];
      // Assuming `update.ref` is the reference to the document to update
      batch.update(update.ref, update.data);
    }

    currentIndex = endIndex;

    try {
      await batch.commit();
    } catch (error) {
      console.error('Error committing batch:', error);
    }

    if (currentIndex < updates.length) {
      // Schedule the next batch after a delay
      setTimeout(writeBatch, delayBetweenBatches);
    }
  }

  // Start writing batches
  writeBatch();
}

function fetchAssignmentDaysWithThrottle(query, lastDocument, uniqueDays, commit) {
  const batchSize = 100; // Adjust the batch size as needed
  const delayBetweenBatches = 2000; // 2 seconds delay between batches

  let isQueryInProgress = false;

  function fetchDataChunk() {
    if (isQueryInProgress) {
      setTimeout(fetchDataChunk, delayBetweenBatches);
      return;
    }

    isQueryInProgress = true;

    let chunkQuery = query.limit(batchSize);

    if (lastDocument) {
      chunkQuery = chunkQuery.startAfter(lastDocument);
    }

    chunkQuery
      .get()
      .then((chunkSnapshot) => {
        if (!chunkSnapshot.empty) {
          const daysArray = chunkSnapshot.docs.map((doc) => doc.data().day);
          const newUniqueDays = daysArray.reduce((unique, day) => {
            if (!unique.includes(day)) {
              unique.push(day);
            }
            return unique;
          }, uniqueDays);

          commit('setAssignmentDays', newUniqueDays);

          setTimeout(() => {
            isQueryInProgress = false;
            const lastDoc = chunkSnapshot.docs[chunkSnapshot.docs.length - 1];

            if (chunkSnapshot.size === batchSize) {
              fetchDataChunk(); // Fetch the next chunk
            } else {
              // No more data to fetch
            }
          }, delayBetweenBatches);
        } else {
          // No more data to fetch
        }
      })
      .catch((error) => {
        console.error('Error fetching assignment days:', error);
        isQueryInProgress = false; // Reset the flag when there's an error
      });
  }

  fetchDataChunk(); // Start fetching data
}


// function getEditedAssignment(editedAssignments, currentAssignment) {
//   const editedAssignment = editedAssignments.find((edited) => edited.id === currentAssignment.id);

//   if (editedAssignment) {
//     return { ...currentAssignment, ...editedAssignment };
//   }

//   return currentAssignment;
// }

function getEditedAssignment(editedAssignments, currentAssignment) {
  // Check if editedAssignments is an array
  if (Array.isArray(editedAssignments)) {
    const editedAssignment = editedAssignments.find((edited) => edited.id === currentAssignment.id);

    if (editedAssignment) {
      return { ...currentAssignment, ...editedAssignment };
    }
  }

  // If editedAssignments is not an array or no edited assignment was found, return the current assignment
  return currentAssignment;
}

const store = new Vuex.Store({
  state: {
    userProfile: {},
    currentUser: null,
    emailVerified: '',
    errorMessage: '',
    totalAssignmentsCount: '',
    requiredCount: '',
    venues: [],
    hiddenVenues: [],
    venueInfo: {},
    venueEvents: [],
    venueFollowers: [],
    users:[],
    users2:[],
    userInfo:{},
    userNotes: [],
    userTimesheets: [],
    userMessages: [],
    userEmailVerified: '',
    events: [],
    eventsByYear: [],
    events2021: [],
    events2022: [],
    eventInfo: {},
    eventShifts: [],
    eventNotes: [],
    eventDays: [],
    eventsByDay: [],
    eventUsers: [],
    eventUsersRef: [],
    eventDrops: [],
    allEvents: [],
    pastEvents: [],
    jobs:[],
    jobInfo: {},
    faqs:[],
    faqInfo: {},
    clients:[],
    clientInfo: {},
    dayShifts: [],
    dayUsers: [],
    availableUsers: [],
    dayEvents: [],
    shifts:[],
    shift:{},
    shiftAssignments: [],
    assignments: [],
    eventAssignments: [],
    paidEventAssignments: [],
    allEmployeeAssignments: [],
    allContractorAssignments: [],
    cachedAssignments: [], // Store for cached assignments
    usersPerDay: [],
    eventShifts: [],
    dayShifts: [],
    opr: [],
    eventTimesheetNotes: [],
    mgrs: [],
    mgrInfo: {},
    userEvents: [],
    userAssignments: [],
    userApplications: [],
    accountingNotes: [],
    payrollHelp: [],
    groups: [],
    hiddenGroups: [],
    group: {},
    groupUsers: [],
    unsubscribeUserListeners: [],
    groupApplications: [],
    groupMessages: [],
    groupNotes: [],
    reviews: [],
    contacts: [],
    contactInfo: {},
    contactNotes: [],
    clientNotes: [],
    clientVenues: [],
    referrals: [],
    stateUsers: [],
    cityUsers: [],
    appUsers: [],
    empUsers: [],
    clientContacts: [],
    followersGroupUsers: [],
    followersGroup: {},
    followersGroupMessages: [],
    followersGroups: [],
    pageText: {},
    clientAccess: [],
    clientAccessInfo: {},
    accessNotes: [],
    verifications: [],
    userVerifications: [],
    eventsByMonth: [],
    eventsByVenue: [],
    tags: [],
    tagInfo: {},
    infiniteEvents: [],
    nextInfiniteEvents: [],
    prevInfiniteEvents: [],
    lastVisibleEventSnapShot: {},
    firstVisibleEventSnapShot: {},
    taggedEvents: [],
    venueEventsSearchResults: [],
    staticEventAssignment: [],
    cancelledEvents: [],
    allPayroll: [],
    myRecaps: [],
    staticEventUsers: [],
    checkInMaster: null,
    eventsCancelled: [],
    completeEvents: [],
    partialEvents: [],
    eventsSearchResults: [],
    markets: [],
    marketingLeads: [],
    archivedLeads: [],
    marketLeads: [],
    eventAssignmentsByDay: [],
    eventAssignmentsByUser: [],
    shiftLeads: [],
    getGroupFromId: [],
    payrollSubmissions: [],
    activeShifts: [],
    usersTotal: null,
    usersWithoutAddress: [],
    assignmentsTotal: [],
    dailyUserData: [],
    groupWaitlist: [],
    weeks: [],
    weekInfo: {},
    oldUsersByState: [],
    userContractorPayProfile: {},
    workerFiles: {},
    employeeFiles: {},
    unpaidPayables: {},
    workerPayHistory: {},
    userEmployeePayProfile: {},
    employeeFiles: {},
    employeeUnpaidPayables: {},
    employeePayHistory: {},
    employeeTimesheetHistory: {},
    evereeContractors: {},
    userBackgroundChecks: [],
    subClients: [],
    backgroundCheck: {},
    payrollQueue: [],
    evereeUsersWithoutExternalId: [],
    nonContractors: [],
    eventsTotal: [],
    eventsTotalNext: [],
    newUsers: [],
    backgroundChecks: [],
    eventShiftsDays: [],
    assignmentDays: [],
    cachedAssignmentDays: [],
    achedEventUsers: [],
    JESNotes: [],
    onboardingUsers: [],
    activeWorkers: [],
    activeEmployees: [],
    onboardingEmployees: [],
    terminatedEmployees: [],
    hiredDirectEmployees: [],
    supportRequests: [],
    leadApplications: [],
    userWatchlist: [],
    activeDay: '',
    orderWatchlist: [],
    groupWatchlist: [],
    dayUsers: [],
    paidAssignments: [],
    associatedGroupUsers: [],
    skills: [],
    skill: {},
    certs: [],
    cert: {},
    skillCategories: [],
    payableContractors: [],
    payableEmployees: [],
    usersByProximity: [],
    employees: [],
    panels: [],
    pipeline: [],
    pipelineCancelled: [],
    pipelineHired: [],
    userPipeline: [],
    myPipeline: [],
    myOnboarding: [],
    myActive: [],
    backgroundCheckTypes: [],
    drugCheckTypes: [],
    allUserLogins: [],
    nYUsers: [],
    startAt: null,
    startAfter: null,
    editedAssignments: [],
    eventGroupUsers: [],
    startAfterGroupUsers: null,
    cachedEventGroupUsers: [],
  },



  actions: {

    // async Employee({ commit }) {
    //   console.log('Employee');
    //   try {
    //     const querySnapshot = await fb.assignmentsCollection
    //       .where("eventInfo.workerType", "==", "Employees")
    //       .get();

    //     let assignmentsArray = [];

    //     querySnapshot.forEach(doc => {
    //       let assignment = doc.data();
    //       assignmentsArray.push(assignment);
    //     });

    //     commit('setAllEmployeeAssignments', assignmentsArray);
    //   } catch (error) {
    //     console.error('Error fetching all employee assignments:', error);
    //     // Handle the error as needed
    //   }
    // },

    async getallEmployeeAssignmentsByDate({ commit }, payload) {
      console.log('getallEmployeeAssignmentsByDate');
      console.log(payload)
      const throttleIntervalInSeconds = 5; // Adjust the interval as needed

      if (payload.type && payload.type == 'Employees') {
        const query = await loadAllEmployeeAssignmentsByDate(payload);
        // Attach a real-time listener to a specific document when needed
        if (query !== null) {
          attachRealtimeEmployeeAssignmentListener({ query, throttleIntervalInSeconds });
        }
      } else {
        const query = await loadAllContractorAssignmentsByDate(payload);
        // Attach a real-time listener to a specific document when needed
        if (query !== null) {
          attachRealtimeContractorAssignmentListener({ query, throttleIntervalInSeconds });
        }
      }
    },

    async getAllEmployeeAssignments({ commit }, payload) {
      console.log('getAllEmployeeAssignments');

      const throttleIntervalInSeconds = 5; // Adjust the interval as needed

      const query = await loadAllEmployeeAssignments(payload);

      // Attach a real-time listener to a specific document when needed
      if (query !== null) {
        attachRealtimeEmployeeAssignmentListener({ query, throttleIntervalInSeconds });
      }
    },

    clearAllAssignments({ commit }) {
      commit('setAllEmployeeAssignments', [])
      commit('setAllContractorAssignments', [])
    },


    async getEventAssignmentsByDay({ commit }, payload) {
      console.log('getEventAssignmentsByDay');

      const throttleIntervalInSeconds = 3; // Adjust the interval as needed

      const query = await loadAllEventAssignmentsByDay(payload);

      // Attach a real-time listener to a specific document when needed
      if (query !== null) {
        attachRealtimeListenerByDay({ query, throttleIntervalInSeconds });
      }
    },

    async getEventAssignments({ commit }, payload) {
      console.log('getEventAssignments');

      const throttleIntervalInSeconds = 3; // Adjust the interval as needed

      const query = await loadAllEventAssignments(payload);

      // Attach a real-time listener to a specific document when needed
      if (query !== null) {
        attachRealtimeListener({ query, throttleIntervalInSeconds });
      }
    },

    // async getEventUsers({ commit }, payload) {
    //   console.log('getEventUsers');

    //   const throttleIntervalInSeconds = 3; // Adjust the interval as needed

    //   const query = await loadAllEventUsers(payload);

    //   // // Attach a real-time listener to a specific document when needed
    //   if (query !== null) {
    //     attachRealtimeListenerUsers({ query, throttleIntervalInSeconds });
    //   }
    // },


    // getEventUsers({ commit }, payload) {
    //   console.log('getEventUsers')
    //   fb.userDaysCollection.where("preferredEvent", "==", payload).orderBy('created', 'asc')
    //   .onSnapshot(querySnapshot => {
    //     let eventUsersArray = []
    //     querySnapshot.forEach(doc => {
    //       let user = doc.data()
    //       eventUsersArray.push(user)
    //     })
    //     commit('setEventUsers', eventUsersArray)
    //   })
    // },
  



    
    // async getEventAssignments({ commit }, payload) {
    //   console.log('getEventAssignments');

    //   try {
    //     const unsubscribe = fb.assignmentsCollection
    //       .where("eventId", "==", payload)
    //       .onSnapshot(snapshot => {
    //         const assignmentsArray = [];

    //         snapshot.forEach((doc) => {
    //           const assignmentData = doc.data();
    //           const assignmentId = doc.id;

    //           if (!(assignmentData && assignmentData.paystatus && assignmentData.paystatus === 'paid')) {
    //             // Include only necessary fields and omit paystatus === 'paid' assignments
    //             const assignment = { id: assignmentId, ...assignmentData };
    //             assignmentsArray.push(assignment);
    //           }
    //         });

    //         commit('setEventAssignments', assignmentsArray);
    //       });

    //     return unsubscribe; // Return unsubscribe function to stop listening to changes
    //   } catch (error) {
    //     console.error('Error during initial load:', error);
    //     return null;
    //   }
    // },

    async getEventAssignmentsByUser({ commit }, payload) {
      console.log('getEventAssignmentsByUser', payload)
  try {
    const assArray = [];
    const query = fb.assignmentsCollection.where("userId", "==", payload);

    // Attach a real-time listener to the query
    query.onSnapshot(querySnapshot => {
      assArray.length = 0; // Clear the array to prevent duplicates

      querySnapshot.forEach(doc => {
        assArray.push(doc.data());
      });
      commit("setEventAssignmentsByUser", assArray);
    });
  } catch (error) {
    // Handle any errors from Firestore
    console.error("Error fetching assignments for user:", error);
  }
},

    // async getEventAssignmentsByUser({ commit }, payload) {
    //   try {
    //     let assArray = []
    //     const querySnapshot = await fb.assignmentsCollection.where("userId", "==", payload).get();

    //     querySnapshot.forEach(function (doc) {
    //       assArray.push(doc.data())
          
    //     });
    //     console.log(assArray)
    //     commit("setEventAssignmentsByUser", assArray);
    //   } catch (error) {
    //     // Handle any errors from Firestore
    //     console.error("Error fetching assignments for user:", error);
    //   }
    // },

    // async getEventAssignments({ commit }, payload) {
    //   console.log('getEventAssignments');

    //   const throttleIntervalInSeconds = 2; // Adjust the interval as needed

    //   const query = await loadAllEventAssignments(commit, payload, throttleIntervalInSeconds);

    //   // Attach a real-time listener to a specific document when needed
    //   if (query !== null) {
    //     attachRealtimeListener(commit, query, throttleIntervalInSeconds);
    //   }
    // },

    // updateSSN({ commit }) {
    //   fb.oldUsersCollection.orderBy("ssn", "desc").limit(5000).onSnapshot(querySnapshot => {
    //     querySnapshot.forEach(doc => {
    //       if (doc.data().ssn && (doc.data().ssn.length > 10)) {
    //         console.log(doc.data().id)
    //         fb.oldUsersCollection.doc(doc.data().id).update({
    //           ssn: ''
    //          })
    //       }
         
    //     })
    //   })
    // },

    async login({ dispatch, commit }, form) {
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
      .catch(err => {
        console.log(err)
        commit('setErrorMessage', err.message)
        })


      // fetch user profile and set in state
      if ((user && user.email && user.emailVerified && (user.email.endsWith('jumpstaffing.com') || user.email.endsWith('goodmancf.com')))) {
        dispatch('fetchUserProfile', user)
        //change route to dashboard
        if (router.currentRoute.path === '/login') {
          router.push('/dashboard')
        }
      } else {
        store.dispatch('logout')
      }
    },

    


    async signup({ dispatch }, form) {
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

      // create user object in userCollections
      fb.usersCollection.doc(user.uid).set({
        firstName: form.firstName,
        lastName: form.lastName,
        id: user.uid,
        email: form.email,
        skills: [],
        status: 'applied',
        created: fb.firestore.FieldValue.serverTimestamp(),
      })


      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    },
    async fetchUserProfile({ commit }, user) {
      console.log('fetchUserProfile')
      // fetch user profile
      // const userProfile = await fb.usersCollection.doc(user.uid).get()

      const userProfile = await fb.usersCollection.doc(user.uid)
      .onSnapshot(function (doc) {
        if (doc.exists) {
          commit('setUserProfile', doc.data())
          // store.dispatch('getGroupWatchlist', user.uid)
          // store.dispatch('getOrderWatchlist', user.uid)
          // store.dispatch('getUserWatchlist', user.uid)
        } else {
         store.dispatch('logout')
        }
      })

      // set user profile in state
      commit('emailVerified', user.emailVerified)

      // set user profile in state
      // commit('setUserProfile', userProfile.data())
      // store.dispatch('getUserProfile')
      // store.dispatch('getClients')
      // store.dispatch('getGroups')

      // change route to dashboard
      // if (router.currentRoute.path === '/login') {
      //   router.push('/dashboard')
      // }
    },
    async logout({ commit }) {
      // log user out
      await fb.auth.signOut()

      // clear user data from state
      commit('setUserProfile', {})
      commit('setEvents', [])
      commit('setCurrentUser', null)
      commit('setVenues', [])
      commit('setJobs', [])
      commit('setClients', [])
      commit('setContacts', [])
      commit('setGroups', [])
      commit('setHiddenGroups', [])
      commit('setUserWatchlist', [])

      // redirect to login view
      router.push('/')
    },
    async getUserProfile({ commit, state }) {
      console.log('getUserProfile')
      await fb.usersCollection.doc(state.currentUser.uid)
      .onSnapshot(function (doc) {
        if (doc.exists) {
          commit('setUserProfile', doc.data())
          // store.dispatch('getGroupWatchlist', state.currentUser.uid)
          // store.dispatch('getOrderWatchlist', state.currentUser.uid)
          // store.dispatch('getUserWatchlist', state.currentUser.uid)
        } else {
         store.dispatch('logout')
        }
      })
    },

    // getMyRecaps({ commit, state }) {
    //   console.log('getMyRecaps')
    //   var eventsRef = fb.eventsCollection
    //   let documents = eventsRef.limit(1).get()
    //     .then(snapshot => {
    //       snapshot.forEach(doc => {
    //         console.log("Parent Document ID: ", doc.id);

    //         let subCollectionDocs = eventsRef.doc(doc.id/{venue}).collection("mgrs").get()
    //           .then(snapshot => {
    //             snapshot.forEach(doc => {
    //               console.log("Sub Document ID: ", doc.id);
    //             })
    //           }).catch(err => {
    //             console.log("Error getting sub-collection documents", err);
    //           })
    //       });
    //     }).catch(err => {
    //     console.log("Error getting documents", err);
    //   })
      
    // },
    updateUserProfile({ commit }, payload) {
      console.log('updateUserProfile')
      fb.usersCollection.doc(payload.id).update(payload)
      // const userProfile = fb.usersCollection.doc(payload.user.id).get()
      // commit('setUserProfile', userProfile)
      
    },
    // async getAllUserLogins({ commit }) {
    //   try {
    //     // Fetch user login data for thousands of users concurrently
    //     const querySnapshot = await fb.usersCollection.get();
    //     const allLogins = [];

    //     // Create an array of promises to fetch logins for each user
    //     const fetchLoginPromises = [];

    //     querySnapshot.forEach((doc) => {
    //       const userData = doc.data();
    //       if (userData.logins && userData.logins.length >= 1) {
    //         fetchLoginPromises.push(Promise.resolve(userData.logins));
    //       }
    //     });

    //     // Fetch logins concurrently for all users
    //     const userLoginsArrays = await Promise.all(fetchLoginPromises);

    //     // Flatten the array of arrays into a single array of logins
    //     userLoginsArrays.forEach((loginsArray) => {
    //       allLogins.push(...loginsArray);
    //     });

    //     // Commit the user login data to the store
    //     commit("setAllUserLogins", allLogins);
    //   } catch (error) {
    //     // Handle errors
    //     console.error("Error fetching user logins:", error);
    //     throw error;
    //   }
    // },
    async getAllUserLogins({ commit }, { pageSize = 250 }) {
      try {
        const allLogins = [];
        let startAfter = null;

        // Create a reference to the logins collection
        const loginsCollection = fb.loginsCollection;

        while (true) {
          let query = loginsCollection
            .orderBy(firebase.firestore.FieldPath.documentId())
            .limit(pageSize);

          if (startAfter) {
            query = query.startAfter(startAfter);
          }

          const querySnapshot = await query.get();

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              allLogins.push(doc.data());
            });

            // Commit the data to the store
            commit("setAllUserLogins", allLogins);

            // Update the startAfter cursor for the next page
            startAfter = querySnapshot.docs[querySnapshot.docs.length - 1];
          } else {
            // No more documents to fetch
            break;
          }
        }
      } catch (error) {
        // Handle errors
        console.error("Error fetching user logins:", error);
        throw error;
      }
    },
    clearAllUserLogins({ commit }) {
      commit("setAllUserLogins", []);
    },

    updateUser2({ commit }, payload) {
      console.log('updateUser2')
      let user = payload
      fb.usersCollection.doc(payload.userId).update(
      {
        employeeNumber: payload.employeeNumber,
        employeeStatus: payload.employeeStatus
      })
      // .then(
      //   store.dispatch('fetchUserProfile', user)
      // )
    },
    updateUserOrderAccurate({ commit }, payload) {
      fb.usersCollection.doc(payload).update({
        accurateCheckResponse: 'Order Placed',
        accurateCheckCreated: fb.firestore.FieldValue.serverTimestamp()
      })
    },
    updateUserOrderAmazon({ commit }, payload) {
      fb.usersCollection.doc(payload).update({
        amazonCheckResponse: 'Order Placed',
        amazonCheckCreated: fb.firestore.FieldValue.serverTimestamp()
      })
    },
    addAccurateNote({ commit }, payload) {
      fb.usersCollection.doc(payload.user).update({
        accurateCheckNotes: firebase.firestore.FieldValue.arrayUnion(payload.note)
      })
    },
    addAmazonNote({ commit }, payload) {
      fb.usersCollection.doc(payload.user).update({
        amazonCheckNotes: firebase.firestore.FieldValue.arrayUnion(payload.note)
      })
    },
    async updateUser({ commit }, payload) {
      try {
        await fb.usersCollection.doc(payload.id).update(payload);
        // Optionally, you can commit the updated user data to the store if needed.
        // commit('updateUserInStore', payload);
      } catch (error) {
        // Handle any errors from Firestore
        console.error("Error updating user:", error);
      }
    },

    updateTheUser({ commit }, payload) {
      console.log('updateTheUser')
      fb.usersCollection.doc(payload.id).update(payload)
    },
    updateTheUserAssignment({ commit }, payload) {
      console.log('updateTheUserAssignment')
      console.log(payload)
      fb.usersCollection.doc(payload.userId).update({
        employmentAssignment: payload.employmentAssignment
      })
    },
    updateUserToOnboard({ commit }, payload) {
      console.log('updateUserToOnboard')
      fb.usersCollection.doc(payload.id).update({
        jesOnboardList: payload.jesOnboardList,
        onboardingStarted: fb.firestore.FieldValue.serverTimestamp()
      })
    },
    // updateUserEmail({ commit }, payload) {
    //   console.log(payload)
    //   // fb.auth.updateUser(payload.id, {
    //   //   email: "modifiedUser@example.com"
    //   // });
    //   admin.auth().updateUser(payload.id, {
    //     email: payload.email
    //   });

    //   // const user = fb.auth.currentUser

    //   // console.log(user)
    //   // user.updateEmail(payload.email).then(() => {
    //   //  console.log('email changed')
    //   // }).catch((error) => {
    //   //   console.log(error)
    //   //   commit('setErrorMessage', error)
    //   // })
    //   fb.usersCollection.doc(payload.id).update({
    //     email: payload.email
    //   })
    // },
    // updateUserEmail({ commit }, payload) {
    //   const user = fb.auth.currentUser

    //   console.log(user)
    //   user.updateEmail(payload.email).then(() => {
    //    console.log('email changed')
    //   }).catch((error) => {
    //     console.log(error)
    //     commit('setErrorMessage', error)
    //   })
    //   fb.usersCollection.doc(payload.id).update(payload)
    //   // .then(
    //   //   store.dispatch('fetchUserProfile', user)
    //   // )
    // },
    resendVerificationEmail({ commit }) {
      fb.auth.currentUser.sendEmailVerification()
      .then(() => {
       console.log('email changed')
      }).catch((error) => {
        console.log(error)
      })
    },
    incrementUser({ commit }, payload) {
      console.log('incrementUser')
      fb.usersCollection.doc(payload.user.id).update({
        points: firebase.firestore.FieldValue.increment(payload.points)
      })
      // store.dispatch("getUserFromId", payload.user.id);
    },

    // async updateAllUserDays({ commit }) {
    //   console.log('updateAllUserDays')
    //   const updateAllUserDays = firebase.functions().httpsCallable('updateAllUserDays')
    //     await updateAllUserDays()

    // },



    /*BACKGROUND CHECKS*/
    async retrieveChecks({ commit }) {
      console.log('retrieveChecks')
      const retrieveChecks = firebase.functions().httpsCallable('retrieveChecks')

        await retrieveChecks()
        .then(result => {
          if (result && result.data) {
            console.log(result.data)
            return result.data
          }
        })
    },
    async resendInvitation({ commit }, payload) {
      console.log('resendInvitation')
      console.log(payload)
      const resendInvitation = firebase.functions().httpsCallable('resendInvitation', )
      await resendInvitation(payload)
      .then(result => {
        console.log(result.data)
        if (result && result.data) {
          return fb.usersCollection.doc(payload.userId).update({
            checkInvitations: firebase.firestore.FieldValue.arrayUnion(result.data)
          })
        }
      })
    },
    async orderCheck({ commit }, payload) {
      console.log('orderCheck');

      try {
        const orderCheck = firebase.functions().httpsCallable('orderCheck');
        const result = await orderCheck(payload);

        if (result && result.data) {
          console.log(result.data);

          // Update the user document with the received data
          await fb.usersCollection.doc(payload.userId).update({
            checkInvitations: firebase.firestore.FieldValue.arrayUnion(result.data)
          });

          // Optionally commit the result to the state if needed
          // commit('setCheckInvitation', result.data);
        }
      } catch (error) {
        console.error('Error during orderCheck:', error);
        // Handle the error gracefully if needed
      }
    },

    async orderDrug({ commit }, payload) {
  console.log('orderDrug');
  console.log(payload);

      try {
        const orderDrug = firebase.functions().httpsCallable('orderDrug');
        const result = await orderDrug(payload);

        if (result && result.data) {
          console.log(result.data);

          // Update the user document with the received data
          await fb.usersCollection.doc(payload.userId).update({
            drugInvitations: firebase.firestore.FieldValue.arrayUnion(result.data)
          });

          // Optionally commit the result to the state if needed
          commit('setDrugInvitation', result.data);
        }
      } catch (error) {
        console.error('Error during orderDrug:', error);
        // Handle the error gracefully if needed
      }
    },

   // getInvitationUpdates({ commit }, payload) {
   //  console.log('getInvitationUpdates')
   //    return fb.usersCollection.doc(payload).get()
   //    .then(doc => {
   //      console.log(doc.data())
   //      if (doc.data() && doc.data().checkInvitations && doc.data().checkInvitations[0] && doc.data().checkInvitations[0].payload && doc.data().checkInvitations[0].payload.result) {
   //        let invitation = doc.data().checkInvitations[0].payload.result.invitationID
   //        console.log(invitation)
   //        const updateInvitation = firebase.functions().httpsCallable('updateInvitation', )
   //        updateInvitation({
   //          id: invitation,
   //          userId: payload
   //        })
   //        .then(result => {
   //          if (result && result.data) {
   //            return fb.usersCollection.doc(payload).update({
   //              checkInvitationResponses: firebase.firestore.FieldValue.arrayUnion(result.data)
   //              // checkInvitationResponse: result.data
   //            })
   //            .then(() => {
   //              if (result.data && result.data.payload && result.data.payload[0] && result.data.payload[0].OrderID) {
   //                const updateOrder = firebase.functions().httpsCallable('updateOrder', )
   //                updateOrder({
   //                  id: result.data.payload[0].OrderID,
   //                  userId: payload
   //                })
   //                .then(result => {
   //                  console.log(result.data)
   //                  return fb.usersCollection.doc(payload).update({
   //                    checkOrderResults: firebase.firestore.FieldValue.arrayUnion(result.data)
   //                    // checkOrderResults: result.data
   //                  })
   //                })
   //              } else {
   //                console.log('else')
   //                // return fb.usersCollection.doc(payload).update({
   //                //   checkOrderResults: firebase.firestore.FieldValue.arrayUnion({
   //                //     description: 'Order Not Completed'
   //                //   })
                    
   //                // })
   //              }
   //            })
   //          }
   //        })
   //      } else {}
   //    })
   //  },
async getInvitationUpdates({ commit }, payload) {
  console.log('getInvitationUpdates');
  
  try {
    const userDoc = await fb.usersCollection.doc(payload).get();
    const userData = userDoc.data();

    if (userData && userData.checkInvitations && userData.checkInvitations[0] && userData.checkInvitations[0].payload && userData.checkInvitations[0].payload.result) {
      const invitation = userData.checkInvitations[0].payload.result.invitationID;
      console.log(invitation);

      const updateInvitation = firebase.functions().httpsCallable('updateInvitation');
      const result1 = await updateInvitation({
        id: invitation,
        userId: payload
      });

      if (result1 && result1.data) {
        await fb.usersCollection.doc(payload).update({
          checkInvitationResponses: firebase.firestore.FieldValue.arrayUnion(result1.data)
        });

        if (result1.data && result1.data.payload && result1.data.payload[0] && result1.data.payload[0].OrderID) {
          const updateOrder = firebase.functions().httpsCallable('updateOrder');
          const result2 = await updateOrder({
            id: result1.data.payload[0].OrderID,
            userId: payload
          });

          if (result2 && result2.data) {
            console.log(result2.data);
            await fb.usersCollection.doc(payload).update({
              checkOrderResults: firebase.firestore.FieldValue.arrayUnion(result2.data)
            });
          }
        } else {
          console.log('Order Not Completed');
          // Handle the case when OrderID is not present
          // await fb.usersCollection.doc(payload).update({
          //   checkOrderResults: firebase.firestore.FieldValue.arrayUnion({
          //     description: 'Order Not Completed'
          //   })
          // });
        }
      }
    }
  } catch (error) {
    console.error('Error during getInvitationUpdates:', error);
    // Handle the error gracefully if needed
  }
},

    // getDrugInvitationUpdates({ commit }, payload) {
    //   console.log('getDrugInvitationUpdates')
    //   return fb.usersCollection.doc(payload).get()
    //   .then(doc => {
    //     console.log(doc.data())
    //     if (doc.data() && doc.data().drugInvitations && doc.data().drugInvitations[0] && doc.data().drugInvitations[0].payload && doc.data().drugInvitations[0].payload.result) {
    //       let invitation = doc.data().drugInvitations[0].payload.result.invitationID
    //       console.log(invitation)
    //       const updateInvitation = firebase.functions().httpsCallable('updateInvitation', )
    //       updateInvitation({
    //         id: invitation,
    //         userId: payload
    //       })
    //       .then(result => {
    //         if (result && result.data) {
    //           return fb.usersCollection.doc(payload).update({
    //             drugInvitationResponses: firebase.firestore.FieldValue.arrayUnion(result.data)
    //             // drugInvitationResponse: result.data
    //           })
    //           .then(() => {
    //             if (result.data && result.data.payload && result.data.payload[0] && result.data.payload[0].OrderID) {
    //               const updateOrder = firebase.functions().httpsCallable('updateOrder', )
    //               updateOrder({
    //                 id: result.data.payload[0].OrderID,
    //                 userId: payload
    //               })
    //               .then(result => {
    //                 console.log(result.data)
    //                 return fb.usersCollection.doc(payload).update({
    //                   drugOrderResults: firebase.firestore.FieldValue.arrayUnion(result.data)
    //                   // drugOrderResults: result.data
    //                 })
    //               })
    //             } else {
    //               console.log('else')
    //               // return fb.usersCollection.doc(payload).update({
    //               //   drugOrderResults: firebase.firestore.FieldValue.arrayUnion({
    //               //     description: 'Order Not Completed'
    //               //   })
                    
    //               // })
    //             }
    //           })
    //         }
    //       })
    //     } else {}
    //   })
    // },
async getDrugInvitationUpdates({ commit }, payload) {
  console.log('getDrugInvitationUpdates');

  try {
    const userDoc = await fb.usersCollection.doc(payload).get();
    const userData = userDoc.data();

    if (userData && userData.drugInvitations && userData.drugInvitations[0] && userData.drugInvitations[0].payload && userData.drugInvitations[0].payload.result) {
      const invitation = userData.drugInvitations[0].payload.result.invitationID;
      console.log(invitation);

      const updateInvitation = firebase.functions().httpsCallable('updateInvitation');
      const result1 = await updateInvitation({
        id: invitation,
        userId: payload
      });

      if (result1 && result1.data) {
        await fb.usersCollection.doc(payload).update({
          drugInvitationResponses: firebase.firestore.FieldValue.arrayUnion(result1.data)
        });

        if (result1.data && result1.data.payload && result1.data.payload[0] && result1.data.payload[0].OrderID) {
          const updateOrder = firebase.functions().httpsCallable('updateOrder');
          const result2 = await updateOrder({
            id: result1.data.payload[0].OrderID,
            userId: payload
          });

          if (result2 && result2.data) {
            console.log(result2.data);
            await fb.usersCollection.doc(payload).update({
              drugOrderResults: firebase.firestore.FieldValue.arrayUnion(result2.data)
            });
          }
        } else {
          console.log('Order Not Completed');
          // Handle the case when OrderID is not present
          // await fb.usersCollection.doc(payload).update({
          //   drugOrderResults: firebase.firestore.FieldValue.arrayUnion({
          //     description: 'Order Not Completed'
          //   })
          // });
        }
      }
    }
  } catch (error) {
    console.error('Error during getDrugInvitationUpdates:', error);
    // Handle the error gracefully if needed
  }
},

    async getOrderUpdate({ commit }, payload) {
      console.log('getOrderUpdate');
      const updateOrder = firebase.functions().httpsCallable('updateOrder');
      
      try {
        const result2 = await updateOrder({
          id: payload.OrderID,
          userId: payload.user
        });

        if (result2 && result2.data) {
          await fb.usersCollection.doc(payload.user).update({
            checkOrderResults: firebase.firestore.FieldValue.arrayUnion(result2.data)
          });
        }
      } catch (error) {
        console.error('Error during getOrderUpdate:', error);
        // Handle the error gracefully if needed
      }
    },

        async getDrugOrderUpdate({ commit }, payload) {
      console.log('getDrugOrderUpdate');
      const updateOrder = firebase.functions().httpsCallable('updateOrder');

      try {
        const result = await updateOrder({
          id: payload.OrderID,
          userId: payload.user
        });

        if (result && result.data) {
          console.log(result.data);
          await fb.usersCollection.doc(payload.user).update({
            drugOrderResults: firebase.firestore.FieldValue.arrayUnion(result.data)
          });
        }
      } catch (error) {
        console.error('Error during getDrugOrderUpdate:', error);
        // Handle the error gracefully if needed
      }
    },
  //   async updateClients({ commit }) {
  // console.log('updateClients');
  // try {
  //   const batchSize = 10; // Adjust the batch size as needed
  //   const querySnapshot = await fb.assignmentsCollection.orderBy("day", "desc").limit(100).get();
  //   const updatePromises = [];

  //   // Helper function to update assignments with clientId and subClientId
  //   const updateAssignment = async (doc, batch) => {
  //     const assignmentData = doc.data();
  //     if (!assignmentData.clientId && assignmentData.eventId) {
  //       const eventDoc = fb.eventsCollection.doc(assignmentData.eventId);
  //       console.log(assignmentData.eventId);

  //       const eventSnapshot = await eventDoc.get();
  //       const eventData = eventSnapshot.data();

  //       if (eventData) {
  //         const clientId = eventData.client[0].id;
  //         const subClientId = eventData.subClient[0].id;
  //         console.log(doc.id);

  //         // Update the assignment with clientId and subClientId
  //         const assignmentRef = fb.assignmentsCollection.doc(doc.id);
  //         batch.update(assignmentRef, {
  //           clientId: clientId,
  //           subClientId: subClientId,
  //         });

  //         console.log(`Updated assignment with ID ${doc.id} clientId: ${clientId}, subClientId: ${subClientId}`);
  //       }
  //     }
  //   };

    // Iterate through the assignments and update them in batches
//     let batch = fb.firestore().batch(); // Initialize the first batch
//     for (let i = 0; i < querySnapshot.docs.length; i++) {
//       const doc = querySnapshot.docs[i];
//       updatePromises.push(updateAssignment(doc, batch));

//       // Execute batch update when the batch size is reached
//       if (updatePromises.length >= batchSize) {
//         await Promise.all(updatePromises);
//         await batch.commit(); // Commit the batch
//         batch = fb.firestore().batch(); // Initialize a new batch
//         updatePromises.length = 0; // Clear the promises array
//       }
//     }

//     // Commit any remaining batch updates
//     if (updatePromises.length > 0) {
//       await Promise.all(updatePromises);
//       await batch.commit();
//     }

//     console.log('All assignments updated successfully.');
//   } catch (error) {
//     console.error('Error updating assignments:', error);
//   }
// },





    /*SKILLS AND CATEGORIES*/
    getSkills({ commit }) {
      console.log('getSkills')
      fb.skillsCollection
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let skillsArray = []
        querySnapshot.forEach(doc => {
          let skill = doc.data()
          skillsArray.push(skill)
        })
        commit('setSkills', skillsArray)
      })
    },
    clearSkills({ commit }) {
      commit('setSkills', [])
    },
    async getSkillFromId({ commit }, payload) {
      try {
        const querySnapshot = await fb.skillsCollection.where("id", "==", payload).get();

        querySnapshot.forEach(function (doc) {
          commit("setSkill", doc.data());
        });
      } catch (error) {
        // Handle any errors from Firestore
        console.error("Error fetching skill from ID:", error);
      }
    },
    clearSkill({ commit }) {
      commit('setSkill', {})
    },
    getSkillCategories({ commit }) {
       console.log('getSkillCategories')
      fb.skillCategoriesCollection
      // .onSnapshot(querySnapshot => {
        .get().then(querySnapshot => {
        let catsArray = []
        querySnapshot.forEach(doc => {
          let cat = doc.data()
          catsArray.push(cat)
        })
        commit('setSkillCategories', catsArray)
      })
    },
    clearSkillCategories({ commit }) {
      commit('setSkillCategories', [])
    },
    getCerts({ commit }) {
      console.log('getCerts')
      fb.certsCollection
      // .onSnapshot(querySnapshot => {
        .get().then(querySnapshot => {
        let certsArray = []
        querySnapshot.forEach(doc => {
          let cert = doc.data()
          certsArray.push(cert)
        })
        commit('setCerts', certsArray)
      })
    },
    clearCerts({ commit }) {
      commit('setCerts', [])
    },
    async getCertFromId({ commit }, payload) {
      try {
        const querySnapshot = await fb.certsCollection.where("id", "==", payload).get();

        querySnapshot.forEach(function (doc) {
          commit("setCert", doc.data());
        });
      } catch (error) {
        // Handle any errors from Firestore
        console.error("Error fetching certificate from ID:", error);
      }
    },
    clearCert({ commit }) {
      commit('setCert', {})
    },
    


    /*USER REPORTS*/

    // getOldUsersByState({ commit }) {
    //   console.log('getOldUsersByState')
    //   fb.oldUsersCollection.orderBy('id', 'asc').startAt(2001).limit(5000)
    //   .onSnapshot(querySnapshot => {
    //     let usersArray = []
    //     querySnapshot.forEach(doc => {
    //       let user = doc.data()
    //       usersArray.push(user)
    //     })
    //     commit('setOldUsersByState', usersArray)
    //   })
    // },
    // clearOldUsersByState({ commit }) {
    //   commit('setOldUsersByState', [])
    // },

    // getPayrollSubmissions({ commit, state }) {
    //   console.log('getPayrollSubmissions')
    //   fb.payrollSubmissionsCollection.orderBy('created', 'desc')
    //   .onSnapshot(querySnapshot => {
    //     let usersArray = []
    //     querySnapshot.forEach(doc => {
    //       let user = doc.data()
    //       usersArray.push(user)
    //     })
    //     const unique = Array.from(new Set(usersArray.map(a => a.userId)))
    //      .map(userId => {
    //        return usersArray.find(a => a.userId === userId)
    //      })
    //     commit('setPayrollSubmissions', unique)
    //   })
    // },
    async getPayrollSubmissions({ commit, state }) {
      console.log('getPayrollSubmissions');
      try {
        const querySnapshot = await fb.payrollSubmissionsCollection.orderBy('created', 'desc').get();
        let usersArray = [];

        querySnapshot.forEach(doc => {
          let user = doc.data();
          usersArray.push(user);
        });

        const unique = Array.from(new Set(usersArray.map(a => a.userId)))
          .map(userId => {
            return usersArray.find(a => a.userId === userId);
          });

        commit('setPayrollSubmissions', unique);
      } catch (error) {
        console.error('Error fetching payroll submissions:', error);
        // Handle error as needed, e.g., show an error message
      }
    },


    clearPayrollSubmissions({ commit }) {
      commit('setPayrollSubmissions', [])
    },
    onboardSubmission({ commit }, payload) {
      console.log('onboardSubmission')
      fb.payrollSubmissionsCollection.doc(payload.id).update({
        Onboarded: true
      })
    },
    unonboardSubmission({ commit }, payload) {
      console.log('unonboardSubmission')
      fb.payrollSubmissionsCollection.doc(payload.id).update({
        Onboarded: false
      })
    },




    // getUserReports({ commit, state }) {
    //   console.log('getUserReports')
    //    fb.usersCollection.onSnapshot(querySnapshot => {
    //     let usersArray = []
    //     let appUsers = []
    //     let empUsers = []
    //     querySnapshot.forEach(doc => {
    //       let user = doc.data()
    //       usersArray.push(user)
    //       if (doc.data().fcm_token) {
    //         appUsers.push(user)
    //       }
    //       if (doc.data().employeeNumber) {
    //         empUsers.push(user)
    //       }
    //     })
    //   })
    //   commit('setUsers', usersArray)
    //   commit('setAppUsers', appUsers)
    //   commit('setEmpUsers', empUsers)
    // },
    // clearUserReports({ commit }) {
    //   commit('setUsersByState', [])
    //   commit('setUsers', [])
    //   commit('setAppUsers', [])
    //   commit('setEmpUsers', [])
    // },

    getUsersWithoutAddress({ commit }) {
      let usersArray = []
      fb.usersCollection.get().then(function(querySnapshot) {
        querySnapshot.forEach(doc => {
          if (doc.exists && !doc.data().center) {
            let user = doc.data()
            usersArray.push(user)
          } else {

          }
        })
      commit('setUsersWithoutAddress', usersArray)
      })
    },

    clearUsersWithoutAddress({ commit }) {
      let usersArray = []
      commit('setUsersWithoutAddress', [])
    },
    async getDailyUserData({ commit }) {
      try {
        fb.dailyTotalUsersCollection.orderBy('totalUsersCount', 'desc').limit('45').get()
        .then(querySnapshot => {
          let dataArray = []
          querySnapshot.forEach(doc => {
            dataArray.push(doc.data())
          })
          commit('setDailyUserData', dataArray.reverse())
        })
      } catch (error) {
        // Handle errors
        console.error("Error fetching total user data:", error);
        throw error;
      }
    },
    clearDailyUserData({ commit }) {
      commit('setDailyUserData', [])
    },

    async getTotalUsers({ commit }) {
      try {
        const usersCollection = fb.usersCollection; // Assuming you have configured this correctly
        const batchSize = 100; // Adjust the batch size as needed
        let totalUsers = 0;
        let lastDocument = null;

        while (true) {
          const querySnapshot = await usersCollection
            .orderBy('id', 'asc') // Adjust the field and order as needed
            .startAfter(lastDocument)
            .limit(batchSize)
            .get();

          if (querySnapshot.empty) {
            break; // No more documents to fetch
          }

          totalUsers += querySnapshot.size;
          lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1];
        }

        commit('setUsersTotal', totalUsers); // Use the appropriate mutation
      } catch (error) {
        // Handle errors
        console.error("Error fetching total users:", error);
        throw error;
      }
    },



    async getAssignmentsTotal({ commit }) {
      try {
        const querySnapshot = await fb.assignmentsCollection.get();
        const total = querySnapshot.size;
        commit('setAssignmentsTotal', total);
      } catch (error) {
        // Handle any errors from Firestore
        console.error("Error fetching assignments total:", error);
      }
    },
    clearAssignmentsTotal({ commit }) {
      commit('setAssignmentsTotal', [])
    },
    // getOldUsersTotal({ commit }) {


    //     fb.oldUsersCollection.get().then(function(querySnapshot) {      
    //       console.log(querySnapshot.size); 
    //   });
    // },
    clearUsersTotal({ commit }) {
      console.log("clearingUsers");
      commit('setUsersTotal', null)
    },
    // removeOldUsers() {
    //   var deleteAuthUser = firebase.functions().httpsCallable('deleteAuthUser');
    //   // deleteAuthUser({ 
    //   //   uid: "r6DwEoLYs3ct5SU36aNyjbMGlfD2",
    //   // })
    //   deleteAuthUser()
    //   .then((result) => {
    //     console.log(result)
    //   })
    // },

    getEmployees({ commit }) {
      console.log('getEmployees')
      fb.usersCollection.where("employeeId", "!=", false)
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let employeesArray = []
        querySnapshot.forEach(doc => {
          let employee = doc.data()
          employeesArray.push(employee)
        })
        commit('setEmployees', employeesArray)
      })
    },
    clearEmployees({ commit }) {
      commit('setEmployees', [])
    },
    async getUsersByProximity({ commit }, payload) {
      console.log('getUsersByProximity');
      console.log(payload);

      try {
        if (
          payload &&
          payload.coords &&
          typeof payload.coords.lat === 'number' &&
          typeof payload.coords.lng === 'number'
        ) {
          console.log('valid coords');
          // Parse the payload distance as a number
          const distance = parseFloat(payload.distance);
          console.log('distance', distance);

          // Check if the parsed distance is a valid number
          if (!isNaN(distance)) {
            const lat1 = payload.coords.lat;
            const lng1 = payload.coords.lng;

            console.log('lat1', lat1);

            // Create a reference to the users collection
            const usersCollectionRef = fb.usersCollection;

            const pageSize = 100; // Adjust the page size as needed
            let query = usersCollectionRef
              .where('center', '!=', null) // Ensure user has valid coordinates
              .orderBy('center', 'asc'); // Order by document ID

            let lastDocument = null;
            const earthRadiusMiles = 3958.8; // Radius of the Earth in miles
            const allUsersArray = []; // Array to store all results

            while (true) {
              if (lastDocument) {
                query = query.startAfter(lastDocument);
              }

              const querySnapshot = await query.limit(pageSize).get();

              if (querySnapshot.empty) {
                if (allUsersArray.length === 0) {
                  // Handle the case where there are no matching results
                  console.log('No matching results found');
                  commit('setUsersByProximity', []); // Commit an empty array
                }
                break; // No more results to fetch
              }

              // Process and accumulate results in smaller chunks
              const usersArray = [];

              // Asynchronously iterate over the current page of query results
              for (const doc of querySnapshot.docs) {
                const user = doc.data();
                const userCenter = user.center;

                if (userCenter && userCenter !== null) {
                  const lat2 = userCenter.lat;
                  const lng2 = userCenter.lng;
                  const dLat = (lat2 - lat1) * (Math.PI / 180);
                  const dLng = (lng2 - lng1) * (Math.PI / 180);
                  const a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(lat1 * (Math.PI / 180)) *
                    Math.cos(lat2 * (Math.PI / 180)) *
                    Math.sin(dLng / 2) *
                    Math.sin(dLng / 2);
                  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                  const distanceInMiles = earthRadiusMiles * c;

                  if (distanceInMiles <= distance) {
                    usersArray.push(user);
                  }
                }

                lastDocument = doc; // Update the last retrieved document
              }

              // Add the batch results to the allUsersArray
              allUsersArray.push(...usersArray);

              // Commit the accumulated results in smaller chunks
              if (allUsersArray.length >= pageSize) {
                commit('setUsersByProximity', allUsersArray);
                await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust the delay as needed
                allUsersArray.length = 0; // Clear the accumulated results
              }
            }

            // Commit any remaining results
            if (allUsersArray.length > 0) {
              commit('setUsersByProximity', allUsersArray);
            }
          } else {
            // Handle the case where 'payload.distance' is not a valid number
            console.error('Invalid distance:', payload.distance);
            // You can choose to set a default value or show an error message
          }
        } else {
          // Handle the case where payload or payload.coords is not as expected
          console.error('Invalid payload or payload.coords:', payload);
          // You can choose to set a default value or show an error message
        }
      } catch (error) {
        console.error('Error fetching users by proximity:', error);
        // Handle error as needed, e.g., show an error message
      }
    },

    clearUsersByProximity({ commit }) {
      // commit('setUsers', [])
      commit('setUsersByProximity', [])
    },




    /*User Messages*/
    sendUserMessage({ commit }, payload) {
      console.log('sendUserMessage')
      fb.userMessagesCollection.add(payload)
      .then(
        doc => {
          fb.userMessagesCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp()
          })
        }
      )
    },
    async getUserMessages({ commit }, payload) {
      console.log("getting Messages");
      try {
        const querySnapshot = await fb.userMessagesCollection
          .where("userId", "==", payload)
          .orderBy('created', 'desc')
          .get();

        let userMessagesArray = [];

        querySnapshot.forEach(doc => {
          let message = doc.data();
          message.id = doc.id;
          userMessagesArray.push(message);
        });

        commit('setUserMessages', userMessagesArray);
      } catch (error) {
        console.error('Error fetching user messages:', error);
        // Handle error as needed, e.g., show an error message
      }
    },



    /*Client Access*/
    getClientAccess({ commit }) {
      console.log('getClientAccess')
      fb.clientAccessCollection.orderBy('lastName', 'asc').onSnapshot(querySnapshot => {
        let clientAccessArray = []
        querySnapshot.forEach(doc => {
          let item = doc.data()
          clientAccessArray.push(item)
        })
        commit('setClientAccess', clientAccessArray)
      })
    },
    getAccessClientFromId({ commit }, payload) {
      console.log("getAccessClientFromId")
      fb.clientAccessCollection.doc(payload).get()
      .then(
        doc => {
          commit("setClientAccessInfo", doc.data())
        }
      )
      store.dispatch('getClients', payload)
      store.dispatch('getAccessNotes', payload)
    },
    updateClientAccess({ commit }, payload) {
      console.log("updateClientAccess")
      fb.clientAccessCollection.doc(payload.id).update(payload)
    },
    clearAccessClientState({ commit }) {
      commit('setClientAccessInfo', {})
      commit('setAccessNotes', [])
      commit('setClients', [])
    },
    clearClientAccess({ commit }) {
      commit('setClientAccess', [])
      commit('setClients', [])
    },
    

    /*GROUPS*/

    addGroup({ commit }, payload) {
      console.log('addGroup')
      fb.groupsCollection.add(payload)
    },
    updateGroup({ commit }, payload) {
      console.log('updateGroup')
      fb.groupsCollection.doc(payload.id).update(payload)
    },


    addAllUsersToGroup({ commit }, payload) {
      console.log('addAllUsersToGroup')
      fb.groupsCollection.doc(payload.id).get()
      .then(doc => {
        console.log(doc)
        doc.data().members.forEach(member => {
          fb.usersCollection.doc(member).get()
          .then(doc => {
            console.log(doc.data())
            // store.dispatch("addUserToGroup", {
            //   group: payload,
            //   user: doc.data()
            // })
          })
        })
      })
    },


    updateBlacklist({ commit }, payload) {
      console.log('updateBlacklist')
      fb.usersCollection.doc(payload.user.id).update({
        blacklist: firebase.firestore.FieldValue.arrayUnion(payload.item)
      })
    },
    removeBlacklist({ commit }, payload) {
      console.log('removeBlacklist')
      fb.usersCollection.doc(payload.user.id).update({
        blacklist: firebase.firestore.FieldValue.arrayRemove(payload.item)
      })
    },
    newGroupApplicant({ commit }, payload) {
      console.log('newGroupApplicant')
      let userId = (payload.user.objectID || payload.user.id)
      fb.groupsCollection.doc(payload.group.id).update({
        applicants: firebase.firestore.FieldValue.arrayUnion(userId)
      })
    },
    // addUserToGroup({ commit }, payload) {
    //   console.log('addUserToGroup')
    //   let userId = (payload.user.objectID || payload.user.id)
    //   let newText = {
    //     name: payload.user.firstName,
    //     phone: payload.user.phone,
    //     userId: userId,
    //     type: 'groupAcceptance',
    //     group: payload.group.title
    //   }
    //   fb.textsCollection.add(newText)
    //   .then(
    //     doc => {
    //     fb.textsCollection.doc(doc.id).update({
    //       id: doc.id,
    //       created: fb.firestore.FieldValue.serverTimestamp()
    //     })
    //   })
    //   fb.usersCollection.doc(userId).update({
    //     groups: firebase.firestore.FieldValue.arrayUnion(payload.group.id)
    //   })
    //   fb.groupsCollection.doc(payload.group.id).update({
    //     members: firebase.firestore.FieldValue.arrayUnion(userId)
    //   })
    // },
    async addUserToGroup({ commit }, payload) {
      console.log('addUserToGroup');
      try {
        const userId = payload.user.objectID || payload.user.id;
        const newText = {
          name: payload.user.firstName,
          phone: payload.user.phone,
          userId: userId,
          type: 'groupAcceptance',
          group: payload.group.title,
        };

        const doc = await fb.textsCollection.add(newText);
        await fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp(),
        });

        await fb.usersCollection.doc(userId).update({
          groups: firebase.firestore.FieldValue.arrayUnion(payload.group.id),
        });

        await fb.groupsCollection.doc(payload.group.id).update({
          members: firebase.firestore.FieldValue.arrayUnion(userId),
        });
      } catch (error) {
        console.error('Error adding user to group:', error);
        // Handle error as needed, e.g., show an error message
      }
    },

    async removeUserFromGroup({ commit }, payload) {
      try {
        // Create a new text document
        const newText = {
          name: payload.user.firstName,
          phone: payload.user.phone,
          userId: payload.user.id,
          type: 'groupDenial',
          group: payload.group.title
        };

        const textDocRef = await fb.textsCollection.add(newText);

        // Update the text document with created timestamp
        await textDocRef.update({
          id: textDocRef.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        });

        // Update the user document to remove the group
        await fb.usersCollection.doc(payload.user.id).update({
          groups: firebase.firestore.FieldValue.arrayRemove(payload.group.id)
        });

        // Update the group document to remove the user
        await fb.groupsCollection.doc(payload.group.id).update({
          members: firebase.firestore.FieldValue.arrayRemove(payload.user.id)
        });

        // Optionally, commit a mutation if needed
        // commit('userRemovedFromGroup', payload);
      } catch (error) {
        // Handle any errors from Firestore updates
        console.error("Error removing user from group:", error);
      }
    },
    removeUserApplicationFromGroup({ commit }, payload) {
      console.log("removeUserApplicationFromGroup")
      fb.usersCollection.doc(payload.user.id).update({
        groupApplications: firebase.firestore.FieldValue.arrayRemove(payload.group.id),
      })
      fb.groupsCollection.doc(payload.group.id).update({
        applicants: firebase.firestore.FieldValue.arrayRemove(payload.user.id)
      })
    },
    removeWaitlistUserApplicationFromGroup({ commit }, payload) {
      console.log("removeWaitlistUserApplicationFromGroup")
      fb.usersCollection.doc(payload.user.id).update({
        groupWaitlist: firebase.firestore.FieldValue.arrayRemove(payload.group.id),
      })
      fb.groupsCollection.doc(payload.group.id).update({
        waitlist: firebase.firestore.FieldValue.arrayRemove(payload.user.id)
      })
    },
    acceptUserToGroup({ commit }, payload) {
      console.log("acceptUserToGroup")
      let newText = {
        name: payload.user.firstName,
        phone: payload.user.phone,
        userId: payload.user.id,
        type: 'groupAcceptance',
        group: payload.group.title
      }
      fb.textsCollection.add(newText)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
      })
      fb.usersCollection.doc(payload.user.id).update({
        groups: firebase.firestore.FieldValue.arrayUnion(payload.group.id),
        groupApplications: firebase.firestore.FieldValue.arrayRemove(payload.group.id),
      })
      fb.groupsCollection.doc(payload.group.id).update({
        members: firebase.firestore.FieldValue.arrayUnion(payload.user.id),
        applicants: firebase.firestore.FieldValue.arrayRemove(payload.user.id)
      })
    },
    acceptWaitlistUserToGroup({ commit }, payload) {
      console.log("acceptWaitlistUserToGroup")
      let newText = {
        name: payload.user.firstName,
        phone: payload.user.phone,
        userId: payload.user.id,
        type: 'groupAcceptance',
        group: payload.group.title
      }
      fb.textsCollection.add(newText)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
      })
      fb.usersCollection.doc(payload.user.id).update({
        groups: firebase.firestore.FieldValue.arrayUnion(payload.group.id),
        groupWaitlist: firebase.firestore.FieldValue.arrayRemove(payload.group.id),
      })
      fb.groupsCollection.doc(payload.group.id).update({
        members: firebase.firestore.FieldValue.arrayUnion(payload.user.id),
        waitlist: firebase.firestore.FieldValue.arrayRemove(payload.user.id)
      })
    },
    waitlistUserToGroup({ commit }, payload) {
      console.log("waitlistUserToGroup")
      let newText = {
        name: payload.user.firstName,
        phone: payload.user.phone,
        userId: payload.user.id,
        type: 'groupWaitlist',
        group: payload.group.title
      }
      fb.textsCollection.add(newText)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
      })
      fb.usersCollection.doc(payload.user.id).update({
        groupApplications: firebase.firestore.FieldValue.arrayRemove(payload.group.id),
        groupWaitlist: firebase.firestore.FieldValue.arrayUnion(payload.group.id),
      })
      fb.groupsCollection.doc(payload.group.id).update({
        waitlist: firebase.firestore.FieldValue.arrayUnion(payload.user.id),
        applicants: firebase.firestore.FieldValue.arrayRemove(payload.user.id)
      })
    },

    updateGroups({ commit, state }, payload) {
      console.log("updateGroups")
      let user = payload.user
      fb.usersCollection.doc(user.id).update(user)
      payload.groups.forEach(p => {
        // if (state.groups.some(i => i.title.includes(p))) {
        //   console.log("includes")
        // } else {
        //   console.log(p)
        //   fb.groupsCollection.add({
        //     title: p,
        //     owner: state.currentUser.uid
        //   })
        //   .then(
        //     doc => {
        //     fb.groupsCollection.doc(doc.id).update({
        //       id: doc.id,
        //       created: fb.firestore.FieldValue.serverTimestamp()
        //     })
        //   })
        // }
      })
    },
    getGroups({ commit }) {
      console.log('getGroups')
      fb.groupsCollection.orderBy('title', 'asc')
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let groupsArray = []
        let hiddenGroupsArray = []
        querySnapshot.forEach(doc => {
          let group = doc.data()
          groupsArray.push(group)
          // if (doc.data().visible) {
          //   groupsArray.push(group)
          // } else {
          //   hiddenGroupsArray.push(group)
          // }
        })
        // commit('setHiddenGroups', hiddenGroupsArray)
        commit('setGroups', groupsArray)
      })
    },
    async getGroupApplications({ commit }, payload) {
      console.log("getGroupApplications");

      const applicationsArray = [];
      const groupDocRef = fb.groupsCollection.doc(payload);

      // Use .onSnapshot to listen for updates in real-time
      groupDocRef.onSnapshot((doc) => {
        if (doc.exists) {
          const groupData = doc.data();
          const applicantPromises = [];

          if (groupData.applicants && groupData.applicants.length > 0) {
            groupData.applicants.forEach((applicant) => {
              const userDocRef = fb.usersCollection.doc(applicant);

              // Push the promise to the array of applicantPromises
              applicantPromises.push(userDocRef.get().then((userDoc) => {
                if (userDoc.exists) {
                  return userDoc.data();
                }
                return null;
              }));
            });

            // Resolve all applicantPromises concurrently
            Promise.all(applicantPromises)
              .then((applicants) => {
                // Filter out any null values
                const filteredApplicants = applicants.filter((applicant) => applicant !== null);
                commit('setGroupApplications', filteredApplicants);
              })
              .catch((error) => {
                console.error('Error fetching group applicants:', error);
              });
          } else {
            // No applicants in the group, clear the data in the state
            commit('setGroupApplications', []);
          }
        } else {
          // The group document doesn't exist, clear the data in the state
          commit('setGroupApplications', []);
        }
      });
    },

    clearGroupApplications({ commit }) {
      commit('setGroupApplications', [])
    },
    async getGroupWaitlist({ commit }, payload) {
      console.log("getGroupWaitlist");

      const waitlistArray = [];
      const groupDocRef = fb.groupsCollection.doc(payload);

      // Use .onSnapshot to listen for updates in real-time
      groupDocRef.onSnapshot((doc) => {
        if (doc.exists) {
          const groupData = doc.data();
          const waitlistPromises = [];

          if (groupData.waitlist && groupData.waitlist.length > 0) {
            groupData.waitlist.forEach((wait) => {
              const userDocRef = fb.usersCollection.doc(wait);

              // Push the promise to the array of waitlistPromises
              waitlistPromises.push(userDocRef.get().then((userDoc) => {
                if (userDoc.exists) {
                  return userDoc.data();
                }
                return null;
              }));
            });

            // Resolve all waitlistPromises concurrently
            Promise.all(waitlistPromises)
              .then((waitlist) => {
                // Filter out any null values
                const filteredWaitlist = waitlist.filter((wait) => wait !== null);
                commit('setGroupWaitlist', filteredWaitlist);
              })
              .catch((error) => {
                console.error('Error fetching group waitlist:', error);
              });
          } else {
            // No users in the waitlist, clear the data in the state
            commit('setGroupWaitlist', []);
          }
        } else {
          // The group document doesn't exist, clear the data in the state
          commit('setGroupWaitlist', []);
        }
      });
    },

    clearGroupWaitlist({ commit }) {
      commit('setGroupWaitlist', [])
    },
    getGroupUsers({ commit, state }, payload) {
  console.log("Listening for group user changes");

  const groupUsers = [];
  const groupDocRef = fb.groupsCollection.doc(payload);

  return new Promise((resolve, reject) => {
    const groupUnsubscribe = groupDocRef.onSnapshot((doc) => {
      if (doc.exists) {
        const groupData = doc.data();
        const memberPromises = [];

        if (groupData && groupData.members && groupData.members.length > 0) {
          const userDocRefs = groupData.members.map(member => fb.usersCollection.doc(member));
          const userUnsubscribeFunctions = [];

          userDocRefs.forEach(userDocRef => {
            const userUnsubscribe = userDocRef.onSnapshot(userDoc => {
              if (userDoc.exists) {
                const userData = userDoc.data();
                const index = groupUsers.findIndex(user => user.id === userData.id);
                if (index !== -1) {
                  groupUsers[index] = userData;
                } else {
                  groupUsers.push(userData);
                }

                commit('setGroupUsers', groupUsers);
              }
            });
            userUnsubscribeFunctions.push(userUnsubscribe);
          });

          state.unsubscribeUserListeners.forEach(unsubscribeFunc => unsubscribeFunc());
          commit('setGroupUsers', groupUsers);

          resolve(() => {
            groupUnsubscribe();
            userUnsubscribeFunctions.forEach(unsubscribeFunc => unsubscribeFunc());
          });
        } else {
          commit('setGroupUsers', []);
          resolve(() => {});
        }
      } else {
        commit('setGroupUsers', []);
        resolve(() => {});
      }
    });
  });
},
    // async getGroupUsers({ commit, state }, payload) {
    //   console.log("Listening for group user changes");

    //   const groupUsers = [];
    //   const groupDocRef = fb.groupsCollection.doc(payload);

    //   // Use .onSnapshot to listen for updates in real-time for the group document
    //   const groupUnsubscribe = groupDocRef.onSnapshot((doc) => {
    //     if (doc.exists) {
    //       const groupData = doc.data();
    //       const memberPromises = [];

    //       if (groupData && groupData.members && groupData.members.length > 0) {
    //         groupData.members.forEach((member) => {
    //           const userDocRef = fb.usersCollection.doc(member);

    //           // Use .onSnapshot to listen for updates in real-time for the user document
    //           const userUnsubscribe = userDocRef.onSnapshot((userDoc) => {
    //             if (userDoc.exists) {
    //               const userData = userDoc.data();
    //               // Add or update the user in the groupUsers array
    //               const index = groupUsers.findIndex((user) => user.id === userData.id);
    //               if (index !== -1) {
    //                 groupUsers[index] = userData;
    //               } else {
    //                 groupUsers.push(userData);
    //               }

    //               commit('setGroupUsers', groupUsers);
    //             }
    //           });

    //           // Store the user's unsubscribe function to clean up later
    //           memberPromises.push(userUnsubscribe);
    //         });

    //         // Clean up user document listeners when the group document changes
    //         state.unsubscribeUserListeners.forEach((unsubscribeFunc) => {
    //           unsubscribeFunc();
    //         });

    //         // Update groupUsers in the Vuex store
    //         commit('setGroupUsers', groupUsers);
    //       } else {
    //         // No members in the group, clear the data in the state
    //         commit('setGroupUsers', []);
    //       }
    //     } else {
    //       // The group document doesn't exist, clear the data in the state
    //       commit('setGroupUsers', []);
    //     }
    //   });

    //   // Clean up group document listener when necessary
    //   const unsubscribeGroup = () => {
    //     groupUnsubscribe();
    //     // Clean up user document listeners when unsubscribing from the group document
    //     state.unsubscribeUserListeners.forEach((unsubscribeFunc) => {
    //       unsubscribeFunc();
    //     });
    //   };

    //   // Return a function to unsubscribe from both group and user document listeners
    //   return () => {
    //     unsubscribeGroup();
    //   };
    // },


    async getGroupFromId({ commit }, payload) {
      console.log("getGroupFromId")
      await fb.groupsCollection.doc(payload)
      .get()
      .then(
        doc => {
        commit("setGroup", doc.data())
      })
    },
    getGroupMessages({ commit }, payload) {
      const groupUpdatesCollection = fb.groupUpdatesCollection
        .where("groupId", "==", payload)
        .orderBy('created', 'desc');

      const unsubscribe = groupUpdatesCollection.onSnapshot(querySnapshot => {
        const groupMessagesArray = [];

        querySnapshot.forEach(doc => {
          const message = doc.data();
          message.id = doc.id;
          groupMessagesArray.push(message);
        });

        // Remove duplicate messages based on the 'message' property
        const uniqueMessages = groupMessagesArray.reduce((acc, cur) => {
          if (!acc[cur.message]) {
            acc[cur.message] = cur;
          }
          return acc;
        }, {});

        const uniqueMessagesArray = Object.values(uniqueMessages);

        commit('setGroupMessages', uniqueMessagesArray);
      });

      // Optionally, return the unsubscribe function if needed to stop listening to updates.
      // return unsubscribe;
    },
    clearGroups({commit}) {
      commit('setGroups', [])
    },
    clearGroupState({ commit }) {
      commit('setGroup', {})
      commit('setGroupUsers', [])
      commit('setGroupWaitlist', [])
      commit('setGroupApplications', [])
      commit('setGroupMessages', [])
      commit('setGroupNotes', [])
      commit('setEventGroupUsers', [])
    },
    clearFollowersGroupState({ commit }) {
      commit('setFollowersGroup', {})
      commit('setFollowersGroupUsers', [])
      commit('setFollowersGroupMessages', [])
    },


    updateSkills({ commit, state }, payload) {
      console.log('updateSkills')
      let user = payload.user
      fb.usersCollection.doc(user.id).update(user)
    },


    /*PAYMENTS*/
    async requestPayment({ commit }, payload) {
      console.log('requestPayment')
      console.log(payload)

      let sendUserPayment

      // if (payload.eventDetails && payload.eventDetails.shiftType && payload.eventDetails.shiftType == 'Job') {
      sendUserPayment = firebase.functions().httpsCallable('requestPaymentForPayablesEmployees')
      await sendUserPayment([payload.userId])
      .then(result => {
        if (result && result.data && result.data.payableCount && result.data.payableCount == 0) {
          fb.assignmentsCollection.doc(payload.id).update({
            paystatus: 'paid',
          })
        } else {
          console.log('else')
        }
      })
    },
    async createNewPayable({ commit }, payload) {
      console.log('createNewPayable')
      let sendUserPayment
      sendUserPayment = firebase.functions().httpsCallable('createNewEmployeePayable')
      await sendUserPayment(payload)
    },
    async deletePayable({ commit }, payload) {
      console.log(payload)
      let deletePayable
      deletePayable = firebase.functions().httpsCallable('deletePayableEmployee')
      await deletePayable(payload)
    },

    // async deletePayableFromPayables({ commit }, payload) {
    //   console.log(payload)
    //   deletePayable = firebase.functions().httpsCallable('deletePayableEmployee')
    //   await deletePayable(payload)
    // },

    async createBatchEmployeePayment({ commit }, payload) {
      console.log('createBatchEmployeePayment')
      let sendUserPayment
      sendUserPayment = firebase.functions().httpsCallable('createBatchEmployeePayables')
      await sendUserPayment({
        users: payload.users,
        title: payload.title,
      })
      .then((result) => {
        return result.data
      })
    },

    async createBatchPayment({ commit }, payload) {
      console.log(payload)
      let sendUserPayment
      sendUserPayment = firebase.functions().httpsCallable('createBatchPayments')
      if (payload.type == 'Employees' || payload.type == 'Job') {
        sendUserPayment = firebase.functions().httpsCallable('createBatchEmployeePayments')
      } else {
        sendUserPayment = firebase.functions().httpsCallable('createBatchPayments')
      }
      await sendUserPayment({
        users: payload.users,
        title: payload.title,
      })
      .then((result) => {
        return result.data
      })

      
    },
    async createEmployeePayment({ commit }, payload) {
       const sendUserPayment = firebase.functions().httpsCallable('createEmployeeBatchPayment')
        await sendUserPayment(payload)
      return result.data
    },
    async createUserPayment({ commit }, payload) {
       const sendUserPayment = firebase.functions().httpsCallable('createBatchPayment')
        await sendUserPayment(payload)
      return result.data
    },
    async refreshContractor ({ commit }, payload) {
      const refreshContractorStatusById = firebase.functions().httpsCallable('refreshContractorStatusById')
        await refreshContractorStatusById(payload)
      // return result.data
    },
    async refreshEmployee ({ commit }, payload) {
      const refreshEmployeeStatusById = firebase.functions().httpsCallable('refreshEmployeeStatusById')
        await refreshEmployeeStatusById(payload)
      // return result.data
    },
    async refreshContractorByAssignment ({ commit }, payload) {

      const refreshContractorStatusById = firebase.functions().httpsCallable('refreshContractorStatusById')
       
      await refreshContractorStatusById({
        id: payload.userId
      })
      fb.assignmentsCollection.doc(payload.id).update({
        checking: false
      })
    },
    async refreshEmployeeByAssignment ({ commit }, payload) {
      console.log(payload)
      const refreshEmployeeStatusById = firebase.functions().httpsCallable('refreshEmployeeStatusById')
       
      await refreshEmployeeStatusById({
        id: payload.userId
      })
      fb.assignmentsCollection.doc(payload.id).update({
        checking: false
      })
    },


    /*PAYROLL HELP*/
    getPayrollHelp({ commit }) {
      console.log('getPayrollHelp')
      fb.payHelpCollection.orderBy('created', 'desc').onSnapshot(querySnapshot => {
        let payrollHelpArray = []
        querySnapshot.forEach(doc => {
          let ph = doc.data()
          ph.id = doc.id
          payrollHelpArray.push(ph)
        })
        commit('setPayrollHelp', payrollHelpArray)
      })
    },
    closePH({ commit }, payload) {
      fb.payHelpCollection.doc(payload.id).update({
        closed: true
      })
    },
    openPH({ commit }, payload) {
      fb.payHelpCollection.doc(payload.id).update({
        closed: false
      })
    },



    



    /*MESSAGING*/
    async callNewLeadMessage({ commit }, payload) {
       const newLeadMessage = firebase.functions().httpsCallable('newLeadMessage')
        await newLeadMessage({
          id: payload.id,
          phone: `1` + payload.phone
        })
      return result.data
    },
    updateProfileEmail({ commit }, payload) {
      console.log('updateProfileEmail')
      fb.emailsCollection.add(payload)
      .then(
        doc => {
        fb.emailsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          updateProfileReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updatePayrollEmail({ commit }, payload) {
      console.log('updatePayrollEmail')
      fb.emailsCollection.add(payload)
      .then(
        doc => {
        fb.emailsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          updatePayrollReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updateCertsFoodEmail({ commit }, payload) {
      console.log('updateCertsFoodEmail')
      fb.emailsCollection.add(payload)
      .then(
        doc => {
        fb.emailsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          updateCertsFoodReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updateCertsAlcoholEmail({ commit }, payload) {
      console.log('updateCertsAlcoholEmail')
      fb.emailsCollection.add(payload)
      .then(
        doc => {
        fb.emailsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          updateCertsAlcoholReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updateAvailabilityEmail({ commit }, payload) {
      console.log('updateAvailabilityEmail')
      fb.emailsCollection.add(payload)
      .then(
        doc => {
        fb.emailsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          updateAvailabilityReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    removeHold({ commit }, payload) {
      console.log(payload)
      fb.usersCollection.doc(payload.id).update({
        docHold: false
      })
    },
    docHold({ commit }, payload) {
      console.log(payload)
      fb.usersCollection.doc(payload.id).update({
        docHold: true,
        docHoldCreated: fb.firestore.FieldValue.serverTimestamp()
      })
      fb.textsCollection.add({
        name: payload.firstName,
        phone: payload.phone,
        userId: payload.id,
        type: 'docHold'
      })
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    requestIdText({ commit }, payload) {
      console.log(payload)
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          IdReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    requestBioText({ commit }, payload) {
      console.log(payload)
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          BioReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    requestSSNText({ commit }, payload) {
      console.log(payload)
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          SSNReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    requestAddressText({ commit }, payload) {
      console.log(payload)
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          addressReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updateAddressReminder({ commit }, payload) {
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          updateAddressReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updateDrugReminder({ commit }, payload) {
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp(),
        })
        fb.usersCollection.doc(payload.userId).update({
          updateDrugReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    sendBackgroundAlert({ commit }, payload) {
      console.log(payload)
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp(),
        })
      })
    },
    sendDrugAlert({ commit }, payload) {
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          name: payload.firstName,
          phone: payload.phone,
          created: fb.firestore.FieldValue.serverTimestamp(),
          type: "Drug Alert"
        })
      })
    },
    sendAccurateAlert({ commit }, payload) {
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          name: payload.firstName,
          phone: payload.phone,
          created: fb.firestore.FieldValue.serverTimestamp(),
          type: "Accurate Alert"
        })
      })
    },
    sendI9Reminder({ commit }, payload) {
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    sendOnboardingMessage({ commit }, payload) {
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updateBackgroundReminder({ commit }, payload) {
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp(),
        })
        fb.usersCollection.doc(payload.userId).update({
          updateBackgroundReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updateAccurateReminder({ commit }, payload) {
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp(),
        })
        fb.usersCollection.doc(payload.userId).update({
          updateAccurateReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updateProfileText({ commit }, payload) {
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          updateProfileReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updatePayrollText({ commit }, payload) {
      console.log(payload)
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp(),
          type: 'Pay'
        })
        fb.usersCollection.doc(payload.userId).update({
          updatePayrollReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updateEmployeePayrollText({ commit }, payload) {
      console.log(payload)
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp(),
          type: 'EmployeePay'
        })
        fb.usersCollection.doc(payload.userId).update({
          updateEmployeePayrollReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updateCertsFoodText({ commit }, payload) {
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          updateCertsFoodReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updateCertsAlcoholText({ commit }, payload) {
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          updateCertsAlcoholReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },
    updateAvailabilityText({ commit }, payload) {
      fb.textsCollection.add(payload)
      .then(
        doc => {
        fb.textsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        })
        fb.usersCollection.doc(payload.userId).update({
          updateAvailabilityReminderSent: fb.firestore.FieldValue.serverTimestamp()
        })
      })
    },



    


    /*VENUES*/
    addVenue({ commit }, payload) {
      fb.venuesCollection.add(payload)
      // .then(
      //   doc => {
      //     console.log(doc.id)
      //     fb.venuesCollection.doc(doc.id).update({
      //       id: doc.id,
      //       created: fb.firestore.FieldValue.serverTimestamp(),
      //     })
      //   }
      // )
      // store.dispatch('getVenues')
    },
    updateVenue({ commit }, payload) {
      console.log('updateVenue')
      fb.venuesCollection.doc(payload.id).update(payload)
    },
    updateVenueAccess({ commit }, payload) {
      console.log('updateVenueAccess')
      fb.usersCollection.where("id", "==", payload.user.objectID).onSnapshot(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          fb.venuesCollection.doc(payload.venue.id).update({
            access: firebase.firestore.FieldValue.arrayUnion(doc.data())
          })
        })
      })
    },
    deleteVenue({ commit }, payload) {
      fb.venuesCollection.doc(payload).delete()
    },
    updateOpr({ commit }, payload) {
      fb.oprCollection.doc(payload.id).update(payload)
    },
    removeOpr({ commit }, payload) {
      console.log(payload)
      fb.oprCollection.where("id", "==", payload.id).get()
      .then(
        doc => {
          fb.oprCollection.doc(doc.id).delete()
        }
      )
    },
    getVenues({ commit }) {
      console.log('getVenues')
      fb.venuesCollection.orderBy('title', 'asc')
      .get().then((querySnapshot) => {
        let venuesArray = []
        let venuesHiddenArray = []
        querySnapshot.forEach((doc) => {
          let venue = doc.data()
          if (doc.data().visible && doc.data().id) {
            venuesArray.push(venue)
          } else {
            venuesHiddenArray.push(venue)
          }
        })
        commit('setVenues', venuesArray)
        commit('setHiddenVenues', venuesHiddenArray)
      })
    },
    getVenueFromIdWithoutEvents({ commit }, payload) {
      console.log('getVenueFromId')
      fb.venuesCollection.doc(payload).get()
      .then(
        doc => {
          commit("setVenueInfo", doc.data())
        }
      )
    },
    getVenueFromId({ commit }, payload) {
      console.log('getVenueFromId')
      fb.venuesCollection.doc(payload).get()
      .then(
        doc => {
          commit("setVenueInfo", doc.data())
          // store.dispatch('getVenueEvents', payload)
          // store.dispatch('getVenueFollowers', payload)
        }
      )
    },
    clearVenueState({ commit }) {
      commit('setVenueInfo', {})
      commit('setVenueEvents', null)
      commit('setVenueFollowers', [])
    },
    clearVenuesState({ commit }) {
      commit('setVenues', [])
      commit('setHiddenVenues', [])
    },


    /*EVEREE*/

    async onboardContractor({ commit }, payload) {
      try {
        const doc = await fb.usersCollection.doc(payload.id).get();
        console.log(doc.data());

        const phone = doc.data().phone.replace(/[^0-9]/g, '');
        const firstName = doc.data().firstName;
        const lastName = doc.data().lastName;
        const email = doc.data().email;
        const externalWorkerId = doc.data().id;

        const onboardEveree = firebase.functions().httpsCallable('kickOffContractor');
        const result = await onboardEveree({
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          email: email,
          id: externalWorkerId
        });

        console.log(result.data);

        if (result && result.data && result.data.workerId) {
          await fb.usersCollection.doc(payload.id).update({
            workerId: result.data.workerId
          });
        } else {

        }

        

        // You can commit mutations or handle other actions here if needed.

      } catch (error) {
        console.error('An error occurred:', error);
        // Handle the error here, if necessary.
      }
    },
    // onboardContractor({ commit }, payload) {
    //   const userProfile = fb.usersCollection.doc(payload.id).get()
    //   .then(doc => {
    //     console.log(doc.data())
    //     let phone = doc.data().phone.replace(/[^0-9]/g, '');
    //     let firstName = doc.data().firstName
    //     let lastName = doc.data().lastName
    //     let email = doc.data().email
    //     let externalWorkerId = doc.data().id

    //     const onboardEveree = firebase.functions().httpsCallable('kickOffContractor')
    //     onboardEveree({
    //       firstName: firstName,
    //       lastName: lastName,
    //       phone: phone,
    //       email: email,
    //       id: externalWorkerId

    //     })
    //     .then(result => {
    //       console.log(result.data)
    //       fb.usersCollection.doc(payload.id).update({
    //         workerId: result.data.workerId
    //       })
    //     })
    //   })
    // },

    onboardContractors({ commit, state }) {
      state.nonContractors.forEach(contractor => {
        console.log(contractor)
        if(contractor.phone) {
          let phone = contractor.phone.replace(/[^0-9]/g, '');
          let firstName = contractor.firstName
          let lastName = contractor.lastName
          let email = contractor.email
          let externalWorkerId = contractor.id
          const onboardEveree = firebase.functions().httpsCallable('kickOffContractor')
          onboardEveree({
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            email: email,
            id: externalWorkerId
          })
          .then(result => {
            console.log(result.data)
            fb.usersCollection.doc(contractor.id).update({
              workerId: result.data.workerId
            })
          })
        } else {

        }
      })
    },

    async onboardEmployee({ commit }, payload) {
      console.log(payload);

      try {
        const doc = await fb.usersCollection.doc(payload.id).get();

        const {
          phone,
          firstName,
          lastName,
          email,
          id,
          address: {
            street_number,
            street,
            city,
            state,
            zip,
            unit = '',
          },
        } = doc.data();

        console.log(street_number);

        const [onboardEvereeEmployeeResult, onboardEvereeEmployeeTextResult] = await Promise.all([
          firebase.functions().httpsCallable('employeeEmbeddedOnboarding')({
            firstName,
            lastName,
            phone: phone.replace(/[^0-9]/g, ''),
            email,
            id,
            line1: `${street_number} ${street}`,
            city,
            state,
            zip,
            line2: unit,
          }),
          firebase.functions().httpsCallable('newOnboardText')({
            firstName,
            lastName,
            phone: phone.replace(/[^0-9]/g, ''),
            email,
          }),
        ]);

        console.log(onboardEvereeEmployeeResult.data);

        await fb.usersCollection.doc(payload.id).update({
          employeeId: onboardEvereeEmployeeResult.data.workerId,
          employmentType: onboardEvereeEmployeeResult.data.employmentType,
          hireDate: onboardEvereeEmployeeResult.data.hireDate,
          lifecycleStatus: onboardEvereeEmployeeResult.data.lifecycleStatus,
          onboardingStatus: onboardEvereeEmployeeResult.data.onboardingStatus,
          jesOnboard: true,
        });

        console.log(onboardEvereeEmployeeTextResult.data);

        store.dispatch('getUserFromId', payload);
      } catch (error) {
        console.error(error);
      }
    },


    /*PANELS*/
    getPanels({ commit }) {
      fb.panelsCollection.orderBy("state", "asc").onSnapshot(querySnapshot => {
        let panelsArray = []
        querySnapshot.forEach((doc) => {
          panelsArray.push(doc.data())
        })
        commit('setPanels', panelsArray)
      })
    },
    clearPanels({ commit }) {
      commit('setPanels', [])
    },


    /*SUPPORT*/
    getSupportRequests({ commit }) {
      fb.supportRequestCollection.orderBy('created', 'desc')
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let ticketsArray = []
        querySnapshot.forEach((doc) => {
          ticketsArray.push(doc.data())
        })

        commit('setSupportRequests', ticketsArray)
      })
    },
    clearSupportRequests({ commit }) {
      commit('setSupportRequests', [])
    },

    /*SUPPORT*/
    getLeadApplications({ commit }) {
      fb.leadApplicationsCollection.orderBy('created', 'asc')
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let leadsArray = []
        querySnapshot.forEach((doc) => {
          leadsArray.push(doc.data())
        })
        commit('setLeadApplications', leadsArray)
      })
    },
    clearLeadApplications({ commit }) {
      commit('setLeadApplications', [])
    },


    /*USERS*/

    addCreated({ commit }) {
      fb.usersCollection
      .get().then((querySnapshot) => {
         let usersArray = []
        querySnapshot.forEach((doc) => {
          if (doc.exists && (!doc.data().created) || doc.data().created == null) {
            fb.usersCollection.doc(doc.data().id).update({
              created: fb.firestore.FieldValue.serverTimestamp()
            })

            
          }
        })
      })
    },

    async getNYUsers({ commit }) {
  console.log('getNYUsers');

  try {
    const querySnapshot = await fb.usersCollection
      .where('isNY', '==', true)
      .orderBy('created', 'desc')
      .get();

    let usersArray = [];

    querySnapshot.forEach((doc) => {
      let user = doc.data();
      usersArray.push(user);
    });

    commit('setNYUsers', usersArray);
  } catch (error) {
    console.log('Error fetching NY users:', error);
    // Handle error as needed, e.g., show an error message
  }
},


     
  clearNYUsers({ commit }) {
    commit('setNYUsers', []);
  },


    
    async getUsers({ commit }) {
    console.log('getting users');

    try {
      const batchSize = 1000; // Adjust the batch size as needed
      const usersArray = [];

      let lastDocument = null;
      let done = false;

      while (!done) {
        const query = fb.usersCollection
          .orderBy('created', 'desc')
          .startAfter(lastDocument)
          .limit(batchSize);

        const querySnapshot = await query.get();

        if (querySnapshot.empty) {
          done = true;
        } else {
          const batchUsers = querySnapshot.docs
            .filter(doc => doc.data().center)
            .map(doc => doc.data());

          usersArray.push(...batchUsers);

          lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1];
        }
      }

      commit('setUsers', usersArray);
    } catch (error) {
      console.error('Error fetching users:', error);
      // Handle error as needed, e.g., show an error message
    }
  },

    getNewUsers({ commit }) {
      fb.usersCollection
      .orderBy('created', 'desc')
      .limit(500)
      .onSnapshot(querySnapshot => {
        let usersArray = []
        querySnapshot.forEach(doc => {
          const userData = doc.data()
          if (!userData.initialContactStatus || userData.initialContactStatus !== "Hide") {
            usersArray.push(userData);
          }
        })
        commit('setNewUsers', usersArray);
      })
    },

    
//     async getNewUsers({ commit }) {
//   console.log('getNewUsers');

//   try {
//     const query = fb.usersCollection
//       .orderBy('created', 'desc')
//       .limit(200);

//     // Initial fetch of new users
//     const querySnapshot = await query.get();

//     // Create an array for new users
//     const usersArray = [];

//     querySnapshot.forEach(doc => {
//       const userData = doc.data();
      
//     });

//     // Commit the initial batch update to the store
//     commit('setNewUsers', usersArray);

//     // Real-time updates for new users
//     query.onSnapshot(snapshot => {
//       snapshot.docChanges().forEach(change => {
//         const modifiedData = change.doc.data();
//         if (!modifiedData.initialContactStatus || modifiedData.initialContactStatus !== "Hide") {
//           if (change.type === "added") {
//             // Add the new user to the store
//             commit('addNewUser', modifiedData);
//           } else if (change.type === "modified") {
//             // Update the individual record in the store
//             commit('updateNewUser', modifiedData);
//           } else if (change.type === "removed") {
//             // Remove the user from the store
//             commit('removeNewUser', modifiedData);
//           }
//         }
//       });
//     });
//   } catch (error) {
//     console.error("Error fetching new users:", error);
//     // Handle any errors from Firestore
//   }
// },


    clearNewUsers({ commit }) {
      commit('setNewUsers', [])
    },
    async getUserFromId({ commit }, payload) {
      console.log("getUserFromId");

      try {
        // Fetch user data and listen for real-time updates
        const userDoc = fb.usersCollection.doc(payload);
        userDoc.onSnapshot((doc) => {
          const userData = doc.data();
          commit("setUserInfo", userData);
        });

        // Define an array of asynchronous tasks
        const asyncTasks = [
          store.dispatch('getUserBackgroundChecks', payload),
          store.dispatch('getUserNotes', payload),
          store.dispatch('retrieveByExternalId', payload),
          store.dispatch('retrieveByExternalEmployeeId', payload),
          store.dispatch('getUserAssignments', payload)
        ];

        // Execute the asynchronous tasks concurrently
        await Promise.all(asyncTasks);

        // Define an array of Firebase function calls
        const firebaseFunctionCalls = [
          firebase.functions().httpsCallable('checkForDrugUpdatesOnCall')({ id: payload }),
          firebase.functions().httpsCallable('checkForBackgroundUpdatesOnCall')({ id: payload }),
        ];

        // Execute the Firebase function calls concurrently
        await Promise.all(firebaseFunctionCalls);
      } catch (error) {
        console.error("Error in getUserFromId:", error);
        // Handle error as needed
      }
    },


    addTouchLog({ commit }, payload) {
      fb.usersCollection.doc(payload.userId).update({
        touchLog: firebase.firestore.FieldValue.arrayUnion(payload.log)
      })
    },
    sendUserToWatch({ commit }, payload) {
      fb.usersCollection.doc(payload.mgr).update({
        userWatchList: firebase.firestore.FieldValue.arrayUnion(payload.user)
      })
    },
    watchUser({ commit, state }, payload) {
      fb.usersCollection.doc(state.currentUser.uid).update({
        userWatchList: firebase.firestore.FieldValue.arrayUnion(payload)
      })
    },
    unWatchUser({ commit, state }, payload) {
      fb.usersCollection.doc(state.currentUser.uid).update({
        userWatchList: firebase.firestore.FieldValue.arrayRemove(payload)
      })
    },
    watchGroup({ commit, state }, payload) {
      fb.usersCollection.doc(state.currentUser.uid).update({
        groupWatchList: firebase.firestore.FieldValue.arrayUnion(payload)
      })
    },
    unWatchGroup({ commit, state }, payload) {
      fb.usersCollection.doc(state.currentUser.uid).update({
        groupWatchList: firebase.firestore.FieldValue.arrayRemove(payload)
      })
    },
    watchOrder({ commit, state }, payload) {
      console.log(payload)
      fb.usersCollection.doc(state.currentUser.uid).update({
        orderWatchList: firebase.firestore.FieldValue.arrayUnion(payload)
      })
    },
    unWatchOrder({ commit, state }, payload) {
      fb.usersCollection.doc(state.currentUser.uid).update({
        orderWatchList: firebase.firestore.FieldValue.arrayRemove(payload)
      })
    },
    async getUserWatchlist({ commit, state }) {
  try {
    const currentUserUid = state.currentUser.uid;
    const userRef = fb.usersCollection.doc(currentUserUid);

    const doc = await userRef.get();

    if (doc.exists) {
      const userWatchList = doc.data().userWatchList || [];

      console.log(userWatchList);

      if (userWatchList.length >= 1) {
        const promises = userWatchList.map(async (userId) => {
          const userDoc = await fb.usersCollection.doc(userId).get();
          if (userDoc.exists) {
            return userDoc.data();
          }
          return null; // Return null for non-existent users
        });

        const watchlistUsers = (await Promise.all(promises)).filter((user) => user !== null); // Filter out null values
        commit('setUserWatchlist', watchlistUsers);
      } else {
        commit('setUserWatchlist', []);
      }
    }
  } catch (error) {
    console.error('Error fetching user watchlist:', error);
    // Handle error as needed, e.g., show an error message
  }
},

    clearUserWatchlist({ commit }) {
      commit('setUserWatchlist', []);
    },
    // getUserWatchlist({ commit, state }) {
    //   const currentUserUid = state.currentUser.uid;
    //   const userRef = fb.usersCollection.doc(currentUserUid);

    //   userRef.get()
    //     .then(doc => {
    //       if (doc.exists) {
    //         const userWatchList = doc.data().userWatchList || [];

    //         if (userWatchList.length > 0) {
    //           const promises = userWatchList.map(userId => {
    //             return fb.usersCollection.doc(userId).get()
    //               .then(userDoc => userDoc.data());
    //           });

    //           return Promise.all(promises)
    //             .then(watchlistUsers => {
    //               commit("setUserWatchlist", watchlistUsers);
    //             });
    //         } else {
    //           commit("setUserWatchlist", []);
    //         }
    //       }
    //     });
    // },

    getOrderWatchlist({ commit, state }) {
      const currentUserUid = state.currentUser.uid;
      const userRef = fb.usersCollection.doc(currentUserUid);

      userRef.onSnapshot(doc => {
        const orderWatchList = doc.data()?.orderWatchList || [];

        if (orderWatchList.length > 0) {
          const promises = orderWatchList.map(orderId => {
            return fb.eventsCollection.doc(orderId).get()
              .then(orderDoc => orderDoc.data());
          });

          Promise.all(promises)
            .then(orderWatchlist => {
              commit("setOrderWatchlist", orderWatchlist);
            });
        } else {
          commit("setOrderWatchlist", []);
        }
      });
    },

    getGroupWatchlist({ commit, state }) {
      const currentUserUid = state.currentUser.uid;
      const userRef = fb.usersCollection.doc(currentUserUid);

      userRef.onSnapshot(doc => {
        const groupWatchList = doc.data()?.groupWatchList || [];

        if (groupWatchList.length > 0) {
          const promises = groupWatchList.map(groupId => {
            return fb.groupsCollection.doc(groupId).get()
              .then(groupDoc => groupDoc.data());
          });

          Promise.all(promises)
            .then(groupWatchlist => {
              commit("setGroupWatchlist", groupWatchlist);
            });
        } else {
          commit("setGroupWatchlist", []);
        }
      });
    },


    async retrieveByExternalEmployeeId({ commit }, payload) {
      console.log(payload)
      const retrieveByExternalId = firebase.functions().httpsCallable('retrieveEmployeeByExternalId')
        await retrieveByExternalId({
        id: payload
      })
      .then(result => {
        commit('setUserEmployeePayProfile', result.data)
      })
      store.dispatch('retrieveEmployeeFiles', payload)
      // store.dispatch('retrieveEmployeeUnpaidPayables', payload)
      // store.dispatch('retrieveEmployeePayHistory', payload)
    },
    async retrieveEmployeeUnpaidPayables({ commit }, payload) {
      const retrieveUnpaidPayables = firebase.functions().httpsCallable('retrieveUnpaidPayables')
        await retrieveUnpaidPayables({
        id: payload
      })
      .then(result => {
        console.log(result.data)
        commit('setEmployeeUnpaidPayables', result.data)
      })
    },
    async retrieveWorkerFiles({ commit }, payload) {
      const retrieveWorkerFiles = firebase.functions().httpsCallable('retrieveWorkerFiles')
        await retrieveWorkerFiles({
        id: payload
      })
      .then(result => {
        console.log(result.data)
        commit('setWorkerFiles', result.data)
      })
    },
    async retrieveEmployeeFiles({ commit }, payload) {
      const retrieveEmployeeFiles = firebase.functions().httpsCallable('retrieveEmployeeFiles')
        await retrieveEmployeeFiles({
        id: payload
      })
      .then(result => {
        console.log(result.data)
        commit('setEmployeeFiles', result.data)
      })
    },
    async retrieveWorkerPayHistory({ commit }, payload) {
      const retrieveWorkerPayHistory = firebase.functions().httpsCallable('retrieveWorkerPayHistory')
        await retrieveWorkerPayHistory({
        id: payload
      })
      .then(result => {
        commit('setWorkerPayHistory', result.data)
      })
    },
    async retrieveEmployeePayHistory({ commit }, payload) {
      const retrieveEmployeePayHistory = firebase.functions().httpsCallable('retrieveEmployeePayHistory')
        await retrieveEmployeePayHistory({
        id: payload
      })
      .then(result => {
        commit('setEmployeePayHistory', result.data)
      })
    },
    async retrieveEmployeeTimesheetHistory({ commit }, payload) {
      console.log('retrieveEmployeeTimesheetHistory', payload)
      const listWorkerTimesheetsFromEveree2 = firebase.functions().httpsCallable('listWorkerTimesheetsFromEveree2')
        await listWorkerTimesheetsFromEveree2({
        id: payload
      })
      .then(result => {
        commit('setEmployeeTimesheetHistory', result.data)
      })
    },

    async retrieveByExternalId({ commit }, payload) {
      console.log(payload)
      const retrieveByExternalId = firebase.functions().httpsCallable('retrieveByExternalId')
        await retrieveByExternalId({
        id: payload
      })
      .then(result => {
        console.log(result.data)
        commit('setUserContractorPayProfile', result.data)
      })
      // store.dispatch('retrieveWorkerFiles', payload)
      // store.dispatch('retrieveEmployeeFiles', payload)
      // // store.dispatch('retrieveUnpaidPayables', payload)
      // store.dispatch('retrieveWorkerPayHistory', payload)
      // store.dispatch('retrieveEmployeeTimesheetHistory', payload)
      // store.dispatch('retrieveEmployeePayHistory', payload)
      
    },
    async retrieveUnpaidPayables({ commit }, payload) {
      const retrieveUnpaidPayables = firebase.functions().httpsCallable('retrieveUnpaidPayables')
        await retrieveUnpaidPayables({
        id: payload
      })
      .then(result => {
        console.log(result.data)
        commit('setUnpaidPayables', result.data)
      })
    },
    // async retrieveWorkerFiles({ commit }, payload) {
    //   const retrieveWorkerFiles = firebase.functions().httpsCallable('retrieveWorkerFiles')
    //     await retrieveWorkerFiles({
    //     id: payload
    //   })
    //   .then(result => {
    //     console.log(result.data)
    //     commit('setWorkerFiles', result.data)
    //   })
    // },
    // async retrieveWorkerPayHistory({ commit }, payload) {
    //   const retrieveWorkerPayHistory = firebase.functions().httpsCallable('retrieveWorkerPayHistory')
    //     await retrieveWorkerPayHistory({
    //     id: payload
    //   })
    //   .then(result => {
    //     console.log(result.data)
    //     commit('setWorkerPayHistory', result.data)
    //   })
    // },
    async getEvereeContractors({ commit }) {
      const getEvereeContractors = firebase.functions().httpsCallable('getEvereeContractors')
        await getEvereeContractors()
      .then(result => {
        commit('setEvereeContractors', result.data.items)
      })
    },

    getEvereeUsersWithoutExternalId({ commit }) {
      fb.usersCollection
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let usersArray = []
        querySnapshot.forEach(doc => {
          if(doc.data().workerId && doc.data().evereeOnboardingComplete) {
            usersArray.push(doc.data())
          }
        })
        commit('setEvereeUsersWithoutExternalId', usersArray)
      })
    },
    clearEvereeUsersWithoutExternalId({ commit }) {
      commit('setEvereeUsersWithoutExternalId', [])
    },

    getNonContractors({ commit }) {
      fb.usersCollection
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let usersArray = []
        querySnapshot.forEach(doc => {
          if(!doc.data().workerId) {
            usersArray.push(doc.data())
          }
        })
        commit('setNonContractors', usersArray)
      })
    },
    clearNonContractors({ commit }) {
      commit('setNonContractors', [])
    },

    updateExternalIds({ commit }) {
      fb.usersCollection.onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc => {
          if(doc.data().workerId && doc.data().evereeOnboardingComplete) {
            console.log(doc.data().id)
            const updateExternalIds = firebase.functions().httpsCallable('updateExternalIds')
            updateExternalIds({
              id: doc.data().id,
              workerId: doc.data().workerId
            })
            .then((result) => {
                console.log(result)
              })

          }
        })
      })
    },

    // updateTheOrders1({ commit }) {
    //   const updateOrdersApplicantCount = firebase.functions().httpsCallable('updateOrdersApplicantCount')
    //     updateOrdersApplicantCount()
        
    // },

    // updateTheOrders2({ commit }) {

    //   const updateOrdersRequiredCount = firebase.functions().httpsCallable('updateOrdersRequiredCount')
    //     updateOrdersRequiredCount()

        
    // },

    // updateTheOrders3({ commit }) {

    //   const updateOrdersAssignmentCount = firebase.functions().httpsCallable('updateOrdersAssignmentCount')
    //     updateOrdersAssignmentCount()
        
    // },





    // addAccountingNote({ commit }, payload) {
    //   fb.accountingNotesCollection.add(payload)
    //   .then(
    //     doc => {
    //       fb.accountingNotesCollection.doc(doc.id).update({
    //         id: doc.id,
    //         created: fb.firestore.FieldValue.serverTimestamp()
    //       })
    //     }
    //   )
    // },
    // getAccountingNotes({ commit }, payload) {
    //   console.log("getAccountingNotes")
    //   fb.accountingNotesCollection.where("event.id", "==", payload).orderBy('created', 'desc').onSnapshot(querySnapshot => {
    //     let accountingNotesArray = []

    //     querySnapshot.forEach(doc => {
    //       let note = doc.data()
    //       note.id = doc.id
    //       accountingNotesArray.push(note)
    //     })
    //     commit('setAccountingNotes', accountingNotesArray)
    //   })
    // },
    // clearAccountingNotes({ commit }) {
    //   commit('setAccountingNotes', [])
    // },
    addUserReview({ commit, state }, payload) {
      console.log('addUserReview')
      fb.reviewsCollection.add(payload)
      .then(
        doc => {
          fb.reviewsCollection.doc(doc.id).update({
            userId: payload.userId,
            submittedBy: payload.submittedBy,
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp()
          })
        }
      )
      store.dispatch('getUserReviews', payload.userId)
    },
    addContactNote({ commit, state }, payload) {
      console.log('addContactNote')
      fb.contactNotesCollection.add(payload)
      .then(
        doc => {
          fb.contactNotesCollection.doc(doc.id).update({
            userId: payload.userId,
            submittedBy: state.currentUser.uid,
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp()
          })
        }
      )
    },
    getContactNotes({ commit }, payload) {
      console.log('getContactNotes')
      fb.contactNotesCollection.where("userId", "==", payload).orderBy('created', 'desc').onSnapshot(querySnapshot => {
        let userNotesArray = []

        querySnapshot.forEach(doc => {
          let note = doc.data()
          note.id = doc.id
          userNotesArray.push(note)
        })
        commit('setContactNotes', userNotesArray)
      })
    },
    addClientNote({ commit, state }, payload) {
      console.log('addClientNote')
      fb.clientNotesCollection.add(payload)
      .then(
        doc => {
          fb.clientNotesCollection.doc(doc.id).update({
            userId: payload.userId,
            submittedBy: payload.submittedBy,
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp()
          })
        }
      )
    },
    getClientNotes({ commit }, payload) {
      console.log('getClientNotes')
      fb.clientNotesCollection.where("userId", "==", payload).orderBy('created', 'desc').onSnapshot(querySnapshot => {
        let userNotesArray = []

        querySnapshot.forEach(doc => {
          let note = doc.data()
          note.id = doc.id
          userNotesArray.push(note)
        })
        commit('setClientNotes', userNotesArray)
      })
    },
    addAccessNote({ commit, state }, payload) {
      console.log('addAccessNote')
      fb.accessNotesCollection.add(payload)
      .then(
        doc => {
          fb.accessNotesCollection.doc(doc.id).update({
            userId: payload.userId,
            submittedBy: state.currentUser.uid,
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp()
          })
        }
      )
    },
    getAccessNotes({ commit }, payload) {
      console.log('getAccessNotes')
      fb.accessNotesCollection.where("userId", "==", payload).orderBy('created', 'desc').onSnapshot(querySnapshot => {
        let userNotesArray = []

        querySnapshot.forEach(doc => {
          let note = doc.data()
          note.id = doc.id
          userNotesArray.push(note)
        })
        commit('setAccessNotes', userNotesArray)
      })
    },
    addUserNote({ commit, state }, payload) {
      console.log('addUserNote')
      fb.userNotesCollection.add(payload)
      .then(
        doc => {
          fb.userNotesCollection.doc(doc.id).update({
            userId: payload.userId,
            submittedBy: payload.submittedBy,
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp()
          })
        }
      )
    },
    addJESNote({ commit, state }, payload) {
      fb.userJESNotesCollection.add(payload)
      .then(
        doc => {
          fb.userJESNotesCollection.doc(doc.id).update({
            userId: payload.userId,
            submittedBy: payload.submittedBy,
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp()
          })
        }
      )
      store.dispatch('getJESNotes', payload.userId)
    },
    getJESNotes({ commit }, payload) {
      fb.userJESNotesCollection.where("userId", "==", payload).orderBy('created', 'desc').onSnapshot(querySnapshot => {
        let userNotesArray = []

        querySnapshot.forEach(doc => {
          let note = doc.data()
          note.id = doc.id
          userNotesArray.push(note)
        })
        commit('setJESNotes', userNotesArray)
      })
    },
    getUserReviews({ commit }, payload) {
      console.log('getUserReviews');
      console.log(payload);

      return fb.reviewsCollection
        .where('userId', '==', payload)
        .orderBy('created', 'desc')
        .get()
        .then(querySnapshot => {
          let reviewsArray = [];

          querySnapshot.forEach(doc => {
            let review = doc.data();
            review.id = doc.id;
            reviewsArray.push(review);
          });

          console.log(reviewsArray);

          commit('setReviews', reviewsArray);

          let length = reviewsArray.length;
          let totalArray = reviewsArray.map(element => element.rating);
          let newRating = totalArray.reduce((a, b) => a + b, 0) / length;

          return fb.usersCollection.doc(payload).update({
            rating: newRating,
          });

          // You can return a promise if you need to chain additional actions.
          // return store.dispatch('getUserFromId', payload);
        })
        .catch(error => {
          console.error('Error fetching user reviews:', error);
          // Handle error as needed, e.g., show an error message
        });
    },

    // getUserReviews({ commit }, payload) {
    //   fb.reviewsCollection.where("userId", "==", payload).orderBy('created', 'desc').onSnapshot(querySnapshot => {
    //     let reviewsArray = []

    //     querySnapshot.forEach(doc => {
    //       let review = doc.data()
    //       review.id = doc.id
    //       reviewsArray.push(review)
    //     })
    //     commit('setReviews', reviewsArray)
    //   })
    // },


    // /*User Images*/
    // getUserImages({ commit }, payload) {
    //   fb.usersCollection.where("userId", "==", payload).orderBy('created', 'desc').onSnapshot(querySnapshot => {
    //     let userNotesArray = []

    //     querySnapshot.forEach(doc => {
    //       let note = doc.data()
    //       note.id = doc.id
    //       userNotesArray.push(note)
    //     })
    //     commit('setUserNotes', userNotesArray)
    //   })
    // }


    getUserNotes({ commit }, payload) {
      console.log("getUserNotes")
      fb.userNotesCollection.where("userId", "==", payload).orderBy('created', 'desc').onSnapshot(querySnapshot => {
        let userNotesArray = []

        querySnapshot.forEach(doc => {
          let note = doc.data()
          note.id = doc.id
          userNotesArray.push(note)
        })
        commit('setUserNotes', userNotesArray)
      })
    },
    getUserTimesheets({ commit }, payload) {
      console.log("getUserTimesheets")
      fb.assignmentsCollection.where("userId", "==", payload).orderBy('day', 'desc').onSnapshot(querySnapshot => {
        let timesheetsArray = []
        querySnapshot.forEach(doc => {
          let sheet = doc.data()
          timesheetsArray.push(sheet)
        })
        commit('setUserTimesheets', timesheetsArray)
      })
    },
    clearUserTimesheets({ commit }) {
      commit('setUserTimesheets', [])
    },
    getUserAssignments({ commit }, payload) {
      const assignmentsCollection = fb.assignmentsCollection
        .where("userId", "==", payload);

      assignmentsCollection.get()
        .then(querySnapshot => {
          const assignmentsArray = [];

          querySnapshot.forEach(doc => {
            const assignment = doc.data();
            assignmentsArray.push(assignment);
          });

          commit('setUserAssignments', assignmentsArray);
        })
        .catch(error => {
          // Handle any errors from Firestore
          console.error("Error fetching user assignments:", error);
        });
    },

    getUserApplications({ commit }, payload) {
      // Use try-catch for error handling
      try {
        const userDaysCollection = fb.userDaysCollection
          .where("userId", "==", payload);

        const unsubscribe = userDaysCollection.onSnapshot(querySnapshot => {
          const applicationsArray = [];

          querySnapshot.forEach(doc => {
            const application = doc.data();
            applicationsArray.push(application);
          });

          commit('setUserApplications', applicationsArray);
        });

        // Optionally, return the unsubscribe function if needed to stop listening to updates.
        // return unsubscribe;
      } catch (error) {
        // Handle any errors
        console.error("Error fetching user applications:", error);
      }
    },
    clearUserApplications({ commit }) {
      commit('setUserApplications', [])
    },
        getUserBackgroundChecks({ commit }, payload) {
      // Use try-catch for error handling
      try {
        const backgroundChecksCollection = fb.backgroundChecksCollection
          .where("userId", "==", payload);

        const unsubscribe = backgroundChecksCollection.onSnapshot(querySnapshot => {
          const backgroundChecksArray = [];

          querySnapshot.forEach(doc => {
            const check = doc.data();
            if (!check.response || (check.response && check.response !== 'Duplicate')) {
              backgroundChecksArray.push(check);
            }
          });

          commit('setUserBackgroundChecks', backgroundChecksArray);
        });

        // Optionally, return the unsubscribe function if needed to stop listening to updates.
        // return unsubscribe;
      } catch (error) {
        // Handle any errors
        console.error("Error fetching user background checks:", error);
      }
    },

    getBackgroundCheck({ commit }, payload) {
      console.log('getBackgroundCheck')
      fb.backgroundChecksCollection.doc(payload)
      .get().then(doc => {
        commit("setBackgroundCheck", doc.data())
      })
    },
    updateCheck({ commit }, payload) {
      console.log('updateCheck')
      fb.backgroundChecksCollection.doc(payload.id).update(payload)
    },
    clearBackgroundCheck({ commit }) {
      commit("setBackgroundCheck", {})
    },
    clearUserState({ commit }) {
      console.log('clearing user state')
      commit('setUserInfo', {})
      commit('setUserNotes', [])
      commit('setUserTimesheets', [])
      commit('setUserMessages', [])
      commit('setReviews', [])
      commit('setUserEvents', [])
      commit('setUserApplications', [])
      commit('setUserAssignments', [])
      commit('setUserVerifications', [])
      commit('setUserPayroll', [])
      commit('setUserContractorPayProfile', {})
      commit('setWorkerFiles', {})
      commit('setEmployeeFiles', {})
      commit('setUnpaidPayables', {})
      commit('setWorkerPayHistory', {})
      commit('setUserEmployeePayProfile', {})
      commit('setEmployeeFiles', {})
      commit('setEmployeeUnpaidPayables', {})
      commit('setEmployeePayHistory', {})
      commit('setEmployeeTimesheetHistory', {})
      commit('setUserBackgroundChecks', [])
      commit('setJESNotes', [])
      
    },
    clearUsersState({ commit }) {
      commit('setUsers', [])
    },
    clearEvereeContractors({ commit }) {
      commit('setEvereeContractors', {})
    },


    getBackgroundCheckTypes({ commit }) {
      fb.backgroundCheckTypesCollection
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let checksArray = []
        querySnapshot.forEach(function (doc) {
          let check = doc.data()
          checksArray.push(check)
        })
        commit('setBackgroundCheckTypes', checksArray)
      })
    },
    clearBackgroundCheckTypes({ commit }) {
      commit('setBackgroundCheckTypes', [])
    },

    getDrugCheckTypes({ commit }) {
      fb.drugCheckTypesCollection
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let checksArray = []
        querySnapshot.forEach(function (doc) {
          let check = doc.data()
          checksArray.push(check)
        })
        commit('setDrugCheckTypes', checksArray)
      })
    },
    clearDrugCheckTypes({ commit }) {
      commit('setDrugCheckTypes', [])
    },
    


    /*ACTIVE ASSIGNED EMPLOYEES*/
    // async getActiveWorkers({ commit }) {
    //   try {
    //     const limit = 50; // Number of documents to retrieve per query (adjust as needed)
    //     let lastDoc = null;
    //     const activeUsersArray = [];

    //     while (true) {
    //       let query = fb.userDaysCollection
    //         .where("status", "==", "assigned")
    //         .where("shiftType", "==", "Job")
    //         .limit(limit);

    //       if (lastDoc) {
    //         query = query.startAfter(lastDoc);
    //       }

    //       const querySnapshot = await query.get();

    //       if (querySnapshot.empty) {
    //         break; // No more results to fetch
    //       }

    //       querySnapshot.docs.forEach((doc) => {
    //         activeUsersArray.push(doc.data());
    //       });

    //       // Get the last document to use as a starting point for the next query
    //       lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    //     }

    //     commit('setActiveWorkers', activeUsersArray);
    //   } catch (error) {
    //     console.error('Error fetching active workers:', error);
    //     // Handle the error as needed, e.g., show a user-friendly message
    //     throw error; // Re-throw the error to propagate it to the caller if needed
    //   }
    // },
    async getActiveWorkers({ commit }) {
      try {
        const limit = 1000; // Number of documents to retrieve per query (adjust as needed)
        let lastDoc = null;
        const activeUsersArray = [];

        while (true) {
          let query = fb.userDaysCollection
            .where("status", "==", "assigned")
            .where("shiftType", "==", "Job")
            .limit(limit);

          if (lastDoc) {
            query = query.startAfter(lastDoc);
          }

          const querySnapshot = await query.get();

          if (querySnapshot.empty) {
            break; // No more results to fetch
          }

          querySnapshot.docs.forEach((doc) => {
            activeUsersArray.push(doc.data());
          });

          // Get the last document to use as a starting point for the next query
          lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        }

        commit('setActiveWorkers', activeUsersArray);
      } catch (error) {
        console.error('Error fetching active workers:', error);
        // Handle the error as needed, e.g., show a user-friendly message
        throw error; // Re-throw the error to propagate it to the caller if needed
      }
    },
    clearActiveWorkers({ commit }) {
      commit('setActiveWorkers', []);
    },




    /*ONBOARDING USERS*/
    // getOnboardingUsers({ commit }) {
    //   fb.usersCollection.where("jesOnboardList", "==", true)
    //   .get().then(querySnapshot => {
    //   // .onSnapshot(querySnapshot => {
    //     let usersArray = []
    //     querySnapshot.forEach(doc => {
    //       if (!doc.data().jesComplete) {
    //         usersArray.push(doc.data())
    //       } else {

    //       }
    //     })
    //     commit('setOnboardingUsers', usersArray)
    //   })
    // },
    clearOnboardingUsers({ commit }) {
      commit('setOnboardingUsers', [])
    },
    async getEmployeeReportData({ commit }) {
  console.log('getEmployeeReportData');

  const employmentStatuses = ["Onboarding", "Active", "Terminated", "HiredDirect"];

  try {
    // Create real-time listeners for each employment status
    const listeners = employmentStatuses.map(status => {
      return fb.usersCollection
        .where("employmentStatus", "==", status)
        .onSnapshot(querySnapshot => {
          const employeeData = querySnapshot.docs.map(doc => doc.data());
          commit(`set${status}Employees`, employeeData);
        });
    });

    // Optionally, you can return the listeners if you want to unsubscribe later
    // return listeners;
  } catch (error) {
    console.error("Error fetching employee data:", error);
  }
},
    // async getEmployeeReportData({ commit }) {
    //   console.log('getEmployeeReportData');
      
    //   const employmentStatuses = ["Onboarding", "Active", "Terminated", "HiredDirect"];
    //   const employeeData = {};

    //   // Create an object to store data for each employment status
    //   employmentStatuses.forEach(status => {
    //     employeeData[status] = [];
    //   });

    //   const query = fb.usersCollection.where("employmentStatus", "in", employmentStatuses);

    //   try {
    //     const querySnapshot = await query.get();

    //     querySnapshot.forEach(doc => {
    //       const data = doc.data();
    //       const status = data.employmentStatus;

    //       // Check if the status is one of the desired statuses
    //       if (employmentStatuses.includes(status)) {
    //         employeeData[status].push(data);
    //       }
    //     });

    //     // Commit data to Vuex store
    //     employmentStatuses.forEach(status => {
    //       commit(`set${status}Employees`, employeeData[status]);
    //     });
    //   } catch (error) {
    //     console.error("Error fetching employee data:", error);
    //   }
    // },
    clearEmployeeReportData({ commit }) {
      commit('setActiveEmployees', [])
      commit('setOnboardingEmployees', [])
      commit('setTerminatedEmployees', [])
      commit('setHiredDirectEmployees', [])
    },



    /*ShiftLeads*/

    getShiftLeads({ commit }, payload) {
      fb.leadApplicationsCollection.orderBy('created', 'desc')
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let leadsArray = []
        querySnapshot.forEach(function (doc) {
          let lead = doc.data()
          leadsArray.push(lead)
        })
        commit('setShiftLeads', leadsArray)
      })
    },
    clearShiftLeads({ commit }) {
      commit('setShiftLeads', [])
    },

    /*BACKGROUND CHECKS*/
    // async sendTheBackgroundCheckMessage({ commit }, payload) {
    //   console.log(payload)
    //   var sendBackgroundCheckMessage = firebase.functions().httpsCallable('sendBackgroundCheckMessage');
    //   await sendBackgroundCheckMessage(payload)
    // },
    async getBackgroundChecks({ commit }) {
      console.log('getBackgroundChecks')
      await fb.backgroundChecksCollection
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let checksArray = []
        querySnapshot.forEach(doc => {
          if(!doc.data().response || (doc.data().response && doc.data().response != 'Duplicate')) {
            let check = doc.data()
            checksArray.push(check)
          } else {}
        })
        commit('setBackgroundChecks', checksArray)
      })
    },
    clearBackgroundChecks({ commit }) {
      commit('setBackgroundChecks', [])
    },
    
    /*SUB CLIENTS*/
    getSubClients({ commit }) {
      console.log('getSubClients')
      fb.subClientsCollection
      .onSnapshot(querySnapshot => {
        let cArray = []
        querySnapshot.forEach(doc => {
          let job = doc.data()
          cArray.push(job)
        })
        commit('setSubClients', cArray)
      })
    },
    clearSubClients({ commit }) {
      commit('setSubClients', [])
    },


    /*MARKETS*/
    addLead({ commit }, payload) {
      fb.marketingLeadsCollection.add(payload)
      .then(
        doc => {
          console.log(doc.id)
          fb.marketingLeadsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    getMarketetingLeads({ commit }) {
      console.log('getMarketetingLeads')
      fb.marketingLeadsCollection.orderBy('created', 'desc').limit(1000)

      // .get()
      //   .then(snapshot => {
      //     let leadsArray = []
      //     let archiveArray = []
      //     snapshot.forEach(doc => {

      .onSnapshot(querySnapshot => {

        let leadsArray = []
        let archiveArray = []
        querySnapshot.forEach(function (doc) {
          let lead = doc.data()
          if (doc.data().hidden) {
            archiveArray.push(lead)
          } else {
            leadsArray.push(lead)
          }

        })
        commit('setMarketingLeads', leadsArray)
        commit('setArchivedLeads', archiveArray)
        store.dispatch('getMyLeads')
      })
    },

    getJobsState({ commit }) {
      fb.skillsCollection
      .get()
      .then(snapshot => {
        let jobsArray = []
        snapshot.forEach(doc => {
          let job = doc.data()
          jobsArray.push(job)
        })
        commit('setJobs', jobsArray)
      })
    },

    getMyLeads({ commit, state }) {

      console.log('getting my leads')
      fb.marketsCollection
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let leadsArray = []
        querySnapshot.forEach(doc => {
            let mgrs = doc.data().mgrs
            if (mgrs && mgrs.length > 0 && (mgrs.some(e => e.userId === state.currentUser.uid))) {
              fb.marketingLeadsCollection.where("marketId", "==", doc.id).orderBy('created', 'desc')
              // .get()
              //   .then(snapshot => {
              //     snapshot.forEach(doc => {
              .onSnapshot(querySnapshot => {
                querySnapshot.forEach(function (doc) {
                let lead = doc.data()
                if (doc.data().hidden) {
                } else {
                  leadsArray.push(lead)
                }
              })
              
            })
          }
        })
          commit('setMyLeads', leadsArray)
      })
    },

    clearMarketingLeads({ commit }) {
      console.log('clearing leads')
      commit('setMarketingLeads', [])
      commit('setArchivedLeads', [])
      commit('setMyLeads', [])
    },
    addMarket({ commit }, payload) {
      fb.marketsCollection.add(payload)
      .then(
        doc => {
          fb.marketsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    getMarketsState({ commit }, payload) {
      console.log('getMarketsState')
      fb.marketsCollection.orderBy('city', 'asc')
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let marketsArray = []
        querySnapshot.forEach(function (doc) {
          let market = doc.data()
          marketsArray.push(market)
        })
        commit('setMarkets', marketsArray)
      })
    },
    clearMarketsState({ commit }) {
      commit('setMarkets', [])
    },

    /*TAGS*/
    getTagsState({ commit }, payload) {
      console.log('getTagsState')
      fb.tagsCollection
      .get()
        .then(snapshot => {
          let tagsArray = []
          snapshot.forEach(doc => {

      // .onSnapshot(querySnapshot => {
      //   let tagsArray = []
      //   querySnapshot.forEach(doc => {
          let tag = doc.data()
          tagsArray.push(tag)
        })
        commit('setTags', tagsArray)
      })
    },
    addTag({ commit }, payload) {
      console.log(payload)
      fb.tagsCollection.add(payload)
      .then(
        doc => {
          fb.tagsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    getTagFromId({ commit }, payload) {
      fb.tagsCollection.where("id", "==", payload).onSnapshot(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          commit("setTagInfo", doc.data())
        })
      })
    },
    deleteTag({ commit }, payload) {
      fb.tagsCollection.doc(payload).delete()
    },
    updateTag({ commit }, payload) {
      fb.tagsCollection.doc(payload.id).update(payload)
    },
    clearTagState({ commit }) {
      commit('setTagInfo', {})
    },
    clearTagsState({ commit }) {
      commit('setTags', [])
    },




    /*MGRS*/
    getMgrsState({ commit }, payload) {
      fb.mgrsCollection.orderBy('name', 'asc')
      // .onSnapshot(querySnapshot => {
        .get().then(querySnapshot => {
        let mgrsArray = []
        querySnapshot.forEach(doc => {
          let mgr = doc.data()
          mgrsArray.push(mgr)
        })
        commit('setMgrs', mgrsArray)
      })
    },
    addMgr({ commit }, payload) {
      console.log(payload)
      fb.mgrsCollection.add(payload)
      .then(
        doc => {
          fb.mgrsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    getMgrFromId({ commit }, payload) {
      fb.mgrsCollection.where("id", "==", payload).onSnapshot(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          commit("setMgrInfo", doc.data())
        })
      })
    },
    deleteMgr({ commit }, payload) {
      fb.mgrsCollection.doc(payload).delete()
    },
    updateMgr({ commit }, payload) {
      fb.mgrsCollection.doc(payload.id).update(payload)
    },
    clearMgrState({ commit }) {
      commit('setMgrInfo', {})
    },
    clearMgrsState({ commit }) {
      commit('setMgrs', [])
    },



    /*JOBS*/
    updateEventJobs2({ commit }) {
      console.log('updateEventJobs2')
      fb.eventsCollection
      .onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(doc.id)
          let venueMgrs = doc.data().venue.mgrs || null
          let venueClient = doc.data().venue.client || null
          doc.ref.update({
            mgrs: venueMgrs,
            client: venueClient
          })
        })
      })
    },

    addJob({ commit }, payload) {
      console.log('addJob')
      fb.jobsCollection.add(payload)
      .then(
        doc => {
          fb.jobsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    getJobsState({ commit }) {
      console.log('getJobsState')
      fb.skillsCollection
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let jobsArray = []
        querySnapshot.forEach(doc => {
          let job = doc.data()
          jobsArray.push(job)
        })
        commit('setJobs', jobsArray)
      })
    },
    getJobFromId({ commit }, payload) {
      console.log('getJobFromId')
      fb.jobsCollection.doc(payload)
      .get().then(doc => {
        commit("setJobInfo", doc.data())
      })

      // .onSnapshot(querySnapshot => {
      //   querySnapshot.forEach(function (doc) {
      //     commit("setJobInfo", doc.data())
      //   })
      // })
      // fb.jobsCollection.where("id", "==", payload).onSnapshot(querySnapshot => {
      //   querySnapshot.forEach(function (doc) {
      //     commit("setJobInfo", doc.data())
      //   })
      // })
    },
    deleteJob({ commit }, payload) {
      fb.jobsCollection.doc(payload).delete()
    },
    updateJob({ commit }, payload) {
      console.log('updateJob')
      fb.jobsCollection.doc(payload.id).update(payload)
    },
    clearJobState({ commit }) {
      commit('setJobInfo', {})
    },
    clearJobsState({ commit }) {
      commit('setJobs', [])
    },


    /*PAGE TEXT*/
    getPageText({ commit }) {
      fb.pageTextCollection.doc("XQLfFD57iwjlpMjMlyVw").get()
      .then(
        doc => {
          let pageText = doc.data()
          commit('setPageText', pageText)
        }
      )
    },
    updatePageText({ commit }, payload) {
      fb.pageTextCollection.doc("XQLfFD57iwjlpMjMlyVw").update(payload)
    },


    /*FAQS*/
    addFaq({ commit }, payload) {
      fb.faqsCollection.add(payload)
      .then(
        doc => {
          fb.faqsCollection.doc(doc.id).update({
            id: doc.id,
            order: 0,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    getFaqsState({ commit }) {
      fb.faqsCollection.orderBy('order', 'asc')
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let faqsArray = []
        querySnapshot.forEach(doc => {
          let faq = doc.data()
          faqsArray.push(faq)
        })
        commit('setFaqs', faqsArray)
      })
    },
    getFaqFromId({ commit }, payload) {
      fb.faqsCollection.where("id", "==", payload).onSnapshot(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          commit("setFaqInfo", doc.data())
        })
      })
    },
    deleteFaq({ commit }, payload) {
      fb.faqsCollection.doc(payload).delete()
    },
    updateFaq({ commit }, payload) {
      fb.faqsCollection.doc(payload.id).update(payload)
    },
    clearFaqState({ commit }) {
      commit('setFaqInfo', {})
    },
    clearFaqsState({ commit }) {
      commit('setFaqs', [])
    },


    /*CONTACTS*/
    addContact({ commit }, payload) {
      fb.contactsCollection.add(payload)
      .then(
        doc => {
          fb.contactsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    getContacts({ commit }) {
      fb.contactsCollection
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let contactsArray = []
        querySnapshot.forEach(doc => {
          let contact = doc.data()
          contactsArray.push(contact)
        })
        commit('setContacts', contactsArray)
      })
    },
    getContactFromId({ commit }, payload) {
      fb.contactsCollection.where("id", "==", payload).onSnapshot(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          commit("setContactInfo", doc.data())
        })
      })
      store.dispatch('getContactNotes', payload)
    },
    deleteContact({ commit }, payload) {
      fb.contactsCollection.doc(payload).delete()
    },
    updateContact({ commit }, payload) {
      fb.contactsCollection.doc(payload.id).update(payload)
    },
    clearContactState({ commit }) {
      commit('setContactInfo', {})
      commit('setContactNotes', [])
    },
    clearContactsState({ commit }) {
      commit('setContacts', [])
    },



    /*Verifications*/

    getVerifications({ commit }) {
      console.log('getting verifications')
      fb.verificationsCollection.orderBy('created', 'desc')
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let verificationsArray = []
        querySnapshot.forEach(doc => {
          let verification = doc.data()
          if (!doc.data().rejected &&  !doc.data().verified) {
            verificationsArray.push(verification)
          }
          // verificationsArray.push(verification)
        })
        commit('setVerifications', verificationsArray)
      })
    },
    getUserVerifications({ commit }, payload) {
      fb.verificationsCollection.where("userId", "==", payload).orderBy('created', 'desc')
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let verificationsArray = []
        querySnapshot.forEach(doc => {
          let verification = doc.data()
          verificationsArray.push(verification)
        })
        commit('setUserVerifications', verificationsArray)
      })
    },
    clearVerifications({ commit }) {
      commit('setVerifications', [])
    },





    updateAllShiftEvents({ commit }, payload) {
      fb.eventsCollection.doc(payload).get()
      .then(doc => {
        const eStatus = doc.data().status
        const eSlug = doc.data().slug
        const eTitle = doc.data().title
        fb.shiftsCollection.where("eventId", "==", payload).onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            console.log(eStatus)
            console.log(doc.id)
            fb.shiftsCollection.doc(doc.id).update({
              eventStatus: eStatus,
              eventSlug: eSlug,
              eventTitle: eTitle
            })

          })
        })
      })
    },


    




    /*CLIENTS*/
    addClient({ commit }, payload) {
      fb.clientsCollection.add(payload)
      .then(
        doc => {
          fb.clientsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    getClients({ commit }) {
      // Use try-catch for error handling
      try {
        const clientsCollection = fb.clientsCollection;

        clientsCollection.get()
          .then(querySnapshot => {
            const clientsArray = [];

            querySnapshot.forEach(doc => {
              const client = doc.data();
              clientsArray.push(client);
            });

            commit('setClients', clientsArray);
          });
      } catch (error) {
        // Handle any errors
        console.error("Error fetching clients:", error);
      }
    },
    clearClients({ commit }) {
      commit('setClients', [])
    },
    getClientFromId({ commit }, payload) {
      fb.clientsCollection.where("id", "==", payload).onSnapshot(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          commit("setClientInfo", doc.data())
        })
      })
      store.dispatch('getClientNotes', payload)
      store.dispatch('getClientContactsFromId', payload)
      store.dispatch('getClientVenues', payload)
    },
    getClientContactsFromId({ commit }, payload) {
      // Use try-catch for error handling
      try {
        const contactsCollection = fb.contactsCollection
          .where("company.id", "==", payload);

        const unsubscribe = contactsCollection.onSnapshot(querySnapshot => {
          const contactsArray = [];

          querySnapshot.forEach(doc => {
            const contact = doc.data();
            contactsArray.push(contact);
          });

          commit('setClientContacts', contactsArray);
        });

        // Optionally, return the unsubscribe function if needed to stop listening to updates.
        // return unsubscribe;
      } catch (error) {
        // Handle any errors
        console.error("Error fetching client contacts:", error);
      }
    },

    getClientContacts({ commit }, payload) {
      if (payload.client && payload.client[0] && payload.client[0].id) {
        // Use try-catch for error handling
        try {
          const clientId = payload.client[0].id;
          const contactsCollection = fb.contactsCollection
            .where("company.id", "==", clientId);

          const unsubscribe = contactsCollection.onSnapshot(querySnapshot => {
            const contactsArray = [];

            querySnapshot.forEach(doc => {
              const contact = doc.data();
              contactsArray.push(contact);
            });

            commit('setClientContacts', contactsArray);
          });

          // Optionally, return the unsubscribe function if needed to stop listening to updates.
          // return unsubscribe;
        } catch (error) {
          // Handle any errors
          console.error("Error fetching client contacts:", error);
        }
      }
    },
    clearClientContacts({ commit }) {
      commit('setClientContacts', [])
    },
    getClientVenues({ commit }, payload) {
      // Use try-catch for error handling
      try {
        const venuesCollection = fb.venuesCollection;

        venuesCollection.get()
          .then(querySnapshot => {
            const venuesArray = [];

            querySnapshot.forEach(doc => {
              const venue = doc.data();
              venuesArray.push(venue);
            });

            // Use Array.filter to filter venues that match the specified client ID
            const filteredVenues = venuesArray.filter(venue => {
              if (venue.client && venue.client.length >= 1) {
                return venue.client.some(client => client.id === payload);
              }
              return false;
            });

            commit('setClientVenues', filteredVenues);
          });
      } catch (error) {
        // Handle any errors
        console.error("Error fetching client venues:", error);
      }
    },
    addClientFile({ commit }, payload) {
      fb.clientsCollection.doc(payload.client.id).update({
        files: firebase.firestore.FieldValue.arrayUnion(payload)
      })
    },
    deleteClient({ commit }, payload) {
      fb.clientsCollection.doc(payload).delete()
    },
    updateClient({ commit }, payload) {
      fb.clientsCollection.doc(payload.id).update(payload)
    },
    clearClientState({ commit }) {
      commit('setClientInfo', {})
      commit('setClientNotes', [])
      commit('setClientVenues', [])
      commit('setClientContacts', [])
    },
    clearClientsState({ commit }) {
      commit('setClients', [])
    },




    /*EVENTS*/
    // addEvent({ commit }, payload) {
    //   console.log(payload)
    //   fb.eventsCollection.add(payload)
    //   .then(
    //     doc => {
    //       let geo = new fb.firestore.GeoPoint(payload.venue.center.lat, payload.venue.center.lng)
    //       fb.eventsCollection.doc(doc.id).update({
    //         id: doc.id,
    //         created: fb.firestore.FieldValue.serverTimestamp(),
    //         venueId: payload.venue.id,
    //         jobs: payload.jobs || [],
    //         days: payload.days || [],
    //         event_city: payload.venue.address.city,
    //         event_location: geo,
    //         event_state: payload.venue.address.state,
    //         venue_title: payload.venue.title,
    //         event_zip: payload.venue.address.zip,
    //         endDate: payload.endDate || payload.startDate
    //       })
    //     }
    //   )
    //   // store.dispatch('alertFollowers', payload)
    // },
    getEventsTotal({ commit }) {
      console.log('getEventsTotal')
      fb.eventsCollection.get().then(function(querySnapshot) {      
        console.log(querySnapshot.size);
        commit('setEventsTotal', querySnapshot.size)
        commit('setEventsTotalNext', (querySnapshot.size + 2400))
      });

    },
    clearEventsTotal({ commit }) {
      commit('setEventsTotal', 0)
      commit('setEventsTotalNext', 0)
    },
    // updateEventJobNos({ commit }) {
    //   fb.eventsCollection.orderBy('created', 'asc')
    //   .get().then((querySnapshot) => {
    //     let eventsArray = []
    //     querySnapshot.forEach((doc) => {
    //       let event = doc.data()
    //       eventsArray.push(event)
    //     })
    //     eventsArray.forEach((item, index) => {
    //       fb.eventsCollection.doc(item.id).update({
    //         jobNum: index + 2000
    //       })
    //     })
    //   })
    // },
    // updateEventJobNos({ commit }) {
    //   fb.eventsCollection.orderBy('created', 'asc')
    //   .get().then((querySnapshot) => {
    //     let eventsArray = []
    //     querySnapshot.forEach((doc) => {
    //       let event = doc.data()
    //       if (doc.data().id) {
    //         eventsArray.push(event)
    //       }
    //     })
    
    //   })
    // },
    addJobNos({ commit, state }) {
      console.log(state.allEvents)
      // state.allEvents.forEach((item, index) => {
          
      //     if (item && item.id) {
      //       fb.eventsCollection.doc(item.id).update({
      //         jobNum: index
      //       })
      //     } else {}
      //   })
    },
    getEvents({ commit }) {
      console.log('getEvents')
      fb.eventsCollection.orderBy('startDate', 'desc').limit(200)
      .get().then((querySnapshot) => {
        let eventsArray = []
        querySnapshot.forEach((doc) => {

          let event = doc.data()
          
            eventsArray.push(event)
        })
        commit('setEvents', eventsArray)
      })
      // store.dispatch('getEventsTotal')
    },
    clearEvents({ commit }) {
      commit('setEvents', [])
    },

    updateEventVenue({ commit }, payload) {
      console.log('updateEventVenue')
      fb.eventsCollection.doc(payload.id).update(payload)
    },
    updateEvent({ commit }, payload) {
      console.log('updateEvent')
      let geo = new fb.firestore.GeoPoint(payload.venue.center.lat, payload.venue.center.lng)
      payload.event_location = geo
      fb.eventsCollection.doc(payload.id).update(payload)
    },
    updateEventJobs({ commit }, payload) {
      console.log('updateEventJobs')
      console.log(payload)
      fb.eventsCollection.doc(payload.eventId).update({
          venue: payload.venue
      })
    },
    updateEventStaff({ commit }, payload) {
      console.log('updateEventStaff')
      fb.userDaysCollection.where("event", "==", payload.id)
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {

      // .onSnapshot(querySnapshot => {
      //   querySnapshot.forEach(doc => {
          let message = {
            phone: doc.data().phone || null,
            name: doc.data().fullName,
            userId: doc.data().userId,
            // email: doc.data().email || null,
            message: payload.updateMessage,
            event: payload
          }
        })
        fb.smsUpdatesCollection.add(message)
      })
      fb.eventsCollection.doc(payload.id).update({
        updateStaffSent: fb.firestore.FieldValue.serverTimestamp()
      })
    },
    updateShiftStaff({ commit }, payload) {
      console.log('updateShiftStaff')
     payload.staff.forEach(user => {
        if (user && user.id && payload.shift && payload.message) {
          let message = {
            shift: payload.shift,
            user: user,
            userId: user.id,
            message: payload.message,
          }

          return fb.shiftUpdatesCollection.add(message)
        } else {
   
        }
      })
    },


    updateGroupStaff({ commit }, payload) {
      console.log('updateGroupStaff')
      var newGroupUpdate = firebase.functions().httpsCallable('newGroupUpdate');
      newGroupUpdate(payload)

      // payload.groupUsers.forEach(user => {
      //   let message = {
      //     groupId: payload.groupId,
      //     user: user,
      //     message: payload.message,
      //     from: payload.from
      //   }
      fb.groupUpdatesCollection.add(payload.message)
      .then(
        doc => {
          fb.groupUpdatesCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    deleteEvent({ commit }, payload) {
      fb.eventsCollection.doc(payload).delete()
    },
    clearEventsByVenue({ commit }) {
      commit('setEventsByVenue', [])
    },
    clearEventsByMonth({ commit }) {
      commit('setEventsByMonth', [])
    },
    

    addEventNote({ commit, state }, payload) {
      fb.eventNotesCollection.add(payload)
      .then(
        doc => {
          fb.eventNotesCollection.doc(doc.id).update({
            eventId: payload.eventId,
            submittedBy: payload.submittedBy,
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp()
          })
        }
      )
    },
    getEventNotes({ commit }, payload) {
  console.log("getEventNotes");

  return new Promise((resolve, reject) => {
    fb.eventNotesCollection
      .where("eventId", "==", payload)
      .orderBy('created', 'desc')
      .get()
      .then(querySnapshot => {
        const eventNotesArray = querySnapshot.docs.map(doc => {
          const note = doc.data();
          note.id = doc.id;
          return note;
        });

        commit('setEventNotes', eventNotesArray);
        resolve(); // Resolve the promise
      })
      .catch(error => {
        console.error('Error fetching event notes:', error);
        reject(error); // Reject the promise if an error occurs
      });
  });
},
    clearEventNotes({ commit }) {
      commit('setEventNotes', [])
    },
    // getEventFromIdOnly({ commit }, payload) {
    //   console.log('getEventFromIdOnly')
    //   fb.eventsCollection.doc(payload).get()
    //   .then(
    //     doc => {
    //       commit("setEventInfo", doc.data())
    //     }
    //   )
    //   store.dispatch('getEventAssignments', doc.data().id)
    // },  


    // dropUser({ commit }, payload) {
    //   console.log(payload)
    //   fb.userDaysCollection.doc(payload).delete()
    // },


    getVenueEventsSearchResults({ commit }, payload) {
      console.log('getVenueEventsSearchResults')
      fb.eventsCollection.where("venueId", "==", payload).orderBy('startDate', 'asc')
      .onSnapshot(querySnapshot => {
       let yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1);
        let venueEventsArray = []
        querySnapshot.forEach(doc => {


      // .onSnapshot(querySnapshot => {
      //   let yesterday = new Date()
      //   yesterday.setDate(yesterday.getDate() - 1);
      //   let venueEventsArray = []
      //   querySnapshot.forEach((doc) => {
          let startComp = new Date(doc.data().startDate)
          let endComp = new Date(doc.data().endDate)
          let event = doc.data()
          venueEventsArray.push(event)
          // if ((endComp >= yesterday || startComp >= yesterday)) {
          //   venueEventsArray.push(event)
          // }
        })
        commit('setVenueEventsSearchResults', venueEventsArray)
      })
    },

    clearVenueEventsSearchResults({ commit }) {
      commit('setVenueEventsSearchResults', [])
    },
    clearTaggedEvents({ commit }) {
      commit('setTaggedEvents', [])
    },
    clearPaidEventAssignments({ commit }) {
      commit('setPaidEventAssignments', [])
    },
    clearEventAssignmentsByUser({ commit }) {
      commit('setEventAssignmentsByUser', [])
    },
    clearEventState({ commit }) {
      console.log('clearEventState')
      commit('setEventInfo', {})
      commit('setEventUsersRef', [])
      commit('setEventShiftsDays', [])
      commit('setEventAssignmentsByDay', [])
      commit('setEventAssignmentsByUser', [])
      commit('setEventAssignments', [])
      commit('setEventGroupUsers', [])
      commit('setEventUsers', [])
      commit('setEventShifts', null)
      commit('setEventDrops', [])
      commit('setPaidEventAssignments', [])
      commit('setEventShiftsDays', []);
    },
    clear2021EventsState({ commit }) {
      commit('set2021Events', [])
    },
    clear2022EventsState({ commit }) {
      commit('set2022Events', [])
    },
    clearEventsState({ commit }) {
      commit('setEvents', [])
      commit('setAllEvents', [])
      commit('setTaggedEvents', [])
      commit('setInfiniteEvents', [])
      commit('setNextInfiniteEvents', [])
      commit('setCancelledEvents', [])
    },


    /*WEEKS*/
    // getWeeks({ commit }) {
    //   fb.weeksCollection.orderBy('startDate', 'desc').limit(150)
    //   .get().then((querySnapshot) => {
    //     let eventsArray = []
    //     querySnapshot.forEach((doc) => {

    //       let event = doc.data()
          
    //         eventsArray.push(event)
    //     })
    //     commit('setWeeks', eventsArray)
    //   })
    // },
    // clearEvents({ commit }) {
    //   commit('setWeeks', [])
    // },


    /*CODE*/
    getCode({ commit }) {
      console.log('getting code')
      fb.checkInMasterCollection.doc('q6fwrK6k4RxLSw3NQN6y')
      .onSnapshot((doc) => {
        let checkInMaster = doc.data().checkInMaster
        commit('setCheckInMaster', checkInMaster)
      })
    },


    removeOldUsers() {
      var deleteAuthUser = firebase.functions().httpsCallable('deleteAuthUser');
      // deleteAuthUser({ 
      //   uid: "r6DwEoLYs3ct5SU36aNyjbMGlfD2",
      // })
      deleteAuthUser()
      .then((result) => {
        console.log(result)
      })
    },


    /*PIPELINE*/

  //   async getMyPipeline({ commit, state }) {
  //   try {
  //     const userDocRef = fb.usersCollection.doc(state.currentUser.uid);

  //     // Subscribe to changes in the user's document
  //     const unsubscribe = userDocRef.onSnapshot(async (userDoc) => {
  //       if (userDoc.exists) {
  //         const userData = userDoc.data();
  //         const orderWatchList = userData.orderWatchList || [];

  //         if (orderWatchList.length > 0) {
  //           const pipelinePromises = orderWatchList.map(async (order) => {
  //             const eventDoc = await fb.eventsCollection.doc(order).get();
  //             if (eventDoc.exists) {
  //               const pipeline = eventDoc.data().pipeline || [];
  //               const userPromises = pipeline.map(async (pipe) => {
  //                 if (pipe) { // Check if pipe is defined
  //                   console.log(pipe);
  //                   const userDoc = await fb.usersCollection.doc(pipe).get();
  //                   if (userDoc.exists && userDoc.data() && userDoc.data().id) {
  //                     return userDoc.data();
  //                   }
  //                 }
  //               });
  //               return Promise.all(userPromises);
  //             }
  //             return [];
  //           });

  //           const pipelineList = await Promise.all(pipelinePromises);
  //           console.log(pipelineList);
  //           const flattenedList = pipelineList.flat();
  //           console.log(flattenedList);

  //           commit('setMyPipeline', flattenedList);
  //         } else {
  //           commit('setMyPipeline', []);
  //         }
  //       } else {
  //         // Handle the case where the user document doesn't exist
  //       }
  //     });

  //     // Store the unsubscribe function for later use (e.g., when the component is destroyed)
  //     // commit('setMyPipelineUnsubscribe', unsubscribe);
  //   } catch (error) {
  //     // Handle any errors that may occur during the process
  //     console.error(error);
  //   }
  // },

    getStaticEventUsers({ commit }, payload) {
      console.log('getStaticEventUsers')
      fb.userDaysCollection.where("preferredEvent", "==", payload).orderBy('created', 'asc')
      .get()
        .then(snapshot => {
          let eventUsersArray = []
          snapshot.forEach(doc => {
            let user = doc.data()
            eventUsersArray.push(user)
          })
        commit('setStaticEventUsers', eventUsersArray)
      })
    },
//     async getMyOnboarding({ commit, state }) {
//   try {
//     const userDocRef = fb.usersCollection.doc(state.currentUser.uid);

//     // Get the user document data
//     const userDoc = await userDocRef.get();

//     if (userDoc.exists) {
//       const userData = userDoc.data();
//       const orderWatchList = userData.orderWatchList || [];
//       console.log('orderWatchList:', orderWatchList);
//       const usersArray = [];

//       // Define batch size for pagination
//       const batchSize = 100; // Adjust the batch size as needed

//       // Define a function to fetch user data
//       const fetchUserData = async (userId) => {
//       try {
//         const userDoc = await fb.usersCollection.doc(userId).get();
//         if (userDoc.exists) {
//           const userData = userDoc.data();
//           if (userData.employmentStatus === "Onboarding") {
//             console.log('Fetched user:', userData.id);
//             return userData;
//           }
//         }
//         console.log('User does not meet criteria:', userId);
//         return null;
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//         return null;
//       }
//     };


//       // Use Promise.all to fetch user data concurrently
//       await Promise.all(
//         orderWatchList.map(async (order) => {
//           console.log('Processing order:', order); // Log the order being processed
//           let lastDoc = null;
//           const usersPromises = [];

//           while (true) {
//             // Create a query with pagination
//             let query = fb.assignmentsCollection
//               .where('eventId', '==', order)
//               .limit(batchSize);

//             if (lastDoc) {
//               query = query.startAfter(lastDoc);
//             }

//             const querySnapshot = await query.get();

//             querySnapshot.forEach((doc) => {
//               const userId = doc.data().userId;
//               console.log('Processing user:', userId); // Log the user being processed

//               usersPromises.push(fetchUserData(userId));
//             });

//             // If there are no more documents, break the loop
//             if (querySnapshot.size < batchSize) {
//               break;
//             }

//             // Set the last document for the next iteration
//             lastDoc = querySnapshot.docs[querySnapshot.size - 1];
//           }

//           // Wait for all user data fetch promises to complete for this order
//           const userDataArray = await Promise.all(usersPromises);

//           // Filter out null values (users that don't exist or don't meet the criteria)
//           const filteredUserDataArray = userDataArray.filter((userData) => userData !== null);

//           // Add the filtered user data to the usersArray
//           usersArray.push(...filteredUserDataArray);
//         })
//       );

//       commit('setMyOnboarding', usersArray);
//     } else {
//       // Handle the case where the user document doesn't exist
//     }
//   } catch (error) {
//     // Handle any errors that may occur during the process
//     console.error('Error in getMyOnboarding:', error);
//   }
// },
    async getMyOnboarding({ commit, state }) {
  try {
    const userDocRef = fb.usersCollection.doc(state.currentUser.uid);

    // Get the user document data
    const userDoc = await userDocRef.get();

    if (userDoc.exists) {
      const userData = userDoc.data();
      const orderWatchList = userData.orderWatchList || [];
      console.log('orderWatchList:', orderWatchList);
      const uniqueUserIds = new Set(); // Use a Set to store unique user IDs

      // Define batch size for pagination
      const batchSize = 100; // Adjust the batch size as needed

      // Define a function to fetch user data
      const fetchUserData = async (userId) => {
        try {
          const userDoc = await fb.usersCollection.doc(userId).get();
          if (userDoc.exists) {
            const userData = userDoc.data();
            if (userData.employmentStatus === "Onboarding") {
              console.log('Fetched user:', userData.id);
              return userData;
            }
          }
          console.log('User does not meet criteria:', userId);
          return null;
        } catch (error) {
          console.error('Error fetching user data:', error);
          return null;
        }
      };

      const requests = [];

      orderWatchList.forEach((order) => {
        requests.push(async () => {
          console.log('Processing order:', order); // Log the order being processed
          let lastDoc = null;
          const usersPromises = [];

          while (true) {
            // Create a query with pagination
            let query = fb.assignmentsCollection
              .where('eventId', '==', order)
              .limit(batchSize);

            if (lastDoc) {
              query = query.startAfter(lastDoc);
            }

            const querySnapshot = await query.get();

            querySnapshot.forEach((doc) => {
              const userId = doc.data().userId;
              console.log('Processing user:', userId); // Log the user being processed

              if (!uniqueUserIds.has(userId)) {
                uniqueUserIds.add(userId); // Add the unique user ID to the Set
                usersPromises.push(fetchUserData(userId));
              }
            });

            // If there are no more documents, break the loop
            if (querySnapshot.size < batchSize) {
              break;
            }

            // Set the last document for the next iteration
            lastDoc = querySnapshot.docs[querySnapshot.size - 1];
          }

          // Wait for all user data fetch promises to complete for this order
          const userDataArray = await Promise.all(usersPromises);

          // Filter out null values (users that don't exist or don't meet the criteria)
          const filteredUserDataArray = userDataArray.filter((userData) => userData !== null);

          return filteredUserDataArray; // Return the filtered user data for this order
        });
      });

      const results = await throttleRequests(requests, 5); // Adjust the concurrency as needed

      // Concatenate the filtered user data from all orders
      const usersArray = results.reduce((accumulator, currentOrderData) => {
        accumulator.push(...currentOrderData);
        return accumulator;
      }, []);

      commit('setMyOnboarding', usersArray);
    } else {
      // Handle the case where the user document doesn't exist
    }
  } catch (error) {
    // Handle any errors that may occur during the process
    console.error('Error in getMyOnboarding:', error);
  }
},


async getMyActive({ commit, state }) {
  try {
    const userDocRef = fb.usersCollection.doc(state.currentUser.uid);

    // Get the user document data
    const userDoc = await userDocRef.get();

    if (userDoc.exists) {
      const userData = userDoc.data();
      const orderWatchList = userData.orderWatchList || [];
      const uniqueUserIds = new Set(); // Use a Set to store unique user IDs

      // Define batch size for pagination
      const batchSize = 100; // Adjust the batch size as needed

      // Define a function to fetch user data
      const fetchUserData = async (userId) => {
        try {
          const userDoc = await fb.usersCollection.doc(userId).get();
          if (userDoc.exists) {
            const userData = userDoc.data();
            if (userData.employmentStatus === "Active") {
              return userData;
            }
          }
          console.log('User does not meet criteria:', userId);
          return null;
        } catch (error) {
          console.error('Error fetching user data:', error);
          return null;
        }
      };

      const requests = [];

      orderWatchList.forEach((order) => {
        requests.push(async () => {
          console.log('Processing order:', order); // Log the order being processed
          let lastDoc = null;
          const usersPromises = [];

          while (true) {
            // Create a query with pagination
            let query = fb.assignmentsCollection
              .where('eventId', '==', order)
              .limit(batchSize);

            if (lastDoc) {
              query = query.startAfter(lastDoc);
            }

            const querySnapshot = await query.get();

            querySnapshot.forEach((doc) => {
              const userId = doc.data().userId;
              
              if (!uniqueUserIds.has(userId)) {
                uniqueUserIds.add(userId); // Add the unique user ID to the Set
                usersPromises.push(fetchUserData(userId));
              }
            });

            // If there are no more documents, break the loop
            if (querySnapshot.size < batchSize) {
              break;
            }

            // Set the last document for the next iteration
            lastDoc = querySnapshot.docs[querySnapshot.size - 1];
          }

          // Wait for all user data fetch promises to complete for this order
          const userDataArray = await Promise.all(usersPromises);

          // Filter out null values (users that don't exist or don't meet the criteria)
          const filteredUserDataArray = userDataArray.filter((userData) => userData !== null);

          return filteredUserDataArray; // Return the filtered user data for this order
        });
      });

      const results = await throttleRequests(requests, 5); // Adjust the concurrency as needed

      // Concatenate the filtered user data from all orders
      const usersArray = results.reduce((accumulator, currentOrderData) => {
        accumulator.push(...currentOrderData);
        return accumulator;
      }, []);

      commit('setMyActive', usersArray);
    } else {
      // Handle the case where the user document doesn't exist
    }
  } catch (error) {
    // Handle any errors that may occur during the process
    console.error('Error in getMyActive:', error);
  }
},







    // async getMyOnboarding({ commit, state }) {
    //   try {
    //     const userDocRef = fb.usersCollection.doc(state.currentUser.uid);

    //     // Get the user document data
    //     const userDoc = await userDocRef.get();

    //     if (userDoc.exists) {
    //       const userData = userDoc.data();
    //       const orderWatchList = userData.orderWatchList || [];
    //       console.log(orderWatchList);
    //       const usersArray = [];

    //       // Use Promise.all to wait for all queries to complete in parallel
    //       await Promise.all(
    //         orderWatchList.map(async (order) => {
    //           console.log(order);
    //           const assSnapshot = await fb.assignmentsCollection
    //             .where('eventId', '==', order)
    //             .get()

    //           const promises = [];

    //           assSnapshot.forEach((doc) => {
    //             console.log('doc.id:', doc.id); // Log the doc.id
    //             const userId = doc.data().userId;
    //             // Fetch user data using the userId and add it to the array
    //             const userPromise = fb.usersCollection.doc(userId)
    //               .get()
    //               .then((userDoc) => {
    //                 if (userDoc.exists) {
    //                   return userDoc.data();
    //                 }
    //                 return null; // Handle the case where the user document doesn't exist
    //               });

    //             promises.push(userPromise);
    //           });

    //           // Wait for all user data fetch promises to complete
    //           const userDataArray = await Promise.all(promises);

    //           // Filter out null values (users that don't exist)
    //           const filteredUserDataArray = userDataArray.filter((userData) => userData !== null);

    //           // Add the filtered user data to the usersArray
    //           usersArray.push(...filteredUserDataArray);
    //         })
    //       );

    //       commit('setMyOnboarding', usersArray);
    //     } else {
    //       // Handle the case where the user document doesn't exist
    //     }
    //   } catch (error) {
    //     // Handle any errors that may occur during the process
    //     console.error('Error in getMyOnboarding:', error);
    //   }
    // },


    clearMyOnboarding({ commit }) {
      commit('setMyOnboarding', []);
    },
    clearMyActive({ commit }) {
      commit('setMyActive', []);
    },


    
    async getMyPipeline({ commit, state }) {
    try {
      const userDocRef = fb.usersCollection.doc(state.currentUser.uid);

      // Subscribe to changes in the user's document
      const unsubscribe = userDocRef.onSnapshot(async (userDoc) => {
        if (userDoc.exists) {
          const userData = userDoc.data();
          const orderWatchList = userData.orderWatchList || [];

          if (orderWatchList.length > 0) {
            const pipelinePromises = orderWatchList.map(async (order) => {
              const eventDoc = await fb.eventsCollection.doc(order).get();
              if (eventDoc.exists) {
                const pipeline = eventDoc.data().pipeline || [];
                const userPromises = pipeline.map(async (pipe) => {
                  if (pipe) { // Check if pipe is defined
                    const userDoc = await fb.usersCollection.doc(pipe).get();
                    if (userDoc && userDoc.exists && userDoc.data() && userDoc.data().id) {
                      return userDoc.data();
                    } else {
                      // Handle the case where userDoc or its properties are missing
                      console.warn('UserDoc is missing or incomplete:', pipe);
                    }
                  }
                });
                return Promise.all(userPromises);
              }
              return [];
            });

            const pipelineList = await Promise.all(pipelinePromises);

            // Filter out undefined or incomplete objects
            const flattenedList = pipelineList.flat().filter(user => user !== undefined);

            commit('setMyPipeline', flattenedList);
          } else {
            commit('setMyPipeline', []);
          }
        } else {
          // Handle the case where the user document doesn't exist
        }
      });

      // Store the unsubscribe function for later use (e.g., when the component is destroyed)
      // commit('setMyPipelineUnsubscribe', unsubscribe);
    } catch (error) {
      // Handle any errors that may occur during the process
      console.error(error);
    }
  },

    

    clearMyPipeline({ commit }) {
      commit('setMyPipeline', [])
    },
    async getUserPipeline({ commit }, payload) {
      try {
        console.log("getUserPipeline");

        // Reference to the user's document
        const userDocRef = fb.usersCollection.doc(payload);

        // Subscribe to changes in the user's document
        const unsubscribe = userDocRef.onSnapshot(async (userDoc) => {
          if (userDoc.exists) {
            const userData = userDoc.data();

            if (userData.pipeline && userData.pipeline.length > 0) {
              const pipeline = userData.pipeline;
              const pipelinePromises = pipeline.map(async (pipe) => {
                const eventDoc = await fb.eventsCollection.doc(pipe).get();
                if (eventDoc.exists) {
                  return eventDoc.data();
                }
                return null;
              });

              const ordersArray = await Promise.all(pipelinePromises);
              const filteredOrders = ordersArray.filter(order => order !== null);

              commit('setUserPipeline', filteredOrders);
            } else {
              commit('setUserPipeline', []);
            }
          } else {
            commit('setUserPipeline', []);
          }
        });

        // Store the unsubscribe function for later use (e.g., when the component is destroyed)
        // commit('setUserPipelineUnsubscribe', unsubscribe);
      } catch (error) {
        // Handle any errors that may occur during the process
        console.error(error);
      }
    },

    // async getPipeline({ commit }, payload) {
    //   console.log("getPipeline");
    //   try {
    //     const eventDocRef = fb.eventsCollection.doc(payload);

    //     eventDocRef.onSnapshot(async (eventDoc) => {
    //       if (eventDoc.exists) {
    //         const pipeline = eventDoc.data().pipeline || [];
    //         const membersArray = await Promise.all(
    //           pipeline.map(async (pipe) => {
    //             const userDoc = await fb.usersCollection.doc(pipe).get();
    //             if (userDoc.exists) {
    //               return userDoc.data();
    //             }
    //             return null;
    //           })
    //         );

    //         commit('setPipeline', membersArray.filter((member) => member !== null));
    //       }
    //     });
    //   } catch (error) {
    //     console.error("Error fetching pipeline:", error);
    //     // Handle the error as needed, e.g., show a notification to the user
    //   }
    // },
    getPipeline({ commit }, payload) {
  console.log("getPipeline");
  try {
    const eventDocRef = fb.eventsCollection.doc(payload);

    eventDocRef.onSnapshot((eventDoc) => {
      if (eventDoc.exists) {
        const pipeline = eventDoc.data().pipeline || [];
        const promises = pipeline.map((pipe) => {
          return fb.usersCollection.doc(pipe).get()
            .then((userDoc) => {
              if (userDoc.exists) {
                return userDoc.data();
              }
              return null;
            });
        });

        Promise.all(promises)
          .then((membersArray) => {
            commit('setPipeline', membersArray.filter((member) => member !== null));
          })
          .catch((error) => {
            console.error("Error fetching pipeline users:", error);
            // Handle the error as needed, e.g., show a notification to the user
          });
      }
    });
  } catch (error) {
    console.error("Error fetching pipeline:", error);
    // Handle the error as needed, e.g., show a notification to the user
  }
},
getPipelineCancelled({ commit }, payload) {
  console.log("getPipelineCancelled");
  try {
    const eventDocRef = fb.eventsCollection.doc(payload);

    eventDocRef.onSnapshot((eventDoc) => {
      if (eventDoc.exists) {
        const pipeline = eventDoc.data().pipelineCancelled || [];
        const promises = pipeline.map((pipe) => {
          return fb.usersCollection.doc(pipe).get()
            .then((userDoc) => {
              if (userDoc.exists) {
                return userDoc.data();
              }
              return null;
            });
        });

        Promise.all(promises)
          .then((membersArray) => {
            commit('setPipelineCancelled', membersArray.filter((member) => member !== null));
          })
          .catch((error) => {
            console.error("Error fetching pipeline cancelled users:", error);
            // Handle the error as needed, e.g., show a notification to the user
          });
      }
    });
  } catch (error) {
    console.error("Error fetching pipeline cancelled:", error);
    // Handle the error as needed, e.g., show a notification to the user
  }
},
    // async getPipelineCancelled({ commit }, payload) {
    //   console.log("getPipelineCancelled");
    //   try {
    //     const eventDocRef = fb.eventsCollection.doc(payload);

    //     eventDocRef.onSnapshot(async (eventDoc) => {
    //       if (eventDoc.exists) {
    //         const pipeline = eventDoc.data().pipelineCancelled || [];
    //         const membersArray = await Promise.all(
    //           pipeline.map(async (pipe) => {
    //             const userDoc = await fb.usersCollection.doc(pipe).get();
    //             if (userDoc.exists) {
    //               return userDoc.data();
    //             }
    //             return null;
    //           })
    //         );

    //         commit('setPipelineCancelled', membersArray.filter((member) => member !== null));
    //       }
    //     });
    //   } catch (error) {
    //     console.error("Error fetching pipeline:", error);
    //     // Handle the error as needed, e.g., show a notification to the user
    //   }
    // },
    // async getPipelineHired({ commit }, payload) {
    //   console.log("getPipelineHired");
    //   try {
    //     const eventDocRef = fb.eventsCollection.doc(payload);

    //     eventDocRef.onSnapshot(async (eventDoc) => {
    //       if (eventDoc.exists) {
    //         const pipeline = eventDoc.data().pipelineHired || [];
    //         const membersArray = await Promise.all(
    //           pipeline.map(async (pipe) => {
    //             const userDoc = await fb.usersCollection.doc(pipe).get();
    //             if (userDoc.exists) {
    //               return userDoc.data();
    //             }
    //             return null;
    //           })
    //         );

    //         commit('setPipelineHired', membersArray.filter((member) => member !== null));
    //       }
    //     });
    //   } catch (error) {
    //     console.error("Error fetching pipeline:", error);
    //     // Handle the error as needed, e.g., show a notification to the user
    //   }
    // },
getPipelineHired({ commit }, payload) {
  console.log("getPipelineHired");
  try {
    const eventDocRef = fb.eventsCollection.doc(payload);

    eventDocRef.onSnapshot((eventDoc) => {
      if (eventDoc.exists) {
        const pipeline = eventDoc.data().pipelineHired || [];
        const promises = pipeline.map((pipe) => {
          return fb.usersCollection.doc(pipe).get()
            .then((userDoc) => {
              if (userDoc.exists) {
                return userDoc.data();
              }
              return null;
            });
        });

        Promise.all(promises)
          .then((membersArray) => {
            commit('setPipelineHired', membersArray.filter((member) => member !== null));
          })
          .catch((error) => {
            console.error("Error fetching pipeline hired users:", error);
            // Handle the error as needed, e.g., show a notification to the user
          });
      }
    });
  } catch (error) {
    console.error("Error fetching pipeline hired:", error);
    // Handle the error as needed, e.g., show a notification to the user
  }
},
    updatePipelineNote({ commit }, payload) {
      fb.usersCollection.doc(payload.id).update({
        pipelineNote: payload.pipelineNote
      })
    },

    
    async removeUserFromPipeline({ commit }, payload) {
      console.log('removeUserFromPipeline');
      console.log(payload)
      const userId = payload.userId;
      const eventId = payload.eventId;

      if (!userId || !eventId) {
        return;
      }

      try {
        await Promise.all([
          fb.usersCollection.doc(userId).update({
            pipeline: firebase.firestore.FieldValue.arrayRemove(eventId),
            pipelineOrder: null,
            pipelineShifts: null,
          }),
          fb.eventsCollection.doc(eventId).update({
            pipeline: firebase.firestore.FieldValue.arrayRemove(userId)
          })
        ]);
      } catch (error) {
        console.error(error);
      }

    },

    async addToPipeline({ commit }, payload) {
      console.log('addToPipeline');
      console.log(payload);

      const userId = payload.userId;
      const eventId = payload.eventId;

      if (!userId || !eventId) {
        return;
      }

      const updatePipelineDetails = firebase.functions().httpsCallable('updatePipelineDetails');
      const updatePipelineShifts = firebase.functions().httpsCallable('updatePipelineShifts');

        await Promise.all([
          updatePipelineDetails({ userId, eventId }),
          updatePipelineShifts({ userId, eventId })
        ]);

      try {
        await Promise.all([
          fb.usersCollection.doc(userId).update({
            pipeline: firebase.firestore.FieldValue.arrayUnion(eventId)
          }),
          fb.eventsCollection.doc(eventId).update({
            pipeline: firebase.firestore.FieldValue.arrayUnion(userId)
          })
        ]);
      } catch (error) {
        console.error(error);
      }
    },

    async addBackToPipeline({ commit }, payload) {
      console.log('addBackToPipeline');
      console.log(payload);

      const { userId, eventId } = payload;

      if (!userId || !eventId) {
        return;
      }

      try {
        const updatePipelineDetails = firebase.functions().httpsCallable('updatePipelineDetails');
        const updatePipelineShifts = firebase.functions().httpsCallable('updatePipelineShifts');

        await Promise.all([
          updatePipelineDetails({ userId, eventId }),
          updatePipelineShifts({ userId, eventId })
        ]);

        const eventUpdate = {
          pipeline: firebase.firestore.FieldValue.arrayUnion(userId),
          pipelineCancelled: firebase.firestore.FieldValue.arrayRemove(userId),
          pipelineHired: firebase.firestore.FieldValue.arrayRemove(userId)
        };

        // Perform other updates concurrently
        if (eventId) {
          await fb.usersCollection.doc(userId).update({
            pipeline: firebase.firestore.FieldValue.arrayUnion(eventId),
            cancellationReason: ''
          });
        }
        if (userId) {
          await fb.eventsCollection.doc(eventId).update(eventUpdate);
          await fb.userDaysCollection.where('userId', '==', userId)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
              console.log(doc.id);
              if (doc.data().preferredEvent && doc.data().preferredEvent == eventId) {
                fb.userDaysCollection.doc(doc.id).update({
                  dayStatus: 'available'
                })
              }
            })
          })
        }
      } catch (error) {
        console.error(error);
      }
    },

    async removeFromPipeline({ commit }, payload) {
      console.log('removeFromPipeline');
      console.log(payload);

      const userId = payload.userId;
      const eventId = payload.eventId;

      if (!userId || !eventId) {
        return;
      }

      try {
        if (eventId) {
          await fb.usersCollection.doc(userId).update({
            pipeline: firebase.firestore.FieldValue.arrayRemove(eventId),
            pipelineCancelled: firebase.firestore.FieldValue.arrayUnion(eventId)
          });
        }
        if (userId) {
          await fb.eventsCollection.doc(eventId).update({
            pipeline: firebase.firestore.FieldValue.arrayRemove(userId),
            pipelineCancelled: firebase.firestore.FieldValue.arrayUnion(userId)
          });
          await fb.userDaysCollection.where('userId', '==', userId)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
              console.log(doc.id);
              if (doc.data().preferredEvent && doc.data().preferredEvent == eventId) {
                fb.userDaysCollection.doc(doc.id).update({
                  dayStatus: 'not requested'
                })
              }
            })
          })
        }
        
      } catch (error) {
        console.error(error);
      }
    },
    async updateReason({ commit }, payload) {
      console.log('updateReason');
      console.log(payload);

      const userId = payload.id;

      if (!userId) {
        return;
      }

      try {
        if (userId) {
          await fb.usersCollection.doc(userId).update({
            cancellationReason: payload.cancellationReason
          });
        }
        
      } catch (error) {
        console.error(error);
      }
    },

    async hireFromPipeline({ commit }, payload) {
      console.log('hireFromPipeline');
      console.log(payload);

      const userId = payload.userId;
      const eventId = payload.eventId;

      if (!userId || !eventId) {
        return;
      }

      try {
        if (eventId) {
          await fb.usersCollection.doc(userId).update({
            pipeline: firebase.firestore.FieldValue.arrayRemove(eventId)
          });
        }
        if (userId) {
          await fb.eventsCollection.doc(eventId).update({
            pipeline: firebase.firestore.FieldValue.arrayRemove(userId),
            pipelineHired: firebase.firestore.FieldValue.arrayUnion(userId)
          });
          await fb.userDaysCollection.where('userId', '==', userId)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
              console.log(doc.id);
              if (doc.data().preferredEvent && doc.data().preferredEvent == eventId) {
                fb.userDaysCollection.doc(doc.id).update({
                  dayStatus: 'hired'
                })
              }
            })
          })
        }
      } catch (error) {
        console.error(error);
      }
    },
    clearPipeline({ commit }) {
      commit('setPipeline', [])
    },
    clearPipelineCancelled({ commit }) {
      commit('setPipelineCancelled', [])
    },
    clearPipelineHired({ commit }) {
      commit('setPipelineHired', [])
    },


    
    clearUserPipeline({ commit }) {
      commit('setUserPipeline', [])
    },

    clearPipelines({ commit }) {
      commit('setPipeline', [])
      commit('setPipelineCancelled', [])
      commit('setPipelineHired', [])
    },
    








    /*SHIFTS*/
    sendBulkShiftEmail({ commit }, payload) {
      console.log('sendBulkShiftEmail')
      var updateUserDaysToSendBulkShiftEmail = firebase.functions().httpsCallable('updateUserDaysToSendBulkShiftEmail');
      updateUserDaysToSendBulkShiftEmail({ 
        users: payload.users,
      })
    },
    sendShiftEmail({ commit }, payload) {
      console.log('sendShiftEmail')
      var updateUserDaysToSendShiftEmail = firebase.functions().httpsCallable('updateUserDaysToSendShiftEmail');
      updateUserDaysToSendShiftEmail({
        user: payload.userDay.id,
      })
    },

    addEventShift({ commit }, payload) {
      console.log('addEventShift')
      fb.shiftsCollection.add(payload)
      .then(
        doc => {
          fb.shiftsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
          })
        }
      )
    },
    deleteShift({ commit }, payload) {
      console.log('deleteShift')
      fb.shiftsCollection.doc(payload).delete()
      .then(() => {
        fb.assignmentsCollection.where("shiftId", "==", payload)
        .get().then(querySnapshot => {
        // .onSnapshot(querySnapshot => {
          querySnapshot.forEach(doc => {
            fb.assignmentsCollection.doc(doc.id).delete()
          })
        })
        .then(() => {
          fb.userDaysCollection.where("shift", "==", payload)
          .get().then(querySnapshot => {
          // .onSnapshot(querySnapshot => {
            querySnapshot.forEach(doc => {
              fb.userDaysCollectionCollection.doc(doc.id).update({
                shift: null,
                status: "available",
                dayStatus: firebase.firestore.FieldValue.delete()
              })
            })
          })
        })
      })
    },
    async muteNotifications({ commit }, payload) {
      try {
        await fb.eventsCollection.doc(payload).update({
          sendPlacementNotifications: false
        });
        // Optionally, commit a mutation if needed
        // commit('notificationsMuted', payload);
      } catch (error) {
        // Handle any errors
        console.error("Error muting notifications:", error);
      }
    },

    async unmuteNotifications({ commit }, payload) {
      try {
        await fb.eventsCollection.doc(payload).update({
          sendPlacementNotifications: true
        });
        // Optionally, commit a mutation if needed
        // commit('notificationsUnmuted', payload);
      } catch (error) {
        // Handle any errors
        console.error("Error unmuting notifications:", error);
      }
    },

    async updateEventShift({ commit }, payload) {
      console.log( payload)
      await fb.shiftsCollection.doc(payload.id).update(payload)
    },
    // async getEventShiftsDays({ commit }, payload) {
    //   console.log('getEventShiftsDays');

    //   try {
    //     const querySnapshot = await fb.shiftsCollection
    //       .where('eventId', '==', payload)
    //       .orderBy('day', 'desc')
    //       .get();

    //     const daysArray = querySnapshot.docs.map((doc) => doc.data().day);

    //     const uniqueDays = Array.from(new Set(daysArray));

    //     commit('setEventShiftsDays', uniqueDays);
    //   } catch (error) {
    //     console.error('Error fetching event shift days:', error);
    //     // Handle the error as needed
    //   }
    // },
    // async getEventShifts({ commit }, payload) {
    //   console.log(payload)
    //   console.log('getEventShifts');

    //   try {
    //     const querySnapshot = await fb.shiftsCollection
    //       .where('eventId', '==', payload)
    //       .orderBy('day', 'desc')
    //       .get();

    //     const shiftsArray = querySnapshot.docs.map((doc) => {
    //       const shift = doc.data();
    //       shift.id = doc.id;
    //       return shift;
    //     });

    //     commit('setEventShifts', shiftsArray);
    //   } catch (error) {
    //     console.error('Error fetching event shifts:', error);
    //     // Handle the error as needed
    //   }
    // },
    async getEventFromId({ commit }, payload) {
      console.log('getEventFromId');

      try {
        const doc = await fb.eventsCollection.doc(payload).get();

        if (doc.exists) {
          commit('setEventInfo', doc.data());
          
          store.dispatch('getClientContacts', doc.data());
          store.dispatch('getEventNotes', payload);
          store.dispatch("getEventShiftsData", payload)

          // if (doc && 
          //   doc.id &&
          //   (!doc.data().shiftType ||
          //     (doc.data().shiftType !== 'Permanent' &&
          //       doc.data().shiftType !== 'Job'))
          // ) {
            
          // } else {
          //   console.log(doc.id)
          //   await store.dispatch('getEventGroupUsers', doc.id)
          // }
        }
      } catch (error) {
        console.error('Error fetching event information:', error);
        // Handle the error as needed
      }
      
    },
    async getAssignmentDays({ commit }, payload) {
      console.log('getAssignmentDays');
      try {
        const querySnapshot = await fb.assignmentsCollection
          .where('event', '==', payload)
          .orderBy('day', 'desc')
          .get();

          console.log(payload);

          const daysArray = querySnapshot.docs.map((doc) => doc.data().day);
          const uniqueDays = Array.from(new Set(daysArray));
          commit('setAssignmentDays', uniqueDays);

        } catch (error) {
        console.error('Error fetching assignment days:', error);
        // Handle the error as needed
      }
    },
    clearAssignmentDays({ commit }) {
      commit('setAssignmentDays', []);
    },
    // async getEventShiftsData({ commit }, payload) {
    //   console.log('getEventShiftsData');

    //   try {
    //     // Set up a real-time listener for the shifts collection, filtering by eventId
    //     fb.shiftsCollection
    //       .where('eventId', '==', payload)
    //       .orderBy('day', 'desc')
    //       .onSnapshot((querySnapshot) => {
    //         const uniqueDaysSet = new Set();
    //         const eventShiftsToUpdate = [];

    //         querySnapshot.docChanges().forEach(async (change) => {
    //           if (change.type === 'added' || change.type === 'modified') {
    //             // Handle added or modified eventShift
    //             const shift = change.doc.data();
    //             shift.id = change.doc.id;

    //             // Add the day to the unique days set
    //             uniqueDaysSet.add(shift.day);

    //             // Push the modified or added eventShift to the update array
    //             eventShiftsToUpdate.push(shift);

    //             // Fetch other data or perform additional actions as needed
    //             // ...
    //           } else if (change.type === 'removed') {
    //             // Handle removed eventShift (optional)
    //             const removedShiftId = change.doc.id;

    //             // Commit removal directly to the store or perform removal actions as needed
    //             commit('removeEventShift', removedShiftId);

    //             // ...
    //           }
    //         });

    //         // Commit all modified or added eventShifts to the store
    //         commit('updateTheEventShifts', eventShiftsToUpdate);

    //         // Convert the Set to an array and commit to eventShiftDays
    //         const uniqueDays = Array.from(uniqueDaysSet);
    //         commit('setEventShiftsDays', uniqueDays);
    //       });
    //   } catch (error) {
    //     console.error('Error fetching event shifts and days:', error);
    //     // Handle the error as needed
    //   }
    // },
    getEventShiftsData({ commit }, payload) {
  console.log('getEventShiftsData');

  return new Promise((resolve, reject) => {
    try {
      // Set up a real-time listener for the shifts collection, filtering by eventId
      fb.shiftsCollection
        .where('eventId', '==', payload)
        .orderBy('day', 'desc')
        .onSnapshot(querySnapshot => {
          const uniqueDaysSet = new Set();
          const eventShiftsToUpdate = [];

          querySnapshot.forEach(doc => {
            const shift = doc.data();
            shift.id = doc.id;

            // Add the day to the unique days set
            uniqueDaysSet.add(shift.day);

            // Check if the shift already exists in the store
            const existingShiftIndex = eventShiftsToUpdate.findIndex(existingShift => existingShift.id === shift.id);

            if (existingShiftIndex !== -1) {
              // If the shift already exists, replace it with the updated shift
              eventShiftsToUpdate[existingShiftIndex] = shift;
            } else {
              // If the shift is new, add it to the update array
              eventShiftsToUpdate.push(shift);
            }
          });

          // Commit all modified or added eventShifts to the store
          commit('updateTheEventShifts', eventShiftsToUpdate);

          // Convert the Set to an array and commit to eventShiftDays
          const uniqueDays = Array.from(uniqueDaysSet);
          commit('setEventShiftsDays', uniqueDays);

          resolve(); // Resolve the promise
        });
    } catch (error) {
      console.error('Error fetching event shifts and days:', error);
      reject(error); // Reject the promise if an error occurs
    }
  });
},





    clearEventShiftsData({ commit }) {
      commit('setEventShifts', []);
      commit('setEventShiftsDays', []);
    },


    getEventShiftsId({ commit }, payload) {
      console.log('getEventShiftsId')
      fb.shiftsCollection.where("eventId", "==", payload).orderBy('day', 'desc')
      .get().then(querySnapshot => {
      // .onSnapshot(querySnapshot => {
        let shiftsArray = []
        querySnapshot.forEach(doc => {
          let shift = doc.data()
          shift.id = doc.id
          shiftsArray.push(shift)
        })
        commit('setEventShifts', shiftsArray)
      })
    },
    clearEventShifts({ commit }) {
      commit('setEventShifts', [])
    },
    getShifts({ commit }) {
      // Use try-catch for error handling
      try {
        fb.shiftsCollection.orderBy('day', 'desc').limit(1000)
          .get()
          .then(querySnapshot => {
            const shiftsArray = [];

            querySnapshot.forEach(doc => {
              const shiftData = doc.data();
              if (shiftData.day) {
                const day = new Date(shiftData.day);
                const target = new Date("2022-12-31");
                if (day >= target) {
                  shiftsArray.push(shiftData);
                }
              }
            });

            commit('setShifts', shiftsArray);
          });
      } catch (error) {
        // Handle any errors
        console.error("Error fetching shifts:", error);
      }
    },

    async getStaticEventAssignments({ commit }, payload) {
      console.log('getStaticEventAssignments');
      commit('setEventAssignments', []);

      try {
        const querySnapshot = await fb.assignmentsCollection
          .where('eventId', '==', payload)
          .get();

        const assignmentsArray = querySnapshot.docs.map((doc) => doc.data());

        commit('setEventAssignments', assignmentsArray);
      } catch (error) {
        console.error('Error fetching static event assignments:', error);
        // Handle the error as needed
      }
    },


    checkoutAssignment({ commit }, payload) {
      console.log(payload)
      fb.userDaysCollection.doc(payload.id).update({
        checkOutTimeStamp: fb.firestore.FieldValue.serverTimestamp(payload.newTime)
      })
    },
    checkinAssignment({ commit }, payload) {
      console.log(payload)
      fb.userDaysCollection.doc(payload.id).update({
        checkInTimeStamp: fb.firestore.FieldValue.serverTimestamp(payload.newTime)
      })
    },
    checkoutTimeAssignment({ commit }, payload) {
      var date = new Date(payload.checkOutTimeStamp.seconds*1000);
      let postedDate = new Date(payload.checkOutTimeStamp.seconds*1000);
      let split = payload.newTime.split(/:|,/)
      postedDate.setHours(split[0], split[1])
      let newSeconds = (postedDate.getTime()/1000)

      console.log(newSeconds)
      fb.userDaysCollection.doc(payload.id).update({
        checkOutTimeStamp: {
          seconds: newSeconds
        }
      })
    },
    updateCheckIn({ commit }, payload) {
      console.log('updateCheckIn')
      console.log(payload)
      fb.userDaysCollection.doc(payload.id).update({
        inTime: payload.inTime,
        spin: false,
        checkInTimeStamp: null
      })
    },
    updateCheckOut({ commit }, payload) {
      console.log('updateCheckOut')
      fb.userDaysCollection.doc(payload.id).update({
        outTime: payload.outTime,
        spin: false,
        checkOutTimeStamp: null
      })
    },
    newBreak({ commit }, payload) {
      console.log('newBreak')
      fb.userDaysCollection.doc(payload.id).update({
        breakTime: 0.5,
      })
    },
    cancelBreak({ commit }, payload) {
      console.log('cancelBreak')
      fb.userDaysCollection.doc(payload.id).update({
        breakTime: 0,
      })
    },
    newCheckIn({ commit }, payload) {
      console.log('newCheckIn')
      fb.userDaysCollection.doc(payload.id).update({
        checkInTimeStamp: fb.firestore.FieldValue.serverTimestamp(),
        spin: false,
        offset: payload.offset
      })
      // fb.userDaysCollection.doc(payload.id).get()
      // .then(doc => {
      //   const postedDate = new Date(doc.data().checkInTimeStamp.seconds) * 1000;
      //   console.log(postedDate)
      //   fb.userDaysCollection.doc(doc.id).update({
      //     inTime: moment(postedDate).format('HH:mm')
      //   })
      // })
    },
    newCheckOut({ commit }, payload) {
      console.log('newCheckOut')
      fb.userDaysCollection.doc(payload.id).update({
        checkOutTimeStamp: fb.firestore.FieldValue.serverTimestamp(),
        spin: false,
        offset: payload.offset
      })
      // fb.userDaysCollection.doc(payload.id).get()
      // .then(doc => {
      //   const postedDate = new Date(doc.data().checkOutTimeStamp.seconds) * 1000;
      //   console.log(postedDate)
      //   fb.userDaysCollection.doc(doc.id).update({
      //     outTime: moment(postedDate).format('HH:mm')
      //   })
      // })
    },
    deleteInTime({ commit }, payload) {
      console.log('deleteInTime')
      fb.userDaysCollection.doc(payload.id).update({
        inTime: '',
        checkInTimeStamp: firebase.firestore.FieldValue.delete()
      })
    },
    deleteOutTime({ commit }, payload) {
      console.log('deleteInTime')
      fb.userDaysCollection.doc(payload.id).update({
        outTime: '',
        checkOutTimeStamp: firebase.firestore.FieldValue.delete()
      })
    },
    deleteInTimeAssignment({ commit }, payload) {
      console.log('deleteInTimeAssignment')
      fb.assignmentsCollection.doc(payload.id).update({
        inTime: '',
        checkInTimeStamp: firebase.firestore.FieldValue.delete()
      })
    },
    deleteOutTimeAssignment({ commit }, payload) {
      console.log('deleteOutTimeAssignment')
      fb.assignmentsCollection.doc(payload.id).update({
        outTime: '',
        checkOutTimeStamp: firebase.firestore.FieldValue.delete()
      })
    },
    async updateAssignmentTotal({ commit }, payload) {
      try {
        const updatePayrollRowTotal = firebase.functions().httpsCallable('updatePayrollRowTotal');
        const response = await updatePayrollRowTotal({
          assignmentId: payload.id
        });
        
        if (response.data.success) {
          console.log("Payroll row total updated successfully");
        } else {
          console.error("Error updating payroll row total:", response.data.error);
        }
      } catch (error) {
        console.error("Error updating payroll row total:", error);
      }
    },

    // async updateAssignmentTotal({ commit }, payload) {
    //   console.log('updateAssignmentTotal')
    //   const updatePayrollRowTotal = firebase.functions().httpsCallable('updatePayrollRowTotal')

    //     await updatePayrollRowTotal(payload)
    //     // .then(result => {
    //     //   if (result && result.data) {
    //     //     return result.data
    //     //   }
    //     // })
    // },

    checkinTimeAssignment({ commit }, payload) {
      console.log('checkinTimeAssignment')
      var date = new Date(payload.checkInTimeStamp.seconds*1000);
      
      let postedDate = new Date(payload.checkInTimeStamp.seconds*1000);
      
      let newTime = fb.firestore.FieldValue.serverTimestamp()
      let split = newTime.split(/:|,/)

      postedDate.setHours(split[0], split[1])
      let newSeconds = (postedDate.getTime()/1000)

      fb.userDaysCollection.doc(payload.id).update({
        checkInTimeStamp: {
          seconds: newSeconds
        }
      })
    },
    updateUserDay({ commit }, payload) {
      console.log(payload)
      fb.userDaysCollection.doc(payload.id).update(payload)
    },
    clearEventAssignmentsByDay({ commit }) {
      commit('setEventAssignmentsByDay', [])
    },
    clearEventAssignments({ commit }) {
      commit('setEventAssignments', [])
      commit('setPaidEventAssignments', [])
      commit('setEventAssignmentsByUser', [])
      commit('setEventAssignmentsByDay', [])
      commit('setStaticEventAssignments', [])
    },
    clearStaticEventAssignments({ commit }) {
      commit('setStaticEventAssignments', [])
    },
    async getPaidAssignments({ commit }, payload) {
      console.log(payload);
      console.log('getPaidAssignments');

      const startDateString = payload.start;
      const endDateString = payload.end;

      const chunkSize = 250; // Adjust the chunk size as needed

      try {
        let lastDocument = null;
        const assArray = [];

        while (true) {
          // Query a chunk of data using limit, startAfter, and where clauses
          let query = fb.assignmentsCollection
            .where('date', '>=', startDateString)
            .where('date', '<=', endDateString)
            .where('paystatus', '==', 'paid') // Add this filter for payStatus
            .limit(chunkSize);

          if (lastDocument) {
            query = query.startAfter(lastDocument);
          }

          const querySnapshot = await query.get();

          if (querySnapshot.empty) {
            break; // No more data to fetch
          }

          querySnapshot.forEach((doc) => {
            const assignmentData = doc.data();
            assArray.push(assignmentData);
          });

          // Update the last document to start the next chunk
          lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1];
        }

        console.log(assArray);
        commit('setPaidAssignments', assArray);
      } catch (error) {
        console.error(error);
      }
    },
    deletePaidAssignments() {
      var deleteOldAssignments = firebase.functions().httpsCallable('deleteOldAssignments');
      deleteOldAssignments()
      .then((result) => {
        console.log(result)
      })
    },

    clearPaidAssignments({ commit }) {
      commit('setPaidAssignments', [])
    },
    async getDayShifts({ commit }, payload) {
      try {
        const shiftsCollection = fb.shiftsCollection
          .where("eventId", "==", payload.event);

        const querySnapshot = await shiftsCollection.get();

        const shiftsArray = querySnapshot.docs
          .filter(doc => doc.data().day === payload.day)
          .map(doc => {
            const shift = doc.data();
            shift.id = doc.id;
            return shift;
          });

        commit('setDayShifts', shiftsArray);
        
        // You may want to use async/await to ensure these dispatch actions finish before moving on
        await store.dispatch('getAssignmentsForDay', payload);
        await store.dispatch('getEventTimesheetNotes', payload.event);
      } catch (error) {
        // Handle any errors
        console.error("Error fetching day shifts:", error);
      }
    },

    clearDayShifts({ commit }) {
      commit('setDayShifts', [])
      commit('setShiftAssignments', [])
    },
    async getActiveShifts({ commit }, payload) {
      console.log('getActiveShifts');
      
      try {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        console.log(yesterday);
        const now = yesterday.getTime();
        console.log(now);

        const querySnapshot = await fb.shiftsCollection.orderBy('day', 'desc').limit(5).get();
        const shiftsArray = [];

        querySnapshot.forEach((doc) => {
          const shift = doc.data();
          shift.id = doc.id;
          const day = new Date(shift.day);
          day.setDate(day.getDate());
          const shiftTime = day.getTime();

          if ((shiftTime >= now) && (!shift.status || (shift.status !== 'cancelled'))) {
            shiftsArray.push(shift);
          }
        });

        // Remove duplicates from shiftsArray based on their ID
        const unique = Array.from(new Set(shiftsArray.map(a => a.id)))
          .map(id => shiftsArray.find(a => a.id === id));

        commit('setTheShifts', unique);
      } catch (error) {
        console.error(error);
      }
    },

    clearActiveShifts({commit}) {
      commit('setTheShifts', [])
    },
    clearShiftsState({ commit }) {
      commit('setShifts', [])
    },
    getShiftFromIdOnly({ commit }, payload) {
      console.log('getShiftFromIdOnly')
      fb.shiftsCollection.doc(payload).get()
      .then(
        doc => {
          commit("setShift", doc.data())
        }
      )
    },
    getShiftFromId({ commit }, payload) {
      console.log('getShiftFromId')
      fb.shiftsCollection.doc(payload).get()
      .then(
        doc => {
          commit("setShift", doc.data())
        }
      )
      store.dispatch('getAssignmentsForShift', payload)
    },
    async getAssignmentsForShift({ commit }, payload) {
      try {
        const assignmentsCollection = fb.assignmentsCollection
          .where("shiftId", "==", payload)
          .orderBy('firstName', 'asc');

        const querySnapshot = await assignmentsCollection.get();

        const assignmentsArray = querySnapshot.docs.map(doc => {
          const assignment = doc.data();
          assignment.id = doc.id;
          return assignment;
        });

        commit('setShiftAssignments', assignmentsArray);
      } catch (error) {
        // Handle any errors
        console.error("Error fetching assignments for shift:", error);
      }
    },
    async getAssignmentsForDay({ commit }, payload) {
      try {
        const assignmentsCollection = fb.assignmentsCollection
          .where("eventId", "==", payload.event)
          .orderBy('firstName', 'asc');

        const querySnapshot = await assignmentsCollection.get();

        const assignmentsArray = querySnapshot.docs
          .filter(doc => doc.data().day === payload.day)
          .map(doc => {
            const assignment = doc.data();
            assignment.id = doc.id;
            return assignment;
          });

        commit('setShiftAssignments', assignmentsArray);
      } catch (error) {
        // Handle any errors
        console.error("Error fetching assignments for day:", error);
      }
    },

    clearShiftState({ commit }) {
      commit('setShift', {})
      commit('setShiftAssignments', [])
    },
    async addUserToShift({ commit }, payload) {
      try {
        const shiftDoc = await fb.eventsCollection.doc(payload.shift.eventId).get();

        if (!shiftDoc.exists) {
          console.error("Shift document does not exist");
          return; // Exit early if the shift document doesn't exist
        }

        const event = shiftDoc.data();
        const shift = payload.shift;
        const dateObj = new Date(payload.shift.day);
        const month = dateObj.getUTCMonth() + 1;
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        const newdate = month + "/" + day + "/" + year;
        const shiftStart = payload.shiftStart;
        const shiftEnd = payload.shiftEnd;
        const assignment = {
          shiftId: shift.id,
          userId: payload.user.objectID,
          date: newdate,
          day: shift.day,
          eventId: shift.eventId,
          email: payload.user.email,
          firstName: payload.user.firstName,
          lastName: payload.user.lastName,
          phone: payload.user.phone,
          name: shift.event,
          fileId: payload.user.employeeNumber || payload.user.contractorNumber || '123',
          position: shift.position.title,
          start: shift.day[0] + " " + shift.startTime,
          end: shift.day[0] + " " + shift.endTime,
          startTime: shift.startTime,
          endTime: shift.endTime,
          eventInfo: event,
          shiftStart: shiftStart,
          shiftEnd: shiftEnd,
          event: event.id,
          eventName: event.title,
          slug: event.slug,
        };

        const assignmentDocRef = await fb.assignmentsCollection.add(assignment);
        await assignmentDocRef.update({
          id: assignmentDocRef.id,
          created: fb.firestore.FieldValue.serverTimestamp()
        });
      } catch (error) {
        // Handle any errors
        console.error("Error adding user to shift:", error);
      }
    },

    addEventTimesheetNote({ commit, state }, payload) {
      console.log('addEventTimesheetNote')
      fb.eventTimesheetNotesCollection.add(payload)
      .then(
        doc => {
          fb.eventTimesheetNotesCollection.doc(doc.id).update({
            eventId: payload.eventId,
            submittedBy: state.currentUser.uid,
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp()
          })
        }
      )
    },
    getEventTimesheetNotes({ commit }, payload) {
      console.log('getEventTimesheetNotes')
      fb.eventTimesheetNotesCollection.where("eventId", "==", payload).orderBy('created', 'desc')
      .onSnapshot(querySnapshot => {
        let userNotesArray = []
        querySnapshot.forEach(doc => {
          let note = doc.data()
          note.id = doc.id
          userNotesArray.push(note)
        })
        commit('setEventTimesheetNotes', userNotesArray)
      })
    },
    getAllPayroll({ commit }) {
      console.log('getAllPayroll')
      fb.assignmentsCollection.where("paystatus", "!=", "paid")
      .onSnapshot(querySnapshot => {
        let assignmentsArray = []
        querySnapshot.forEach(doc => {
          let docDate = new Date(doc.data().date)
          let Feb = new Date("2/1/2022")
          if (docDate > Feb) {
            assignmentsArray.push(doc.data())
          }
        })
        commit('setAllPayroll', assignmentsArray)
      })
    },
    clearAllPayroll({ commit }) {
      commit('setAllPayroll', [])
    },
    // getUserPayroll({ commit }, payload) {
    //   // Use try-catch for error handling
    //   try {
    //     fb.assignmentsCollection
    //       .where("userId", "==", payload)
    //       .get()
    //       .then(querySnapshot => {
    //         const assignmentsArray = [];

    //         querySnapshot.forEach(doc => {
    //           const assignmentData = doc.data();
    //           const docDate = new Date(assignmentData.date);
    //           const Feb = new Date("2022-02-01");

    //           if (docDate > Feb && !assignmentData.hidden && (!assignmentData.paystatus || assignmentData.paystatus !== 'paid')) {
    //             assignmentsArray.push(assignmentData);
    //           }
    //         });

    //         commit('setUserPayroll', assignmentsArray);
    //       });
    //   } catch (error) {
    //     // Handle any errors
    //     console.error("Error fetching user payroll:", error);
    //   }
    // },

    clearUserPayroll({ commit }) {
      commit('setUserPayroll', [])
    },
  
    clearEventCheckin({ commit }) {
      commit('setStaticEventUsers', [])
    },

    getStaticEventUsers({ commit }, payload) {
      console.log('getStaticEventUsers')
      fb.userDaysCollection.where("preferredEvent", "==", payload).orderBy('created', 'asc')
      .get()
        .then(snapshot => {
          let eventUsersArray = []
          snapshot.forEach(doc => {
            let user = doc.data()
            eventUsersArray.push(user)
          })
        commit('setStaticEventUsers', eventUsersArray)
      })
    },
    getDayUsers({ commit, state }, payload) {
      const usersArray = state.eventUsers.filter(item => item.day === payload.day);
      commit('setDayUsers', usersArray);
    },
    
    // async getAssociatedGroupUsers({ commit }, payload) {
    //   console.log('getAssociatedGroupUsers');
    //   console.log(payload);

    //   try {
    //     const eventDoc = await fb.eventsCollection.doc(payload).get();
    //     console.log(eventDoc);
    //     const usersArray = [];

    //     if (eventDoc.exists) {
    //       const groups = eventDoc.data().groups || [];
          

    //       for (const groupObject of groups) {
    //           const groupId = groupObject.id;

    //           const groupDoc = await fb.groupsCollection.doc(groupId).get();
    //           console.log(groupDoc.data());
    //           if (groupDoc.exists) {
    //             const groupData = groupDoc.data();
    //             if (groupData.members && groupData.members.length > 0) {
    //               usersArray.push(...groupData.members);
    //             }
    //           }
    //         }

    //       // Deduplicate the usersArray
    //       const unique = Array.from(new Set(usersArray));

    //       let fullArray = []

    //       unique.forEach(user => {
    //         fb.usersCollection.doc(user).get()
    //         .then(doc => {
    //           fullArray.push(doc.data())
    //         })
    //       })

    //       commit('setAssociatedGroupUsers', fullArray);
    //     } else {
    //       console.log('else');
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    async getAssociatedGroupUsers({ commit }, payload) {
  console.log('getAssociatedGroupUsers');
  console.log(payload);

  try {
    const eventDoc = await fb.eventsCollection.doc(payload).get();
    console.log(eventDoc);
    const usersArray = [];

    if (eventDoc.exists) {
      const groups = eventDoc.data().groups || [];

      for (const groupObject of groups) {
        const groupId = groupObject.id;

        const groupDoc = await fb.groupsCollection.doc(groupId).get();
        console.log(groupDoc.data());
        if (groupDoc.exists) {
          const groupData = groupDoc.data();
          if (groupData.members && groupData.members.length > 0) {
            usersArray.push(...groupData.members);
          }
          
          if (groupData.applicants && groupData.applicants.length > 0) {
            usersArray.push(...groupData.applicants);
          }
        }
        // if (groupDoc.exists) {
        //   const groupData = groupDoc.data();
        //   if (groupData.members && groupData.members.length > 0) {
        //     usersArray.push(...groupData.members);
        //   }
        // }
      }

      // Deduplicate the usersArray
      const unique = Array.from(new Set(usersArray));

      // Fetch user data for valid IDs
      const fullArray = await Promise.all(
        unique.map(async (user) => {
          const userDoc = await fb.usersCollection.doc(user).get();
          if (userDoc.exists) {
            return userDoc.data();
          }
          return null; // Return null for non-existent users
        })
      );

      // Filter out null values
      const validUsers = fullArray.filter((user) => user !== null);

      commit('setAssociatedGroupUsers', validUsers);
    } else {
      console.log('else');
    }
  } catch (error) {
    console.error(error);
  }
},

    clearAssociatedGroupUsers({ commit }) {
      commit('setAssociatedGroupUsers', []);
    },
    getEventGroupUsers({ commit }, payload) {
      console.log('getEventGroupUsers');
      console.log(payload);

      return new Promise((resolve, reject) => {
        fb.assignmentsCollection
          .where('eventId', '==', payload)
          // .orderBy('day', 'desc')
          .limit(350)
          .get()
          .then(querySnapshot => {
            const eventGroupUsersArray = [];

            querySnapshot.forEach(doc => {
              const assignmentData = doc.data();
              if (assignmentData && assignmentData.id && assignmentData.userId && assignmentData.position) {
                eventGroupUsersArray.push(assignmentData);
              }
            });

            // If you want to remove duplicates based on the `userId` property:
            const uniqueArray = eventGroupUsersArray.filter((user, index, self) =>
              index === self.findIndex(u => u.userId === user.userId)
            );

            commit('setEventGroupUsers', uniqueArray);
            resolve(uniqueArray);
          })
          .catch(error => {
            console.error(error);
            reject(error);
          });
      });
    },

// async getEventGroupUsers({ commit }, payload) {
//   console.log('getEventGroupUsers', payload);

//   try {
//     const querySnapshot = await fb.assignmentsCollection
//       .where('eventId', '==', payload)
//       .get();

//     const eventGroupUsersMap = new Map(); // Use a map to store unique users based on userId

//     querySnapshot.forEach(doc => {
//       if (doc.exists) {
//         const assignmentData = doc.data();
//         const userId = assignmentData.userId;

//         if (!eventGroupUsersMap.has(userId)) {
//           eventGroupUsersMap.set(userId, assignmentData);
//         }
//       }
//     });

//     const uniqueArray = Array.from(eventGroupUsersMap.values());

//     commit('setEventGroupUsers', uniqueArray);
//     // store.dispatch('getEventAssignments', payload);
//   } catch (error) {
//     console.error('Error fetching event group users:', error);
//   }
// },

    clearEventGroupUsers({ commit }) {
      commit('setEventGroupUsers', [])
    },
    async getEventUsersByDay({ commit }, payload) {
      console.log('getEventUsersByDay');
      console.log(payload);

      try {
        const query = await fb.userDaysCollection
          .where("preferredEvent", "==", payload.event.id)
          .where("day", "==", payload.day);

        query.onSnapshot((querySnapshot) => {
          const eventUsersArray = [];

          querySnapshot.forEach((doc) => {
            eventUsersArray.push(doc.data());
          });


          commit('setEventUsers', eventUsersArray);
        });
      } catch (error) {
        console.error('Error fetching event users by day:', error);
        // Handle the error as needed
      }
    },
//     async getEventUsers({ commit }, payload) {
//   console.log('getEventUsers');
//   try {
//     const batchSize = 10; // Reduced batch size for faster fetching
//     let lastDocument = null;

//     // Set up the initial query
//     let query = fb.userDaysCollection
//                   .where("preferredEvent", "==", payload)
//                   .orderBy("created") // Order by a field that ensures consistent ordering
//                   .limit(batchSize);
    
//     while (true) {
//       // Fetch the next batch of documents
//       let querySnapshot = await query.get();
      
//       // Process the batch of documents
//       const eventUsersArray = [];
//       querySnapshot.forEach((doc) => {
//         const userData = doc.data();
//         userData.id = doc.id; // Store the document ID for reference
//         eventUsersArray.push(userData);
//         lastDocument = doc; // Update the last document reference
//       });

//       // Commit the batch of documents to the store
//       if (eventUsersArray.length > 0) {
//         if (!lastDocument) {
//           // If it's the initial fetch, commit as 'setEventUsers'
//           commit('setEventUsers', eventUsersArray);
//         } else {
//           // Otherwise, append to the existing data
//           commit('appendEventUsers', eventUsersArray);
//         }
//       }

//       // Check if there are more documents to fetch
//       if (querySnapshot.size < batchSize) {
//         break; // Exit the loop if there are no more documents
//       }

//       // Construct the next query starting after the last document of the previous batch
//       query = fb.userDaysCollection
//                 .where("preferredEvent", "==", payload)
//                 .orderBy("created")
//                 .startAfter(lastDocument)
//                 .limit(batchSize);
//     }
//   } catch (error) {
//     console.error('Error fetching event users:', error);
//     // Handle the error as needed
//   }
// },

    getEventUsers({ commit }, payload) {
      console.log('getEventUsers limit 100')
      fb.userDaysCollection.where("preferredEvent", "==", payload).orderBy('created', 'desc').limit('500')
      .onSnapshot(querySnapshot => {
        let eventUsersArray = []
        querySnapshot.forEach(doc => {
          let user = doc.data()
          eventUsersArray.push(user)
        })
        commit('setEventUsers', eventUsersArray)
      })
    },
    

    // async getEventUsers({ commit }, payload) {
    //   console.log('getEventUsers')
    //   console.log(payload)
    //   try {
    //     const query = fb.userDaysCollection.where("preferredEvent", "==", payload);

    //     // Attach a real-time listener to the query
    //     query.onSnapshot((querySnapshot) => {
    //       const eventUsersArray = [];

    //       querySnapshot.forEach((doc) => {
    //         const userData = doc.data();
    //         userData.id = doc.id; // Store the document ID for reference
    //         eventUsersArray.push(userData);
    //       });

    //       // Commit the updated user data to the store
    //       commit('setEventUsers', eventUsersArray);
    //     });
    //   } catch (error) {
    //     console.error('Error fetching event users:', error);
    //     // Handle the error as needed
    //   }
    // },
    clearEventUsersAndRef({ commit }) {
      commit('setEventUsers', [])
    },
    getEventDrops({ commit }, payload) {
      console.log('getEventDrops')
      fb.dropsCollection.where("preferredEvent", "==", payload).orderBy('created', 'asc')
      .onSnapshot(querySnapshot => {
        let eventUsersArray = []
        querySnapshot.forEach(doc => {
          let user = doc.data()
          eventUsersArray.push(user)
        })
        commit('setEventDrops', eventUsersArray)
      })
    },
    clearEventDrops({ commit }) {
      commit('setEventDrops', [])
    },
    getDayShiftsState({ commit }, payload) {
      console.log('getDayShiftsState(')
      fb.shiftsCollection.where("day", "==", payload).orderBy('startTime', 'asc')
        .get()
        .then(snapshot => {
          let dayShiftsArray = []
          snapshot.forEach(doc => {
            let dayShift = doc.data()
            dayShiftsArray.push(dayShift)
          })
          commit('setDayShifts', dayShiftsArray)
          store.dispatch('getUserAvailabilityState', payload)
      })
    },
    getAccountingEvents1({ commit }, payload) {
      fb.eventsCollection.where("published", "==", true).orderBy('startDate', 'asc')
      .onSnapshot(querySnapshot => {
        let eventsArray = []
        querySnapshot.forEach(doc => {
          let event = doc.data()
          if (event.status != 'cancelled' && !event.invoiceStatus && event.startDate.includes("2022")) {
            eventsArray.push(event)
          }
        })
        commit('setEvents', eventsArray.slice(0, 96))
      })
    },

    getAccountingEvents2({ commit }, payload) {
      fb.eventsCollection.where("published", "==", true).orderBy('startDate', 'desc')
      .onSnapshot(querySnapshot => {
        let completeEventsArray = []
        let partialEventsArray = []
        let cancelledEventsArray = []
        querySnapshot.forEach(doc => {
          
          let event = doc.data()
          // if (event.status != 'cancelled') {
          //   eventsArray.push(event)
          // }
          // if (event.status != 'cancelled' && event.startDate.includes("2022")) {
          //   allArray.push(event)
          // }
          if (event.status != 'cancelled' && event.invoiceStatus == 'complete' && event.startDate.includes("2022")) {
            completeEventsArray.push(event)
          }
          if (event.status != 'cancelled' && event.invoiceStatus == 'partial' && event.startDate.includes("2022")) {
            partialEventsArray.push(event)
          }
          // if (event.status != 'cancelled' && !event.invoiceStatus && event.startDate.includes("2022")) {
          //   eventsArray.push(event)
          // }
          if (event.status == 'cancelled' && event.startDate.includes("2022")) {
            cancelledEventsArray.push(event)
          }
        })
        // commit('setEvents', eventsArray.slice(0, 96))
        // commit('setAllEvents', allArray)
        commit('setPartialEvents', partialEventsArray)
        commit('setCompleteEvents', completeEventsArray.slice(0, 96))
        commit('setEventsCancelled', cancelledEventsArray)
      })
    },
    clearEvents({ commit }) {
      commit('setEvents', [])
      // commit('setAllEvents', [])
      commit('setPartialEvents', [])
      commit('setCompleteEvents', [])
      commit('setEventsCancelled', [])
      commit('setEventsTotal', null)
    },
    getEventsByDay({ commit }, payload) {
      console.log('getEventsByDay')
      fb.eventsCollection.where("days", "array-contains", payload).orderBy('title', 'asc').onSnapshot(querySnapshot => {
        let dayEventsArray = []
        querySnapshot.forEach(doc => {
          let dayEvents = doc.data()
          dayEvents.id = doc.id
          dayEventsArray.push(dayEvents)
        })
        commit('setEventsByDay', dayEventsArray)
      })
    },
    // getEventShiftsState({ commit }, payload) {
    //   console.log('getEventShiftsState')
    //   fb.shiftsCollection.where("eventId", "==", payload).orderBy('name', 'asc')
    //  .onSnapshot(querySnapshot => {
    //     let eventShiftsArray = []
    //     querySnapshot.forEach(doc => {
    //       let eventShift = doc.data()
    //       eventShiftsArray.push(eventShift)
    //     })
    //     commit('setEventShifts', eventShiftsArray)
    //     // store.dispatch('getUserAvailabilityState', payload)
    //   })
    // },
    getUserAvailabilityState({ commit }, payload) {
      console.log('getUserAvailabilityState')
      fb.userDaysCollection.where("day", "==", payload)
      // .get()
      // .then((querySnapshot) => {
      //   let dayUsersArray = []
      //   querySnapshot.forEach((doc) => {
      //     let dayUser = doc.data()
      //     dayUsersArray.push(dayUser)
      //   })
      //   commit('setDayUsers', dayUsersArray)
      // })
      .get().then((querySnapshot) => {
        let dayUsersArray = []
        querySnapshot.forEach((doc) => {
          let dayUser = doc.data()
          dayUsersArray.push(dayUser)
        })
        commit('setDayUsers', dayUsersArray)
      })
    },
    // getDayUserListState({ commit }, payload) {
    //   fb.usersCollection.onSnapshot(querySnapshot => {
    //     let usersArray = []
    //     querySnapshot.forEach(doc => {
    //       let user = doc.data()
    //       user.id = doc.id
    //       usersArray.push(user)
    //     })
    //     const availableUsers = usersArray.filter(item => payload.includes(item.id))
    //     commit('setAvailableUsers', availableUsers)
    //   })
    // },
    getDayEventsState({ commit }, payload) {
      console.log('getDayEventsState')
      fb.eventsCollection.where("days", "array-contains", payload).orderBy('startDate', 'desc').onSnapshot(querySnapshot => {
        let dayEventsArray = []
        querySnapshot.forEach(doc => {
          let dayEvent = doc.data()
          dayEvent.id = doc.id
          dayEventsArray.push(dayEvent)
        })
        commit('setDayEvents', dayEventsArray)
      })
    },

    removeUserPlacement({ commit }, payload) {
      fb.userDaysCollection.doc(payload.id).update({
        status: "available", 
        shift: null,
        event: null,
        eventName: null,
        slug: null,
        showTrash: false,
      })
    },
    updateSendPlacementNotifications({ commit }, payload) {
      fb.eventsCollection.onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(doc.id)
          fb.eventsCollection.doc(doc.id).update({
            sendPlacementNotifications: true
          })
        })
      })
    },
    unreserveUser({ commit }, payload) {
      fb.userDaysCollection.doc(payload.id).update({
        sendPlacementNotifications: payload.sendPlacementNotifications,
        dayStatus: firebase.firestore.FieldValue.delete()
      })
    },


    async addPlacement({ commit }, payload) {
      try {
        console.log(payload);

        let eventId = payload.shift.eventId
        let userId = payload.userId

        await fb.userDaysCollection.where("preferredEvent", "==", eventId).where("day", "==",  payload.placement.day)
        .onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(doc.data())
          fb.userDaysCollection.doc(doc.id).update({
            status: "placed",
            shift: payload.placement.shiftId,
            requestedShift: payload.shift,
            start: ''
          });
          })
        })

        console.log("Placement updated successfully");
      } catch (error) {
        console.error("Error updating placement:", error);
        // Handle the error as needed
      }
    },
    clearShiftUser({ commit }) {
      commit("setCheckinUser", null)
    },

    async getPayableContractors({ commit }) {
      const listPayables = firebase.functions().httpsCallable('listPayableContractors')
        await listPayables()
      .then(result => {
        let payables = []
        console.log(result.data)
        // commit('setPayableContractors', result.data)
        result.data.items.forEach(item => {
          fb.assignmentsCollection.doc(item.id)
          .get()
          .then(doc => {
            payables.push(doc.data())
          })
        })
        commit('setPayableContractors', payables)
      })
    },
    async getPayableEmployees({ commit }) {
      try {
        // Fetch data from the API using listPayableEmployees
        const listPayables = firebase.functions().httpsCallable('listPayableEmployees');
        const result = await listPayables();
        const payables = [];

        console.log(result.data);

        for (const item of result.data.items) {
          const doc = await fb.assignmentsCollection.doc(item.id).get();

          if (doc.exists && doc.data().paystatus == 'scheduled') {
            payables.push(doc.data());
          }
        }

        // Commit the payables array to the Vuex store
        commit('setPayableEmployees', payables);

      } catch (error) {
        console.error('Error:', error);
        // Handle the error as needed
      }
    },
    clearPayables({ commit }) {
      commit('setPayableContractors', [])
      commit('setPayableEmployees', [])
    },



    async getPayrollQueue({ commit }) {
      console.log('getting queue again');
      
      try {
        const timestamp = new Date('2023-01-01');
        const querySnapshot = await fb.assignmentsCollection.where("created", ">=", timestamp).get();
        const queueArray = [];

        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (!(data.paystatus && data.paystatus === 'paid') && !data.hidden) {
            if (data.status && data.status !== 'no-show' && data.status !== 'cancelled') {
              queueArray.push(data);
            }
          }
        });

        commit('setPayrollQueue', queueArray);
        // store.dispatch('getPayrollUsers', assignmentsArray);
      } catch (error) {
        console.error(error);
      }
    },

    clearPayrollQueue({ commit }) {
      console.log('clearing queue')
      commit('setPayrollQueue', [])
    },
    // getPayrollUsers({ commit }, payload) {
     
    //   let usersArray = []
    //   payload.forEach((user) => {
    //     fb.usersCollection.doc(user.userId)
    //     .onSnapshot((doc) => {
    //       console.log(doc.id)
    //       usersArray.push(doc.data())
    //     })
    //     commit('setUsers', usersArray)
    //   })
      
    // },
    getUsersPerDay({ commit }) {
      console.log('getUsersPerDay')
      fb.userDaysCollection.onSnapshot(querySnapshot => {
        let userDaysArray = []
        querySnapshot.forEach(doc => {
          let day = doc.data()
          userDaysArray.push(day)
        })
        commit('setUsersPerDay', userDaysArray)
      })
    },
    clearDayState({ commit }) {
      commit('setDayEvents', null)
      commit('setEventsByDay', null)
      commit('setDayShifts', null)
      commit('setDayUsers', null)
      commit('setUsers', [])
      commit('setAvailableUsers', null)
    },
    clearPlacementsState({ commit }) {
      commit('setEventDays', null)
      commit('setUsersPerDay', null)
    },
    clearEventUsers({ commit }) {
      commit('setEventGroupUsers', [])
      commit('setAssociatedGroupUsers', [])
      commit('setEventUsers', [])
      commit('setDayUsers', [])
      commit('setEventUsersRef', [])
      commit('setEventDrops', [])
    },
    clearEventShiftsState({ commit }) {
      commit('setEventShifts', null)
      commit('setEventInfo', null)
    },
    clearPlacementsHome({ commit }) {
      commit('setUsersPerDay', null)
      commit('setPastEvents', null)
    },



    /*TIMESHEETS*/

    deleteTimesheetEntry({ commit }, payload) {
      fb.assignmentsCollection.doc(payload.id).delete()
    },

    updateTimesheet({ commit }, payload) {
      console.log(payload)
      fb.assignmentsCollection.doc(payload.id).update(payload)
    },

    updateTimesheetRates({ commit }, payload) {
      fb.assignmentsCollection.doc(payload.id).update(payload)
    },

    updateTimesheetPay({ commit }, payload) {
      console.log(payload)
      fb.assignmentsCollection.doc(payload.id).update({
        paystatus: payload.paystatus
      })
      // if (payload.status == 'paid') {
      //   fb.assignmentsCollection.doc(payload.id).get()
      //   .then(
      //     doc => {
      //       fb.usersCollection.doc(doc.data().userId).update({
      //         branchOnboard: true
      //       })
      //     }
      //   )
      // }
    },
    updateTimesheetStatus({ commit }, payload) {
      console.log(payload)
      fb.assignmentsCollection.doc(payload.id).update({
        status: payload.status
      })
    },
    getOprs({ commit }, payload) {
      fb.oprCollection.orderBy('created', 'desc').onSnapshot(querySnapshot => {
        let oprArray = []
        querySnapshot.forEach(doc => {
          let op = doc.data()
          op.id = doc.id
          oprArray.push(op)
        })
        commit('setOpr', oprArray)
      })
    },
    removeUserOpr({ commit }, payload) {
      console.log(payload)
      fb.oprCollection.where("user.id", "==", payload.user.id).delete()
    },
    clearOprState({ commit }) {
      commit('setOpr', [])
    },


    clearErrors({ commit }) {
      commit('setErrorMessage', '')
    },

    getOrderStats({ commit }) {
      fb.statsCollection.doc("requiredCounts").get()
      .then(doc => {
        commit('setRequiredCount', doc.data())
      })
      fb.statsCollection.doc("assignments").get()
      .then(doc => {
        commit('setTotalAssignmentsCount', doc.data())
      })
    },

    clearAssignmentCache({ commit }) {
      commit('cacheAssignments', null)
      commit('setEventAssignments', [])
      commit('updateStartAt', null)
      commit('setEventGroupUsers', [])
      commit('updateStartAfterGroupUsers', [])
      commit('cacheEventGroupUsers', [])
    }
  },
  mutations: {
    cacheAssignments(state, cachedAssignments) {
    state.cachedAssignments = cachedAssignments;
    },  
    setEventAssignments(state, val) {
      if (val) {
        state.eventAssignments = val;
      } else {
        state.eventAssignments = []
      }
    },
    setEventAssignmentsByUser(state, val) {
      if (val) {
        state.eventAssignmentsByUser = val;
      } else {
        state.eventAssignmentsByUser = []
      }
    },
    setPaidEventAssignments(state, val) {
      if (val) {
        state.paidEventAssignments = val;
      } else {
        state.paidEventAssignments = []
      }
    },
    setAllEmployeeAssignments(state, val) {
      if (val) {
        state.allEmployeeAssignments = val;
      } else {
        state.allEmployeeAssignments = []
      }
    },
    setAllContractorAssignments(state, val) {
      if (val) {
        state.allContractorAssignments = val;
      } else {
        state.allContractorAssignments = []
      }
    },
    updateStartAt(state, newStartAt) {
      state.startAt = newStartAt;
    },
    emailVerified(state, val) {
      state.emailVerified = val
    },
    setCurrentUser(state, val) {
      state.currentUser = val
    },
    setErrorMessage(state, val) {
      state.errorMessage = val
    },
    setRequiredCount(state, val) {
      state.requiredCount = val
    },
    setTotalAssignmentsCount(state, val) {
      state.totalAssignmentsCount = val
    },

    setUserProfile(state, val) {
      state.userProfile = val
    },

    setVenues(state, val) {
      if (val) {
        state.venues = val
      } else {
        state.venues = []
      }
    },
    setHiddenVenues(state, val) {
      if (val) {
        state.hiddenVenues = val
      } else {
        state.hiddenVenues = []
      }
    },
    setVenueInfo(state, val) {
      state.venueInfo = val
    },
    setVenueEvents(state, val) {
      if (val) {
        state.venueEvents = val
      } else {
        state.venueEvents = []
      }
    },
    setVenueFollowers(state, val) {
      if (val) {
        state.venueFollowers = val
      } else {
        state.venueFollowers = []
      }
    },
    setSeries(state, val) {
      if (val) {
        state.series = val
      } else {
        state.series = []
      }
    },
    setSeriesDays(state, val) {
      if (val) {
        state.seriesDays = val
      } else {
        state.seriesDays = []
      }
    },
    setCheckinUser(state, val) {
      state.checkinUser = val
    },
    setSeriesInfo(state, val) {
      state.seriesInfo = val
    },
    setSeriesDays(state, val) {
      if (val) {
        state.seriesDays = val
      } else {
        state.seriesDays = []
      }
    },
    setOpr(state, val) {
      if (val) {
        state.opr = val
      } else {
        state.opr = []
      }
    },
    setPayrollHelp(state, val) {
      if (val) {
        state.payrollHelp = val
      } else {
        state.payrollHelp = []
      }
    },
    setEvents(state, val) {
      if (val) {
        state.events = val
      } else {
        state.events = []
      }
    },
    set2021Events(state, val) {
      if (val) {
        state.events2021 = val
      } else {
        state.events2021 = []
      }
    },
    set2022Events(state, val) {
      if (val) {
        state.events2022 = val
      } else {
        state.events2022 = []
      }
    },
    setEventsByDay(state, val) {
      if (val) {
        state.eventsByDay = val
      } else {
        state.eventsByDay = []
      }
    },
    setEventDays(state, val) {
      if (val) {
        state.eventDays = val
      } else {
        state.eventDays = []
      }
    },
    // setCheckinInfo(state, val) {
    //   state.checkinInfo = val
    // },
    setWeekInfo(state, val) {
      state.weekInfo = val
    },
    setEventInfo(state, val) {
      state.eventInfo = val
    },
    setEventNotes(state, val) {
      if (val) {
        state.eventNotes = val
      } else {
        state.eventNotes = []
      }
    },
    setEventShifts(state, val) {
      if (val) {
        state.eventShifts = val
      } else {
        state.eventShifts = []
      }
    },
    setDayEvents(state, val) {
      if (val) {
        state.dayEvents = val
      } else {
        state.dayEvents = []
      }
    },
    setAllEvents(state, val) {
      if (val) {
        state.allEvents = val
      } else {
        state.allEvents = []
      }
    },
    setPastEvents(state, val) {
      if (val) {
        state.pastEvents = val
      } else {
        state.pastEvents = []
      }
    },
    setPayrollSubmissions(state, val) {
      if (val) {
        state.payrollSubmissions = val
      } else {
        state.payrollSubmissions = []
      }
    },
    setNYUsers(state, val) {
      if (val) {
        state.nYUsers = val
      } else {
        state.nYUsers = []
      }
    },
    setUsers(state, val) {
      if (val) {
        state.users = val
      } else {
        state.users = []
      }
    },
    setGroupNotes(state, val) {
      if (val) {
        state.groupNotes = val
      } else {
        state.groupNotes = []
      }
    },
    setGroupMessages(state, val) {
      if (val) {
        state.groupMessages = val
      } else {
        state.groupMessages = []
      }
    },
    setGroupUsers(state, val) {
      if (val) {
        state.groupUsers = val
      } else {
        state.groupUsers = []
      }
    },
    setGroupWaitlist(state, val) {
      if (val) {
        state.groupWaitlist = val
      } else {
        state.groupWaitlist = []
      }
    },
    setGroupApplications(state, val) {
      if (val) {
        state.groupApplications = val
      } else {
        state.groupApplications = []
      }
    },
    setUserNotes(state, val) {
      if (val) {
        state.userNotes = val
      } else {
        state.userNotes = []
      }
    },
    setUserTimesheets(state, val) {
      if (val) {
        state.userTimesheets = val
      } else {
        state.userTimesheets = []
      }
    },
    setContactNotes(state, val) {
      if (val) {
        state.contactNotes = val
      } else {
        state.contactNotes = []
      }
    },
    setUserMessages(state, val) {
      if (val) {
        state.userMessages = val
      } else {
        state.userMessages = []
      }
    },
    setUserEvents(state, val) {
      if (val) {
        state.userEvents = val
      } else {
        state.userEvents = []
      }
    },
    setReviews(state, val) {
      if (val) {
        state.reviews = val
      } else {
        state.reviews = []
      }
    },
    setUserAssignments(state, val) {
      if (val) {
        state.userAssignments = val
      } else {
        state.userAssignments = []
      }
    },
    setUserApplications(state, val) {
      if (val) {
        state.userApplications = val
      } else {
        state.userApplications = []
      }
    },
    setEventTimesheetNotes(state, val) {
      if (val) {
        state.eventTimesheetNotes = val
      } else {
        state.eventTimesheetNotes = []
      }
    },
    setUserInfo(state, val) {
      state.userInfo = val
    },
    userEmailVerified(state, val) {
      state.userEmailVerified = val
    },
    setJobs(state, val) {
      if (val) {
        state.jobs = val
      } else {
        state.jobs = []
      }
    },
    setMgrs(state, val) {
      if (val) {
        state.mgrs = val
      } else {
        state.mgrs = []
      }
    },
    setMgrInfo(state, val) {
      state.mgrInfo = val
    },
    setJobInfo(state, val) {
      state.jobInfo = val
    },
    setFaqs(state, val) {
      if (val) {
        state.faqs = val
      } else {
        state.faqs = []
      }
    },
    setFaqInfo(state, val) {
      state.faqInfo = val
    },
    setClients(state, val) {
      if (val) {
        state.clients = val
      } else {
        state.clients = []
      }
    },
    setClientInfo(state, val) {
      state.clientInfo = val
    },
    setContacts(state, val) {
      if (val) {
        state.contacts = val
      } else {
        state.contacts = []
      }
    },
    setContactInfo(state, val) {
      state.contactInfo = val
    },
    setDayShifts(state, val) {
      if (val) {
        state.dayShifts = val
      } else {
        state.dayShifts = []
      }
    },
    setEventShifts(state, val) {
      if (val) {
        state.eventShifts = val
      } else {
        state.eventShifts = []
      }
    },
    appendEventUsers(state, eventUsers) {
      state.eventUsers = state.eventUsers.concat(eventUsers); // Append the next batch of documents to the existing array
    },
    setEventUsers(state, val) {
      if (val) {
        state.eventUsers = val
      } else {
        state.eventUsers = []
      }
    },
    setAssociatedGroupUsers(state, val) {
      if (val) {
        state.associatedGroupUsers = val
      } else {
        state.associatedGroupUsers = []
      }
    },
    setEventGroupUsers(state, eventGroupUsers) {
      state.eventGroupUsers = eventGroupUsers;
    },
    updateStartAfterGroupUsers(state, lastDocument) {
      state.startAfterGroupUsers = lastDocument;
    },
    cacheEventGroupUsers(state, cachedEventGroupUsers) {
      state.cachedEventGroupUsers = cachedEventGroupUsers;
    },
    // setEventGroupUsers(state, val) {
    //   if (val) {
    //     state.eventGroupUsers = val
    //   } else {
    //     state.eventGroupUsers = []
    //   }
    // },
    setDayUsers(state, val) {
      if (val) {
        state.dayUsers = val
      } else {
        state.dayUsers = []
      }
    },
    setEventUsersRef(state, val) {
      if (val) {
        state.eventUsersRef = val
      } else {
        state.eventUsersRef = []
      }
    },
    cacheEventUsers(state, cachedUsers) {
      state.cachedEventUsers = cachedUsers;
    },
    cacheAssignmentDays(state, cachedDays) {
      state.cachedAssignmentDays = cachedDays;
    },
    updateStartAfter(state, newStartAfter) {
      state.startAfter = newStartAfter;
    },
    setAssignmentDays(state, val) {
      if (val) {
        state.assignmentDays = val
      } else {
        state.assignmentDays = []
      }
    },
    setEventShiftsDays(state, val) {
      if (val) {
        state.eventShiftsDays = val
      } else {
        state.eventShiftsDays = []
      }
    },
    setStaticEventUsers(state, val) {
      if (val) {
        state.staticEventUsers = val
      } else {
        state.staticEventUsers = []
      }
    },
    setEventDrops(state, val) {
      if (val) {
        state.eventDrops = val
      } else {
        state.eventDrops = []
      }
    },
    setShifts(state, val) {
      if (val) {
        state.shifts = val
      } else {
        state.shifts = []
      }
    },
    setTheShifts(state, val) {
      if (val) {
        state.activeShifts = val
      } else {
        state.activeShifts = []
      }
    },
    setDayShifts(state, val) {
      if (val) {
        state.dayShifts = val
      } else {
        state.dayShifts = []
      }
    },
    setShift(state, val) {
      state.shift = val
    },
    setGroup(state, val) {
      state.group = val
    },
    setDayUsers(state, val) {
      if (val) {
        state.dayUsers = val
      } else {
        state.dayUsers = []
      }
    },
    setAvailableUsers(state, val) {
      if (val) {
        state.availableUsers = val
      } else {
        state.availableUsers = []
      }
    },
    setShiftAssignments(state, val) {
      if (val) {
        state.shiftAssignments = val
      } else {
        state.shiftAssignments = []
      }
    },
    setUsersPerDay(state, val) {
      if (val) {
        state.usersPerDay = val
      } else {
        state.usersPerDay = []
      }
    },
    setPerformingRequest(state, val) {
      state.performingRequest = val
    },
    setAccountingNotes(state, val) {
      if (val) {
        state.accountingNotes = val
      } else {
        state.accountingNotes = []
      }
    },
    setClientNotes(state, val) {
      if (val) {
        state.clientNotes = val
      } else {
        state.clientNotes = []
      }
    },
    setAccessNotes(state, val) {
      if (val) {
        state.accessNotes = val
      } else {
        state.accessNotes = []
      }
    },
    setClientVenues(state, val) {
      if (val) {
        state.clientVenues = val
      } else {
        state.clientVenues = []
      }
    },
    setClientContacts(state, val) {
      if (val) {
        state.clientContacts = val
      } else {
        state.clientContacts = []
      }
    },
    setGroups(state, val) {
      if (val) {
        state.groups = val
      } else {
        state.groups = []
      }
    },
    setHiddenGroups(state, val) {
      if (val) {
        state.hiddenGroups = val
      } else {
        state.hiddenGroups = []
      }
    },
    setReferrals(state, val) {
      if (val) {
        state.referrals = val
      } else {
        state.referrals = []
      }
    },
    setUsersWithoutAddress(state, val) {
      if (val) {
        state.usersWithoutAddress = val
      } else {
        state.usersWithoutAddress = []
      }
    },
    setUsersTotal(state, val) {
      state.usersTotal = val
    },
    setDailyUserData(state, val) {
      if (val) {
        state.dailyUserData = val
      } else {
        state.dailyUserData = []
      }
    },
    setAssignmentsTotal(state, val) {
      if (val) {
        state.assignmentsTotal = val
      } else {
        state.assignmentsTotal = []
      }
    },
    setEmployees(state, val) {
      if (val) {
        state.employees = val
      } else {
        state.employees = []
      }
    },
    setUsersByProximity(state, val) {
      if (val) {
        state.usersByProximity = val
      } else {
        state.usersByProximity = []
      }
    },
    setUsersByState(state, val) {
      if (val) {
        state.stateUsers = val
      } else {
        state.stateUsers = []
      }
    },
    setUsersByCity(state, val) {
      if (val) {
        state.cityUsers = val
      } else {
        state.cityUsers = []
      }
    },
    setAppUsers(state, val) {
      if (val) {
        state.appUsers = val
      } else {
        state.appUsers = []
      }
    },
    setEmpUsers(state, val) {
      if (val) {
        state.empUsers = val
      } else {
        state.empUsers = []
      }
    },
    setFollowersGroupUsers(state, val) {
      if (val) {
        state.followersGroupUsers = val
      } else {
        state.followersGroupUsers = []
      }
    },
    setFollowersGroup(state, val) {
      state.followersGroup = val
    },
    setFollowerGroups(state, val) {
      if (val) {
        state.followersGroups = val
      } else {
        state.followersGroups = []
      }
    },
    setFollowersGroupMessages(state, val) {
      if (val) {
        state.followersGroupMessages = val
      } else {
        state.followersGroupMessages = []
      }
    },
    setClientAccess(state, val) {
      if (val) {
        state.clientAccess = val
      } else {
        state.clientAccess = []
      }
    },
    setClientAccessInfo(state, val) {
      state.clientAccessInfo = val
    },
    setPageText(state, val) {
      state.pageText = val
    },
    setEventsByYear(state, val) {
      if (val) {
        state.eventsByYear = val
      } else {
        state.eventsByYear = []
      }
    },
    setVerifications(state, val) {
      if (val) {
        state.verifications = val
      } else {
        state.verifications = []
      }
    },
    setUserVerifications(state, val) {
      if (val) {
        state.userVerifications = val
      } else {
        state.userVerifications = []
      }
    },
    setEventsByMonth(state, val) {
      if (val) {
        state.eventsByMonth = val
      } else {
        state.eventsByMonth = []
      }
    },
    setEventsByVenue(state, val) {
      if (val) {
        state.eventsByVenue = val
      } else {
        state.eventsByVenue = []
      }
    },
    setMarkets(state, val) {
      if (val) {
        state.markets = val
      } else {
        state.markets = []
      }
    },
    setTagInfo(state, val) {
      state.tagInfo = val
    },
    setTags(state, val) {
      if (val) {
        state.tags = val
      } else {
        state.tags = []
      }
    },
    setInfiniteEvents(state, val) {
      if (val) {
        state.infiniteEvents = val
      } else {
        state.infiniteEvents = []
      }
    },
    setNextInfiniteEvents(state, val) {
      if (val) {
        state.nextInfiniteEvents = val
      } else {
        state.nextInfiniteEvents = []
      }
    },
    setPrevInfiniteEvents(state, val) {
      if (val) {
        state.prevInfiniteEvents = val
      } else {
        state.prevInfiniteEvents = []
      }
    },
    setLastVisibleEventSnapShot(state, val) {
      state.lastVisibleEventSnapShot = val
    },
    setFirstVisibleEventSnapShot(state, val) {
      state.firstVisibleEventSnapShot = val
    },
    setVenueEventsSearchResults(state, val) {
      if (val) {
        state.venueEventsSearchResults = val
      } else {
        state.venueEventsSearchResults = []
      }
    },
    setEventsSearchResults(state, val) {
      if (val) {
        state.eventsSearchResults = val
      } else {
        state.eventsSearchResults = []
      }
    },
    setCancelledEvents(state, val) {
      if (val) {
        state.cancelledEvents = val
      } else {
        state.cancelledEvents = []
      }
    },
    setTaggedEvents(state, val) {
      if (val) {
        state.taggedEvents = val
      } else {
        state.taggedEvents = []
      }
    },
    setPaidAssignments(state, val) {
      if (val) {
        state.paidAssignments = val
      } else {
        state.paidAssignments = []
      }
    },
    // setEventAssignments(state, val) {
    //   if (val) {
    //     state.eventAssignments = val
    //   } else {
    //     state.eventAssignments = []
    //   }
    // },
    setStaticEventAssignments(state, val) {
      if (val) {
        state.staticEventAssignments = val
      } else {
        state.staticEventAssignments = []
      }
    },
    setEventAssignmentsByDay(state, val) {
      if (val) {
        state.eventAssignmentsByDay = val
      } else {
        state.eventAssignmentsByDay = []
      }
    },
    setMyActive(state, val) {
      if (val) {
        state.myActive = val
      } else {
        state.myActive = []
     }
    },
    setMyOnboarding(state, val) {
      if (val) {
        state.myOnboarding = val
      } else {
        state.myOnboarding = []
     }
    },
    setMyPipeline(state, val) {
      if (val) {
        state.myPipeline = val
      } else {
        state.myPipeline = []
     }
    },
    setUserPipeline(state, val) {
      if (val) {
        state.userPipeline = val
      } else {
        state.userPipeline = []
     }
    },
    setAllPayroll(state, val) {
      if (val) {
        state.allPayroll = val
      } else {
        state.allPayroll = []
     }
    },
    setMyRecaps(state, val) {
      if (val) {
        state.myRecaps = val
      } else {
        state.myRecaps = []
      }
    },
    setCheckInMaster(state, val) {
      state.checkInMaster = val
    },
    setEventsCancelled(state, val) {
      if (val) {
        state.eventsCancelled = val
      } else {
        state.eventsCancelled = []
      }
    },
    setPartialEvents(state, val) {
      if (val) {
        state.partialEvents = val
      } else {
        state.partialEvents = []
      }
    },
    setCompleteEvents(state, val) {
      if (val) {
        state.completeEvents = val
      } else {
        state.completeEvents = []
      }
    },
    setMarketingLeads(state, val) {
      if (val) {
        state.marketingLeads = val
      } else {
        state.marketingLeads = []
      }
    },
    setArchivedLeads(state, val) {
      if (val) {
        state.archivedLeads = val
      } else {
        state.archivedLeads = []
      }
    },
    setMyLeads(state, val) {
      if (val) {
        state.marketLeads = val
      } else {
        state.marketLeads = []
      }
    },
    setShiftLeads(state, val) {
      if (val) {
        state.shiftLeads = val
      } else {
        state.shiftLeads = []
      }
    },
    setBackgroundCheck(state, val) {
      state.backgroundCheck = val
    },
    setEventsTotal(state, val) {
      state.eventsTotal = val
    },
    setEventsTotalNext(state, val) {
      state.eventsTotalNext = val
    },
    setSubClients(state, val) {
      if (val) {
        state.subClients = val
      } else {
        state.subClients = []
      }
    },
    setUserBackgroundChecks(state, val) {
      if (val) {
        state.userBackgroundChecks = val
      } else {
        state.userBackgroundChecks = []
      }
    },
    setJESNotes(state, val) {
      if (val) {
        state.JESNotes = val
      } else {
        state.JESNotes = []
      }
    },
    setUserPayroll(state, val) {
      if (val) {
        state.userPayroll = val
      } else {
        state.userPayroll = []
      }
    },
    setWeeks(state, val) {
      if (val) {
        state.weeks = val
      } else {
        state.weeks = []
      }
    },
    setSkills(state, val) {
      if (val) {
        state.skills = val
      } else {
        state.skills = []
      }
    },
    setSkill(state, val) {
      state.skill = val
    },
    setCerts(state, val) {
      if (val) {
        state.certs = val
      } else {
        state.certs = []
      }
    },
    setCert(state, val) {
      state.cert = val
    },
    setAllUserLogins(state, val) {
      if (val) {
        state.allUserLogins = val
      } else {
        state.allUserLogins = []
      }
    },
    setSkillCategories(state, val) {
      if (val) {
        state.skillCategories = val
      } else {
        state.skillCategories = []
      }
    },
    setOldUsersByState(state, val) {
      if (val) {
        state.oldUsersByState = val
      } else {
        state.oldUsersByState = []
      }
    },
    setPayrollQueue(state, val) {
      if (val) {
        state.payrollQueue = val
      } else {
        state.payrollQueue = []
      }
    },
    setEvereeUsersWithoutExternalId(state, val) {
      if (val) {
        state.evereeUsersWithoutExternalId = val
      } else {
        state.evereeUsersWithoutExternalId = []
      }
    },
    setNonContractors(state, val) {
      if (val) {
        state.nonContractors = val
      } else {
        state.nonContractors = []
      }
    },
    updateNewUser(state, updatedUser) {
      // Find the index of the user to update
      const index = state.newUsers.findIndex(user => user.id === updatedUser.id);

      if (index !== -1) {
        // Update the user data at the found index
        state.newUsers[index] = updatedUser;
      }
    },
    addNewUser(state, newUser) {
    // Filter out users with initialContactStatus equal to "Hide"
      if (!newUser.initialContactStatus || newUser.initialContactStatus !== "Hide") {
        state.newUsers.push(newUser);
      }
    },
    removeNewUser(state, removedUser) {
      const index = state.newUsers.findIndex(user => user.id === removedUser.id);
      if (index !== -1) {
        state.newUsers.splice(index, 1); // Removes the user from the array
      }
    },
    newUsers() {
      return this.$store.state.newUsers; // Replace with your actual Vuex state property
    },
    setNewUsers(state, val) {
      if (val) {
        state.newUsers = val
      } else {
        state.newUsers = []
      }
    },
    setSupportRequests(state, val) {
      if (val) {
        state.supportRequests = val
      } else {
        state.supportRequests = []
      }
    },
    setLeadApplications(state, val) {
      if (val) {
        state.leadApplications = val
      } else {
        state.leadApplications = []
      }
    },
    setBackgroundChecks(state, val) {
      if (val) {
        state.backgroundChecks = val
      } else {
        state.backgroundChecks = []
      }
    },
    setUserContractorPayProfile(state, val) {
      state.userContractorPayProfile = val
    },
    setWorkerFiles(state, val) {
      state.workerFiles = val
    },
    setEmployeeFiles(state, val) {
      state.employeeFiles = val
    },
    setUnpaidPayables(state, val) {
      state.unpaidPayables = val
    },
    setWorkerPayHistory(state, val) {
      state.workerPayHistory = val
    },
    setEmployeePayHistory(state, val) {
      state.employeePayHistory = val
    },
    setEmployeeTimesheetHistory(state, val) {
      state.employeeTimesheetHistory = val
    },
    setUserEmployeePayProfile(state, val) {
      state.userEmployeePayProfile = val
    },
    setEmployeeFiles(state, val) {
      state.employeeFiles = val
    },
    setActiveDay(state, val) {
      state.activeDay = val
    },
    setUserWatchlist(state, val) {
      if (val) {
        state.userWatchlist = val
      } else {
        state.userWatchlist = []
      }
    },
    setOrderWatchlist(state, val) {
      if (val) {
        state.orderWatchlist = val
      } else {
        state.orderWatchlist = []
      }
    },
    setGroupWatchlist(state, val) {
      if (val) {
        state.groupWatchlist = val
      } else {
        state.groupWatchlist = []
      }
    },
    setEmployeeUnpaidPayables(state, val) {
      state.employeeUnpaidPayables = val
    },
    setEvereeContractors(state, val) {
      state.evereeContractors = val
    },
    setActiveWorkers(state, val) {
      if (val) {
        state.activeWorkers = val
      } else {
        state.activeWorkers = []
      }
    },
    setOnboardingUsers(state, val) {
      if (val) {
        state.onboardingUsers = val
      } else {
        state.onboardingUsers = []
      }
    },
    setOnboardingEmployees(state, val) {
      if (val) {
        state.onboardingEmployees = val
      } else {
        state.onboardingEmployees = []
      }
    },
    setTerminatedEmployees(state, val) {
      if (val) {
        state.terminatedEmployees = val
      } else {
        state.terminatedEmployees = []
      }
    },
    setHiredDirectEmployees(state, val) {
      if (val) {
        state.hiredDirectEmployees = val
      } else {
        state.hiredDirectEmployees = []
      }
    },
    setActiveEmployees(state, val) {
      if (val) {
        state.activeEmployees = val
      } else {
        state.activeEmployees = []
      }
    },
    setBackgroundCheckTypes(state, val) {
      if (val) {
        state.backgroundCheckTypes = val
      } else {
        state.backgroundCheckTypes = []
      }
    },
    setDrugCheckTypes(state, val) {
      if (val) {
        state.drugCheckTypes = val
      } else {
        state.drugCheckTypes = []
      }
    },
    setPayableEmployees(state, val) {
      if (val) {
        state.payableEmployees = val
      } else {
        state.payableEmployees = []
      }
    },
    setPayableContractors(state, val) {
      if (val) {
        state.payableContractors = val
      } else {
        state.payableContractors = []
      }
    },
    setPanels(state, val) {
      if (val) {
        state.panels = val
      } else {
        state.panels = []
      }
    },
    setPipeline(state, val) {
      if (val) {
        state.pipeline = val
      } else {
        state.pipeline = []
      }
    },
    updatePipeline(state, val) {
      if (val) {
        state.pipeline = val
      } else {
        state.pipeline = []
      }
    },
    setPipelineHired(state, val) {
      if (val) {
        state.pipelineHired = val
      } else {
        state.pipelineHired = []
      }
    },
    updatePipelineHired(state, val) {
      if (val) {
        state.pipelineHired = val
      } else {
        state.pipelineHired = []
      }
    },
    setPipelineCancelled(state, val) {
      if (val) {
        state.pipelineCancelled = val
      } else {
        state.pipelineCancelled = []
      }
    },
    updatePipelineCancelled(state, val) {
      if (val) {
        state.pipelineCancelled = val
      } else {
        state.pipelineCancelled = []
      }
    },
    updateAssignment(state, updatedAssignment) {
      // Find the index of the assignment with the same ID in the state
      const index = state.assignments.findIndex((assignment) => assignment.id === updatedAssignment.id);

      if (index !== -1) {
        // Update the assignment at the found index with the updated data
        state.assignments[index] = updatedAssignment;
      }
    },
    // setEventAssignments(state, assignments) {
    //   state.eventAssignments = assignments;
    // },
    cacheEventAssignments(state, assignments) {
      state.cachedAssignments = assignments;
    },
    updateTheEventShifts(state, updatedEventShifts) {
  updatedEventShifts.forEach((updatedEventShift) => {
    const existingEventShiftIndex = state.eventShifts.findIndex(
      (eventShift) => eventShift.id === updatedEventShift.id
    );

    if (existingEventShiftIndex !== -1) {
      // If the eventShift exists, update it
      state.eventShifts[existingEventShiftIndex] = updatedEventShift;
    } else {
      // If the eventShift doesn't exist, add it to the array
      state.eventShifts.push(updatedEventShift);
    }
  });
},
    updateTheEventShift(state, updatedEventShift) {
      const existingEventShiftIndex = state.eventShifts.findIndex(
        (eventShift) => eventShift.id === updatedEventShift.id
      );

      if (existingEventShiftIndex !== -1) {
        // If the eventShift exists, update it
        state.eventShifts[existingEventShiftIndex] = updatedEventShift;
      } else {
        // If the eventShift doesn't exist, add it to the array
        state.eventShifts.push(updatedEventShift);
      }
    },

    // Mutation to remove an eventShift (optional)
    removeEventShift(state, removedEventShiftId) {
      const indexToRemove = state.eventShifts.findIndex(
        (eventShift) => eventShift.id === removedEventShiftId
      );

      if (indexToRemove !== -1) {
        // Remove the eventShift from the array
        state.eventShifts.splice(indexToRemove, 1);
      }
    },
    // updateEventUser(state, updatedUser) {
    //   const index = state.eventUsers.findIndex(user => user.id === updatedUser.id);
    //   if (index !== -1) {
    //     state.eventUsers[index] = updatedUser;
    //   }
    // },
    // removeEventUser(state, userId) {
    //   const index = state.eventUsers.findIndex(user => user.id === userId);
    //   if (index !== -1) {
    //     state.eventUsers.splice(index, 1);
    //   }
    // },
  },
})
export default store