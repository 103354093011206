<template>
  <header>
    <div class="nav hidden-large">
      <div class="nav__container">
        <div class="nav__logo">
          <router-link to="/dashboard">
            <span style="text-transform: none; font-size: 2.8rem;
    font-weight: 300;">J<em>u</em>mp</span>
          </router-link>
        </div>
        <div class="nav__menu">
 
          <div class="nav__menu--item mobile">
            <a @click="showSideNav()">
              <i class="fal fa-bars fa-2x" v-show="!showSide"></i>
              <i class="fal fa-times fa-2x" v-show="showSide"></i>
            </a>
          </div>

          
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <div class="sideNav" v-show="showSide" style="right:0; top:0; position: fixed; z-index: 999; justify-content: flex-start; padding-top:1rem;">
        <div class="nav__menu--item justify-flex-end">
            <a @click="showSideNav()" style="width:100%; justify-content: flex-end !important;">
              <i class="fal fa-times fa-2x" v-show="showSide"></i>
            </a>
          </div>
          <div class="nav__menu--item">
            <div class="nav__logo">
              <router-link to="/dashboard" style="width:100%;">
                <span style="text-transform: none; font-size: 3.6rem;
    font-weight: 300;">J<em>u</em>mp</span>
              </router-link>
            </div>
            
          </div>

          <div class="nav__menu--item">
            <router-link :to="{name: 'dashboardHome'}">
              home <i class="fas fa-chevron-right"></i>
            </router-link>
          </div>

          
          <div class="nav__menu--item">
            <router-link :to="{name: 'users'}">
              search users <i class="fas fa-chevron-right"></i>
            </router-link>
          </div>
          <!-- <div class="nav__menu--item">
            <router-link :to="{name: 'watchlistHome'}">
              watchlists <i class="fas fa-chevron-right"></i>
            </router-link>
          </div> -->
          <div class="nav__menu--item">
            <router-link :to="{name: 'newUsers'}">
              new users <i class="fas fa-chevron-right"></i>
            </router-link>
          </div>
          <div class="nav__menu--item">
            <router-link :to="{name: 'events'}">
              orders <i class="fas fa-chevron-right"></i>
            </router-link>
          </div>
          <div class="nav__menu--item">
            <router-link :to="{name: 'shiftsTable'}">
              shifts <i class="fas fa-chevron-right"></i>
            </router-link>
          </div>

          <div class="nav__menu--item">
            <router-link :to="{name: 'groups'}">
              Job groups <i class="fas fa-chevron-right"></i>
            </router-link>
          </div>
          <div class="nav__menu--item">
            <router-link :to="{name: 'jobsites'}">
              Jobsites <i class="fas fa-chevron-right"></i>
            </router-link>
          </div>
          <div class="nav__menu--item">
            <router-link :to="{name: 'supportHome'}">
              Support Tickets <i class="fas fa-chevron-right"></i>
            </router-link>
          </div>
          <div class="nav__menu--item">
            <router-link :to="{name: 'shiftLeads'}">
              Shift Lead Applications<i class="fas fa-chevron-right"></i>
            </router-link>
          </div>

          <div class="nav__menu--item">
            <router-link :to="{name: 'clients'}">
              Customers <i class="fas fa-chevron-right"></i>
            </router-link>
          </div>

          <div class="nav__menu--item">
            <router-link :to="{name: 'contacts'}">
              Contacts <i class="fas fa-chevron-right"></i>
            </router-link>
          </div>
          <div class="nav__menu--item">
            <router-link :to="{name: 'leadsHome'}">
              Marketing Leads <i class="fas fa-chevron-right"></i>
            </router-link>
          </div>
          

          

          <div class="nav__menu--item">
            <router-link :to="{name: 'payrollHome'}">
              payroll <i class="fas fa-chevron-right"></i>
            </router-link>
          </div>

          <div class="nav__menu--item">
            <router-link :to="{name: 'reportsHome'}">
              reports <i class="fas fa-chevron-right"></i>
            </router-link>
          </div>

          <div class="nav__menu--item">
            <router-link :to="{name: 'team'}">
              company roster<i class="fas fa-chevron-right"></i>
            </router-link>
          </div>
        
         
          <div class="nav__menu--item">
            <a @click="logout()">logout</a>
          </div>

        </div>
      </div>
    </transition>
  </header>
</template>

<style scoped>
  .dropdown {
  position: relative;
  display: inline-block;
  color: #d0d0d0;
  transition: 0.2s ease all;
}

.dropdown-content {
  display: none;
  position: relative;
  background-color: #212121;
  width:96%;
  transition: 0.2s ease all;
}
.dropdown:hover .dropdown-content {
  display: block;
  padding-top: 1rem;
  transition: 0.2s ease all;
}
</style>

<script>
import SVG_TriangleLogo from '@/assets/svgs/SVG_TriangleLogo.vue'

export default {
  props: ['userProfile'],
  data: () => ({
    showSide: false,
    accDrop: false,
  }),
  computed: {
    pic () {
      return this.userProfile.photoUrl || 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/avatar%20copy.png?alt=media&token=966c07c4-125a-490f-81be-4e2d26bf33fa'
    }
  },
  watch:{
    $route (to, from){
      this.showSide = false
    }
  },
  components: {
    SVG_TriangleLogo
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
    showSideNav() {
      this.showSide = !this.showSide
    }
  },
  beforeDestroy() {
    this.showSide = null
    delete this.showSide
    this.accDrop = null
    delete this.accDrop
  }
}
</script>